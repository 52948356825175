import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  FormButtons,
  FormControl,
  TextControl,
  Checkbox,
  errorMessages,
  Dialog,
  StepCard,
  StepCardHeader,
  StepCardTitle,
  StepCardAction,
} from '@clatter/ui';
import {
  AddOutlined as AddOutlinedIcon,
} from '@mui/icons-material';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import CreatePageIconForm from '../CreatePageIconForm/CreatePageIconForm';
import ImagePicker from '../../ImagePicker/ImagePicker';

const StyledTemplateForm = styled.form`
  min-width: 480px;
`;

const StyledStepCardContent = styled.div`
  padding: 20px 0;
`;

const validationSchema = Joi.object()
  .keys({
    display_name: Joi.string().optional(),
    hidden: Joi.bool().optional(),
    pageIcon: Joi.any().optional(),
  })
  .messages({
    'any.required': errorMessages.REQUIRED.message,
    'string.empty': errorMessages.REQUIRED.message,
    'string.base': errorMessages.REQUIRED.message,
  });

const TemplateForm = ({ editableItem, pageIcons, isEdit, onSubmit }) => {
  const [showCreatePageIconDialog, setShowCreatePageIconDialog] =
    useState(false);
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    control,
  } = useForm({
    reValidateMode: 'onChange',
    mode: 'onBlur',
    resolver: joiResolver(validationSchema),
    defaultValues: {
      display_name: editableItem?.display_name,
      hidden: !!editableItem?.hidden,
      pageIcon: editableItem?.icon_uri || '',
    },
  });

  const handleFormSubmit = async (formData) => {
    onSubmit(formData);
  };

  const toggleShowCreatePageIconDialog = () => {
    setShowCreatePageIconDialog(!showCreatePageIconDialog);
  };

  const handleSelectPageIcon = (logo) => {
    setValue('pageIcon', logo, {
      shouldDirty: true,
      shouldValidate: true,
    });
    toggleShowCreatePageIconDialog();
  };

  return (
    <>
      <StyledTemplateForm onSubmit={handleSubmit(handleFormSubmit)}>
        <FormControl label="Name" error={errors?.display_name}>
          <TextControl
            {...register('display_name')}
            placeholder="Enter template display name"
            disabled
          />
        </FormControl>
        <FormControl error={errors?.hidden}>
          <Controller
            name="hidden"
            render={({ field }) => (
              <Checkbox
                {...field}
                text="Active"
                checked={!field.value}
                onBlur={field.onBlur}
                onChange={(event) =>
                  field.onChange(event.target.checked ? false : true)
                }
              />
            )}
            control={control}
          />
        </FormControl>

        <StepCard>
          <StepCardHeader>
            <StepCardTitle text="Upload page icon image (optional)" />
            <StepCardAction
              onClick={toggleShowCreatePageIconDialog}
              label="Add page icon"
              icon={AddOutlinedIcon}
            />
          </StepCardHeader>
          <StyledStepCardContent>
            <FormControl noPadding error={errors.pageIcon}>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <ImagePicker
                    images={pageIcons}
                    titleProperty="pageIcon"
                    urlProperty="asset.url"
                    onChange={onChange}
                    value={value}
                    imageAspectRatio="10/3"
                  />
                )}
                control={control}
                name="pageIcon"
              />
            </FormControl>
          </StyledStepCardContent>
        </StepCard>
        <FormButtons>
          <Button disabled={!isValid || !isDirty} type="submit">
            {isEdit ? 'Save' : 'Create'}
          </Button>
        </FormButtons>
      </StyledTemplateForm>

      <Dialog
        title="Add Page Icon"
        onCloseDialog={toggleShowCreatePageIconDialog}
        open={showCreatePageIconDialog}
      >
        <CreatePageIconForm
          templateId={editableItem.id}
          onSuccess={handleSelectPageIcon}
        />
      </Dialog>
    </>
  );
};

export default TemplateForm;
