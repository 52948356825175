import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { formatFileSize } from '../../helpers';
import { IconButton } from '@clatter/ui';

const StyledFileUpload = styled.div`
  .uploader {
    background: #fff;
    position: relative;
    border: 1px dashed #1890ff;
    border-radius: 4px;
    padding: 48px 24px;
    text-align: center;

    input[type='file'] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }

    &:hover {
      border-style: solid;
    }
  }

  .file {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 8px;
    padding: 8px 12px;
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;

    .file-icon {
      fill: #1890ff;
      font-size: 16px;
    }

    .file-name {
      padding: 0 8px;
      font-size: 14px;
      max-width: 60%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .file-size {
      padding: 0 8px;
      font-size: 12px;
    }

    .file-remove {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .image-preview {
    position: relative;
    max-width: 100%;
    height: 150px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`;

const FileUpload = ({
  acceptedFileTypes = '*/*',
  multiple = false,
  onChange,
  previewOnTop = false,
  renderCustomPreview,
  value = null,
}) => {
  const [files, setFiles] = useState(value);
  const [imagePreview, setImagePreview] = useState(null);

  const handleChange = (event) => {
    if (event.target.files.length) {
      let nextFiles;

      if (multiple) {
        nextFiles = [...files, ...event.target.files];
      } else {
        nextFiles = event.target.files[0];
      }

      setFiles(nextFiles);
      typeof onChange === 'function' && onChange(nextFiles);
    }
  };

  const handleClick = (event) => {
    event.target.value = null;
  };

  const handleRemoveFileClick = (event) => {
    let nextFiles = null;
    if (multiple) {
      const fileIndex = Number(event.currentTarget.dataset.fileIndex);
      nextFiles = files.filter((file, index) => index !== fileIndex);
    }
    setImagePreview(null);
    setFiles(nextFiles);
    typeof onChange === 'function' && onChange(nextFiles);
  };

  const renderPreviewItem = (file, index = 0) => (
    <div className="file" key={`${file.name}-${file.size}`}>
      <InsertDriveFileOutlinedIcon className="file-icon" />
      <div className="file-name">{file.name}</div>
      <div className="file-size">{formatFileSize(file.size)}</div>
      <IconButton className="file-remove" data-file-index={index} onClick={handleRemoveFileClick}>
        <ClearOutlinedIcon />
      </IconButton>
    </div>
  );

  const renderPreview = () => {
    if (!files && ((multiple && !files.length) || !multiple)) {
      return null;
    }

    if (typeof renderCustomPreview === 'function') {
      return renderCustomPreview(files, handleRemoveFileClick);
    }

    if (multiple) {
      return files.map(renderPreviewItem);
    }

    return renderPreviewItem(files);
  };

  useEffect(() => {
    const fileToPreview = multiple ? (files.length ? files[0] : null) : files;

    if (fileToPreview && fileToPreview.type && fileToPreview.type.startsWith('image/')) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setImagePreview(reader.result);
      };

      reader.readAsDataURL(fileToPreview);
    } else {
      setImagePreview(null);
    }
  }, [files]);

  return (
    <StyledFileUpload>
      {previewOnTop && renderPreview()}
      {imagePreview ? (
        <div className="image-preview">
          <img src={imagePreview} alt="Preview" />
        </div>
      ) : (
        <div className="uploader">
          <p className="upload-text">Drop file{multiple ? 's' : ''} here or click to upload</p>
          <input
            type="file"
            id="file"
            name="file"
            onChange={handleChange}
            onClick={handleClick}
            accept={acceptedFileTypes}
            multiple={multiple}
          />
        </div>
      )}
      {!previewOnTop && renderPreview()}
    </StyledFileUpload>
  );
};

const FileType = PropTypes.shape({
  name: PropTypes.name,
  size: PropTypes.number,
});

FileUpload.propTypes = {
  acceptedFileTypes: PropTypes.string,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.arrayOf(FileType), FileType]),
};

export default FileUpload;
