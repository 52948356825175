import React, { useState } from 'react';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {
  Button,
  FormButtons,
  FormControl,
  IconButton,
  TextControl,
} from '@clatter/ui';
import { useAuth } from '@clatter/platform';
import FileUpload from '../../FileUpload/FileUpload';
import { acceptedFileTypes } from "../config";
import { addCobrandLogo, updateCoBrandLogo } from "../../../store";

const StyledForm = styled.form`
  width: 480px;

  .image {
    width: 100%;
    position: relative;

    img {
      width: 100%;
    }
  }
`;

const CoBrandLogoForm = ({ editableItem, isEdit, onSuccess }) => {
  const dispatch = useDispatch();
  const { activeUser } = useAuth();
  const [showUploadForm, setShowUploadForm] = useState(false);
  const {
    control,
    formState: { errors },
    formState,
    handleSubmit,
    register,
  } = useForm({
    mode: 'onChange',
    defaultValues: editableItem || {},
  });

  const onSubmit = async (formData) => {
    dispatch(
      isEdit
        ? updateCoBrandLogo({
          id: editableItem.id,
          company: formData.company,
          file: formData.file,
          updatedBy: activeUser.email,
        })
        : addCobrandLogo({ company: formData.company, file: formData.file, createdBy: activeUser.email }),
    ).then(() => {
      onSuccess();
    });
  };

  const handleShowUploadForm = () => {
    setShowUploadForm(true);
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      {!showUploadForm && editableItem?.asset?.url ? (
        <div className="image">
          <img src={editableItem.asset.url} alt="Preview" />
          <IconButton className="" onClick={handleShowUploadForm}>
            <CloseOutlinedIcon />
          </IconButton>
        </div>
      ) : (
        <FormControl error={errors.file}>
          <Controller
            name="file"
            rules={{ required: { value: true, message: 'Field is required' } }}
            render={({ field: { onChange, value } }) => (
              <FileUpload acceptedFileTypes={acceptedFileTypes} onChange={onChange} value={value} />
            )}
            control={control}
            defaultValue={null}
          />
        </FormControl>
      )}
      <FormControl label="Company" error={errors.company}>
        <TextControl
          {...register('company', {
            required: { value: true, message: 'Field is required' },
          })}
          placeholder="Enter company name"
        />
      </FormControl>
      <FormButtons>
        <Button disabled={!formState.isValid} type="submit">
          Save
        </Button>
      </FormButtons>
    </StyledForm>
  );
};

export default CoBrandLogoForm;
