import getAxiosWithStrapi from './strapiFactory';

const contentRailsApi = {
  fetchContentRailsById: (ids) => {
    const queryIds = ids
      // .map((id, index) => `_where[id][$in][${index}]=${id}`) // old strapi version
      .map((id) => `filters[id][$in]=${id}`)
      .join('&');

    /// api/content-rails?filters[id][$in]=1&filters[id][$in]=9 // strapi v4 proper url
    return getAxiosWithStrapi({
      url: `/content-rails?${queryIds}`,
      method: 'GET',
    });
  },

  updateContentRail: ({ id, data }) =>
    getAxiosWithStrapi({
      url: `/content-rails/${id}`,
      method: 'PUT',
      data: {
        data: data,
      },
    }),

  createContentRail: ({ data }) =>
    getAxiosWithStrapi({
      url: `/content-rails`,
      method: 'POST',
      data: {
        data: data,
      },
    }),
};

export default contentRailsApi;
