import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { messagesActions } from '../../store/messages.slice';

const MessageProvider = ({ children, subscriber }) => {
  const dispatch = useDispatch();
  const message = useSelector((state) => state.messages.items[subscriber]);

  const handleDismiss = () => {
    dispatch(messagesActions.clearMessage({ message }));
  };

  if (!message) {
    return null;
  }

  return React.Children.only(children(message, handleDismiss));
};

MessageProvider.propTypes = {
  subscriber: PropTypes.string.isRequired,
};

export default MessageProvider;
