import React from 'react';
import { buttonWidths, DataGridPremium, IconButton } from '@clatter/ui';
import {
  GridToolbarContainer,
  useGridApiContext,
} from '@mui/x-data-grid';
import { CloudDownload as CloudDownloadIcon } from '@mui/icons-material';
import { handleExportAsCSV } from '@clatter/platform';
export const columns = [
  {
    headerName: 'Site',
    field: 'site',
    sortable: true,
    flex: 1,
  },
  {
    headerName: 'Pages',
    field: 'pages',
    valueGetter: ({ row }) => row.pages.length,
    sortable: true,
    flex: 1,
  },
  {
    headerName: 'Pageviews',
    field: 'views',
    sortable: true,
    flex: 1,
  },
  {
    headerName: 'Clicks',
    field: 'clicks',
    sortable: true,
    flex: 1,
  },
];

const CustomToolbar = ({ exportFileName }) => {
  const apiRef = useGridApiContext();

  return (
    <GridToolbarContainer
      style={{
        justifyContent: 'end',
      }}
    >
      <IconButton
        tooltip="Export"
        width={buttonWidths.md}
        onClick={() =>
          handleExportAsCSV({
            apiRef: apiRef,
            exportFileName: exportFileName,
          })
        }
      >
        <CloudDownloadIcon />
      </IconButton>
    </GridToolbarContainer>
  );
};

const PageviewsBySite = ({ isLoading = false, rows = [], exportFileName }) => {
  return (
    <DataGridPremium
      showVerticalSidebar
      loading={isLoading}
      dataGridProps={{
        components: {
          Toolbar: () => CustomToolbar({ exportFileName }),
        },
        getRowClassName: (params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd',
      }}
      name="report/msm-pageviews-page-site"
      columns={columns}
      defaultSortField={{ field: 'site', sort: 'desc' }}
      rows={rows}
      getRowId={(row) => row.uuid}
      disableColumnFilter
    />
  );
};

export default PageviewsBySite;
