import React from 'react';
import styled from 'styled-components';

const StyledTable = styled.table`
  border-collapse: collapse;
  border: none;
  width: 100%;

  tr {
    td,
    th {
      &:first-child {
        padding: ${({ theme }) => theme.table.header.th.firstChildPadding};
      }

      &:last-child {
        padding-right: 12px;
      }
    }
  }

  thead {
    tr {
      th,
      th * {
        padding: ${({ theme }) => theme.table.header.th.padding};
        font-family: ${({ theme }) => theme.table.header.th.fontFamily};
        font-size: ${({ theme }) => theme.table.header.th.fontSize};
        font-weight: ${({ theme }) => theme.table.header.th.fontWeight};
        line-height: ${({ theme }) => theme.table.header.th.lineHeight};
        color: ${({ theme }) => theme.table.header.th.color};
      }
    }
  }

  tbody {
    tr {
      border-bottom: ${({ theme }) => theme.table.body.tr.borderBottom};

      td,
      td > span,
      td > * span,
      td > div,
      td > * div {
        font-family: ${({ theme }) => theme.table.body.td.fontFamily};
        font-size: ${({ theme }) => theme.table.body.td.fontSize};
        color: ${({ theme }) => theme.table.body.td.color};
        line-height: ${({ theme }) => theme.table.body.td.lineHeight};
        padding: ${({ theme }) => theme.table.body.td.padding};
      }
    }

    &.sticky-header {
      thead {
        tr {
          th {
            background-color: #fff;
            position: sticky;
            top: 0;
            z-index: 1;
          }
        }
      }
    }

    .text-left {
      text-align: left;
    }

    .text-center {
      text-align: center;
    }
  }
`;

const Table = ({ children, stickyHeader }) => (
  <StyledTable className={`${stickyHeader ? 'sticky-header' : ''}`}>
    {children}
  </StyledTable>
);

export default Table;
