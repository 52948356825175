import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, NavLink, useRouteMatch, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { List, ListItem, ListItemIcon, ListItemText, useDocumentTitle } from '@clatter/ui';
import {
  featureFlagsMap,
  hasActiveFeatureFlag,
  platformSelectors,
  useAuth,
  userRolesMap,
  hasRole
} from '@clatter/platform';
import {
  ViewComfyOutlined as ViewCompactOutlinedIcon,
  ListOutlined as ListOutlinedIcon,
  PanoramaOutlined as PanoramaOutlinedIcon,
  AccountBoxOutlined as AccountBoxOutlinedIcon,
  DescriptionOutlined as DescriptionOutlinedIcon,
  OndemandVideoOutlined as OndemandVideoOutlinedIcon,
  AnalyticsOutlined as AnalyticsIcon,
} from '@mui/icons-material';
import Bios from './Bios/Bios';
import BioGroups from './BioGroups/BioGroups';
import Resources from './Resources/Resources';
import ResourceCategories from './ResourceCategories/ResourceCategories';
import HeroImages from './HeroImages/HeroImages';
import VideoCategories from './VideoCategories/VideoCategories';
import VideoEmbeds from './VideoEmbeds/VideoEmbeds';
import MicrositesSettings from './MicrositesSettings/MicrositesSettings';
import LandingPage from './LandingPage/LandingPage';
import Templates from './Templates/Templates';
import TrackingPixel from './TrackingPixel/TrackingPixel';
import CoBrandLogos from './CoBrandLogos/CoBrandLogos';
import { documentTitleMap } from "../../routes/routes";

const StyledAdmin = styled.div`
  display: grid;
  grid-template-areas: 'sidebar content';
  grid-template-columns: 250px 1fr;
  grid-auto-rows: auto;
  align-items: start;
  gap: 24px;
  height: 100%;

  .admin-nav {
    position: sticky;
    top: 80px;
  }

  .admin-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    > *:first-child + * {
      flex: 1;
      height: calc(100% - 82px);
    }
  }
`;

const Admin = () => {
  const match = useRouteMatch();
  const featureFlags = useSelector(
    platformSelectors.featureFlag.selectAllFeatureFlags,
  );
  const location = useLocation();
  const { activeUser } = useAuth();
  const isTrackingPixelEnabled = hasActiveFeatureFlag({
    flagName: featureFlagsMap.MSM.trackingPixel,
    featureFlags: featureFlags,
  }) && hasRole(userRolesMap.systemAdmin, activeUser);
  const isMsmAdmin = hasRole(userRolesMap.msmAdmin, activeUser);

  if (location?.pathname === '/admin') {
    useDocumentTitle(documentTitleMap.admin);
  }

  return (
    <StyledAdmin>
      <div className="admin-nav">
        <List>
          <ListItem
            hoverable
            component={NavLink}
            componentProps={{ to: `${match.path}/bios` }}
          >
            <ListItemIcon>
              <AccountBoxOutlinedIcon />
            </ListItemIcon>
            <ListItemText text="Bios" />
          </ListItem>
          <ListItem
            hoverable
            component={NavLink}
            componentProps={{ to: `${match.path}/bio-groups` }}
          >
            <ListItemIcon>
              <ListOutlinedIcon />
            </ListItemIcon>
            <ListItemText text="Bio groups" />
          </ListItem>

          {isMsmAdmin && (
            <ListItem
              hoverable
              component={NavLink}
              componentProps={{ to: `${match.path}/landing-page` }}
            >
              <ListItemIcon>
                <ListOutlinedIcon />
              </ListItemIcon>
              <ListItemText text="Landing Page" />
            </ListItem>
          )}

          <ListItem
            hoverable
            component={NavLink}
            componentProps={{ to: `${match.path}/resources` }}
          >
            <ListItemIcon>
              <DescriptionOutlinedIcon />
            </ListItemIcon>
            <ListItemText text="Resources" />
          </ListItem>
          <ListItem
            hoverable
            component={NavLink}
            componentProps={{ to: `${match.path}/resource-categories` }}
          >
            <ListItemIcon>
              <ListOutlinedIcon />
            </ListItemIcon>
            <ListItemText text="Resource categories" />
          </ListItem>
          <ListItem
            hoverable
            component={NavLink}
            componentProps={{ to: `${match.path}/videos` }}
          >
            <ListItemIcon>
              <OndemandVideoOutlinedIcon />
            </ListItemIcon>
            <ListItemText text="Videos" />
          </ListItem>
          <ListItem
            hoverable
            component={NavLink}
            componentProps={{ to: `${match.path}/video-categories` }}
          >
            <ListItemIcon>
              <ListOutlinedIcon />
            </ListItemIcon>
            <ListItemText text="Video categories" />
          </ListItem>
          <ListItem
            hoverable
            component={NavLink}
            componentProps={{ to: `${match.path}/hero-images` }}
          >
            <ListItemIcon>
              <PanoramaOutlinedIcon />
            </ListItemIcon>
            <ListItemText text="Hero images" />
          </ListItem>
          <ListItem
            hoverable
            component={NavLink}
            componentProps={{ to: `${match.path}/co-brand-logos` }}
          >
            <ListItemIcon>
              <PanoramaOutlinedIcon />
            </ListItemIcon>
            <ListItemText text="Co Brand Logos" />
          </ListItem>

          {isMsmAdmin && (
            <ListItem
              hoverable
              component={NavLink}
              componentProps={{ to: `${match.path}/templates` }}
            >
              <ListItemIcon>
                <ViewCompactOutlinedIcon />
              </ListItemIcon>
              <ListItemText text="Templates" />
            </ListItem>
          )}

          {isTrackingPixelEnabled && (
            <ListItem
              hoverable
              component={NavLink}
              componentProps={{ to: `${match.path}/tracking-pixel` }}
            >
              <ListItemIcon>
                <AnalyticsIcon />
              </ListItemIcon>
              <ListItemText text="Tracking Pixel" />
            </ListItem>
          )}
        </List>
      </div>
      <div className="admin-content">
        <Switch>
          <Route path={match.path} exact>
            <MicrositesSettings />
          </Route>
          <Route path={`${match.path}/bios`}>
            <Bios />
          </Route>
          <Route path={`${match.path}/bio-groups`}>
            <BioGroups />
          </Route>
          <Route path={`${match.path}/landing-page`} component={LandingPage} />
          <Route path={`${match.path}/resources`}>
            <Resources />
          </Route>
          <Route path={`${match.path}/resource-categories`}>
            <ResourceCategories />
          </Route>
          <Route path={`${match.path}/videos`}>
            <VideoEmbeds />
          </Route>
          <Route path={`${match.path}/video-categories`}>
            <VideoCategories />
          </Route>
          <Route path={`${match.path}/hero-images`}>
            <HeroImages />
          </Route>
          <Route path={`${match.path}/co-brand-logos`}>
            <CoBrandLogos />
          </Route>
          <Route path={`${match.path}/templates`}>
            <Templates />
          </Route>
          {isTrackingPixelEnabled && (
            <Route path={`${match.path}/tracking-pixel`}>
              <TrackingPixel />
            </Route>
          )}
        </Switch>
      </div>
    </StyledAdmin>
  );
};

export default Admin;
