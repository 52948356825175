import { DISPLAY_TYPE_PAGE, DISPLAY_TYPE_SITE } from './PageViewsFilters';
import { groupBy, orderBy } from 'lodash';
import { filterDataGridTableRowByTableColumns } from '@clatter/platform';

const sortRows = (data, filters) => {
  const { sort_model } = filters;
  // sort model undefined when clicking on currently sorted column name
  if (!sort_model) {
    return data;
  }

  const sortByFields = [sort_model.field];
  const orderDirections = [sort_model.sort];

  const isGroupByPage = filters?.display_type === DISPLAY_TYPE_PAGE;
  if (isGroupByPage) {
    sortByFields.unshift('site');
    orderDirections.unshift('asc');
  }

  return orderBy(data, sortByFields, orderDirections);
};

const groupByDisplay = (data, filters) => {
  const isGroupBySite = filters?.display_type === DISPLAY_TYPE_SITE;
  const sitePositionGroups = groupBy(data, 'site');
  const sitePositionKeys = Object.keys(sitePositionGroups);

  if (isGroupBySite) {
    return sitePositionKeys.map((siteName) => {
      const row = sitePositionGroups[siteName][0];

      return {
        uuid: row.uuid,
        site: row.site,
        pages: sitePositionGroups[siteName],
        views: sitePositionGroups[siteName].reduce(
          (acc, row) => acc + row.views,
          0,
        ),
        clicks: sitePositionGroups[siteName].reduce(
          (acc, row) => acc + row.clicks,
          0,
        ),
      };
    });
  }

  return data.map((row) => ({
    uuid: row.uuid,
    avg_engagement_time: row.avg_engagement_time,
    views: row.views,
    clicks: row.clicks,
    visitors: row.visitors,
    site: row.site,
    page: row.page,
    isOdd: sitePositionKeys.indexOf(row.site) % 2,
  }));
};

export const pageviewsFilter = ({ data = [], filters, tableColumns }) => {
  const sortedData = sortRows(data, filters);
  const groupedData = groupByDisplay(sortedData, filters);

  if (filters.search_query) {
    return groupedData.filter((row) =>
      filterDataGridTableRowByTableColumns(
        row,
        tableColumns,
        filters.search_query,
      ),
    );
  }

  return groupedData;
};
