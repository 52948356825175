import { getAxiosApiV2WithAuthorization } from '@clatter/platform';

export const axiosApiV2BaseQuery = () => async (url: string) => {
  try {
    const result = await getAxiosApiV2WithAuthorization({
      url: url,
    });

    return {
      data: result,
    };
  } catch (axiosError) {
    const err = axiosError;

    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message,
      },
    }
  }
};
