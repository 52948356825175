import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import {
  FormControl,
  TextControl,
  SelectControl,
  DataTable,
  IconButton,
} from '@clatter/ui';

const StyledResourcePicker = styled.div`
  .filters {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: column;
    gap: 16px;
  }

  h3 {
    margin: 24px 0 8px;
  }
`;

const ResourcePicker = ({
  max = 0,
  onChange,
  options,
  tableColumns,
  value,
}) => {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [availableCategories, setAvailableCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');

  useEffect(() => {
    const nextAvailableCategories = [
      ...new Set(options.map((resource) => resource.category)),
    ]
      .filter((resource) => !!resource)
      .map((category) => ({
        label: category,
        value: category,
      }));

    nextAvailableCategories.unshift({
      label: 'All',
      value: null,
    });

    setAvailableCategories(nextAvailableCategories);
  }, [options, value]);

  useEffect(() => {
    let nextFilteredOptions = options.filter(
      ({ id: optionId }) =>
        !value.some(({ id: valueId }) => valueId === optionId),
    );

    if (categoryFilter && categoryFilter.value) {
      nextFilteredOptions = nextFilteredOptions.filter(
        (item) => item.category === categoryFilter.value,
      );
    }

    if (searchTerm) {
      nextFilteredOptions = nextFilteredOptions.filter((option) =>
        option.name.toLowerCase().includes(searchTerm.toLowerCase()),
      );
    }

    if (nextFilteredOptions !== filteredOptions) {
      setFilteredOptions(nextFilteredOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, options, searchTerm, categoryFilter]);

  if (!options || !options.length) {
    return null;
  }

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCategoryFilterChange = (option) => {
    setCategoryFilter(option);
  };

  const handleAdd = (event) => {
    const nextValue = cloneDeep(value);
    const clickedItemId = parseInt(event.currentTarget.dataset.id);
    const newItem = cloneDeep(
      filteredOptions.find(({ id }) => id === clickedItemId),
    );
    nextValue.push(newItem);
    onChange(nextValue);
  };

  return (
    <StyledResourcePicker>
      <div className="filters">
        <FormControl>
          <TextControl
            onChange={handleSearchTermChange}
            placeholder="Search resources"
            value={searchTerm}
          />
        </FormControl>
        <FormControl>
          <SelectControl
            onChange={handleCategoryFilterChange}
            options={availableCategories}
            placeholder="Select a category"
            value={categoryFilter}
          />
        </FormControl>
      </div>
      <DataTable
        name='resource-picker-list'
        columns={tableColumns}
        rows={filteredOptions}
        renderActionColumn={(row) => (
          <>
            <IconButton
              disabled={max && value.length >= max}
              data-id={row.id}
              onClick={handleAdd}
              tooltip="Add"
            >
              <AddIcon />
            </IconButton>
          </>
        )}
      />
      <h3>Your selected resources</h3>
      <DataTable
        name='resource-picker-selected'
        columns={tableColumns}
        rows={value.map(({ id }) =>
          options.find((resource) => resource.id === id),
        )}
        noRowsMessage="No resources selected"
        onReorder={onChange}
        onRemoveItems={onChange}
        fullHeight
        selectable
        disableSearch
        selectableActions
      />
    </StyledResourcePicker>
  );
};

export default ResourcePicker;
