import React from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { joiResolver } from '@hookform/resolvers/joi';
import { DevTool } from '@hookform/devtools';
import Joi from 'joi';
import { useForm } from 'react-hook-form';
import {
  Button,
  Checkbox,
  errorMessages,
  StepCard,
  StepCardContent,
  StepCardHeader,
  StepCardTitle,
  StyledCheckboxContainer,
} from '@clatter/ui';
import SiteMakerActions from '../SiteMaker/SiteMakerActions';
import PreviewButton from '../SiteMaker/PreviewButton';
import { getNextPage, isSiteComplete } from '../../helpers';
import routes from '../../routes/routes';
import OptionalCopyBlockFormItem from './OptionalCopyBlockFormItem';
import { Stack } from '@mui/material';
import { useDispatch } from 'react-redux';
import { updatePage } from '../../store';

const copyBlockObject = Joi.object().keys({
  enabled: Joi.boolean(),
  headline: Joi.string().allow('').optional(),
  description: Joi.when('enabled', {
    is: Joi.boolean().valid(true),
    then: Joi.string().required(),
    otherwise: Joi.string().allow('').optional(),
  }),
  showButton: Joi.boolean(),
  buttonText: Joi.when('enabled', {
    is: Joi.boolean().valid(true),
    then: Joi.when('showButton', {
      is: Joi.boolean().valid(true),
      then: Joi.string().required(),
      otherwise: Joi.string().allow('').optional(),
    }),
    otherwise: Joi.string().allow('').optional(),
  }),
  buttonUrl: Joi.when('enabled', {
    is: Joi.boolean().valid(true),
    then: Joi.when('showButton', {
      is: Joi.boolean().valid(true),
      then: Joi.string().uri().required(),
      otherwise: Joi.string().allow('').optional(),
    }),
    otherwise: Joi.string().allow('').optional(),
  }),
});

const validationSchema = Joi.object()
  .keys({
    optionalCopyBlocks: Joi.array().items(copyBlockObject),
  })
  .messages({
    'any.required': errorMessages.REQUIRED.message,
    'string.uri': errorMessages.INVALID.message,
    'string.empty': errorMessages.REQUIRED.message,
    'string.base': errorMessages.REQUIRED.message,
    'array.base': errorMessages.REQUIRED.message,
    'array.min': errorMessages.REQUIRED.message,
    'string.min': errorMessages.REQUIRED.message,
  })
  .options({
    allowUnknown: true,
  });

const OptionalCopyBlockForm = ({
  currentMicrosite,
  currentPage,
  data,
  pageId,
  formConfig,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    control,
    watch,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    getValues,
    reset,
    register,
  } = useForm({
    resolver: joiResolver(validationSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      optionalCopyBlocks: data,
    },
  });

  const handleUpdate = async (formData) => {
    if (isValid && isDirty) {
      const variables = JSON.parse(currentPage?.variables);
      const updatedVariables = {
        ...variables,
        optionalCopyBlocks: formData?.optionalCopyBlocks || [],
      };

      await dispatch(
        updatePage({
          id: pageId,
          variables: JSON.stringify(updatedVariables),
        }),
      );
    }
  };

  const handleFormSubmit = async (formData) => {
    if (isDirty) {
      await handleUpdate(formData);
    }
    history.push(getNextPage(currentMicrosite, currentMicrosite.pages));
  };

  const onPreviewClick = async () => {
    const formData = getValues();
    await handleUpdate(formData);
    reset(formData);
  };

  const redirectToPublish = () =>
    history.push(
      generatePath(routes.publishSite, { siteId: currentMicrosite.id }),
    );

  const renderButtons = () => (
    <>
      <Button disabled={!isValid} type="submit">
        {isDirty ? 'Save Optional Copy Blocks and continue' : 'Continue'}
      </Button>
      <PreviewButton
        siteName={currentMicrosite?.name}
        pageName={currentPage?.name}
        onPreviewClick={onPreviewClick}
      />
      <Button
        disabled={
          isDirty || !isSiteComplete(currentMicrosite, currentMicrosite?.pages)
        }
        onClick={redirectToPublish}
      >
        Publish
      </Button>
    </>
  );

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <DevTool control={control} placement="bottom-left" />
      <StepCard>
        <StepCardHeader step={1}>
          <StepCardTitle text={formConfig?.firstBlockTitle || "Enter first block content"} />
        </StepCardHeader>
        <StepCardContent>
          <Stack direction="column" spacing={4}>
            {
              !formConfig?.hideFirstOptionalCheckbox &&
              <StyledCheckboxContainer>
                <Checkbox
                  {...register(`optionalCopyBlocks[${0}].enabled`)}
                  text="Include First Optional copy block"
                />
              </StyledCheckboxContainer>
            }

            {watch(`optionalCopyBlocks[${0}].enabled`) && (
              <OptionalCopyBlockFormItem
                index={0}
                control={control}
                errors={errors}
                hideCta={formConfig?.hideCta}
                disableHeadline={formConfig?.disableHeadline}
              />
            )}
          </Stack>
        </StepCardContent>
      </StepCard>

      <StepCard>
        <StepCardHeader step={2}>
          <StepCardTitle text={formConfig?.secondBlockTitle || "Enter second block content"} />
        </StepCardHeader>
        <StepCardContent>
          <Stack direction="column" spacing={4}>
            {
              !formConfig?.hideSecondOptionalCheckbox &&
              <StyledCheckboxContainer>
                <Checkbox
                  {...register(`optionalCopyBlocks[${1}].enabled`)}
                  text="Include Second Optional copy block"
                />
              </StyledCheckboxContainer>
            }

            {watch(`optionalCopyBlocks[${1}].enabled`) && (
              <OptionalCopyBlockFormItem
                index={1}
                hideCta={formConfig?.hideCta}
                disableHeadline={formConfig?.disableHeadline}
                control={control}
                errors={errors}
              />
            )}
          </Stack>
        </StepCardContent>
      </StepCard>

      <SiteMakerActions renderButtons={renderButtons} />
    </form>
  );
};

export default OptionalCopyBlockForm;
