import React from 'react';
import { StyledCheckbox } from '.'

const Checkbox = React.forwardRef(({ text, ...inputProps }, ref) => (
  <StyledCheckbox withLabel={!!text?.length}>
    <input type="checkbox" {...inputProps} ref={ref} />
    {!!text && <span className="checkbox-text">{text}</span>}
  </StyledCheckbox>
));

export default Checkbox;
