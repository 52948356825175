import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { messageTypes } from '@clatter/ui';
export const MESSAGES_FEATURE_KEY = 'messages';
export const messagesAdapter = createEntityAdapter();

export const createMessage = (type, text, subscriber = null) => ({
  type,
  text,
  subscriber,
});

export const initialMessagesState = messagesAdapter.getInitialState({
  items: {},
});

export const messagesSlice = createSlice({
  name: MESSAGES_FEATURE_KEY,
  initialState: initialMessagesState,
  reducers: {
    setMessage: (state, { payload: { message } }) => {
      if (message.type in messageTypes) {
        state.items[message.subscriber] = message;
      }
    },
    clearMessage: (state, { payload: { message } }) => {
      state.items[message.subscriber] = undefined;
    },
  },
});

export const messagesReducer = messagesSlice.reducer;

export const messagesActions = messagesSlice.actions;

const { selectAll, selectEntities } = messagesAdapter.getSelectors();
export const getMessagesState = (rootState) => rootState[MESSAGES_FEATURE_KEY];
export const selectAllMessages = createSelector(getMessagesState, selectAll);
export const selectMessagesEntities = createSelector(
  getMessagesState,
  selectEntities,
);
