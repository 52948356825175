import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from "classnames";

const StyledSiteMakerContentHead = styled.div`
  margin-bottom: 16px;

  .content-head {
    margin: 0;
    padding: 0;
  }

  .subhead {
    margin: 16px 0 0 0;

    &.no-title {
      margin: 0;
    }
  }
`;

const SiteMakerContentHead = ({ subhead = '', title }) => (
  <StyledSiteMakerContentHead>
    { title ? <h1 className="content-head">{title}</h1> : <></> }
    {subhead && <p className={classNames("subhead", { "no-title": !title })}>{subhead}</p>}
  </StyledSiteMakerContentHead>
);

SiteMakerContentHead.propTypes = {
  title: PropTypes.string,
  subhead: PropTypes.string,
};

export default SiteMakerContentHead;
