import React, { useState } from 'react';
import styled from 'styled-components';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { VideoGalleryIframe, VideoPreviewDialog } from '@clatter/ui';
import { msmPageTemplatesMap } from '@clatter/platform';
import OptionalCopyBlockSection from './OptionalCopyBlockSection';
import classNames from "classnames";

const StyledHeroBannerImage = styled.div`
  font-family: ${({ theme }) => theme.body.fontFamily};
  background-color: ${({ theme }) => theme.heroBanner.backgroundColor};

  .banner-section {
    position: relative;
    margin: auto;
    width: 82.5%;
    min-width: 320px;
    max-width: ${({ theme }) => theme.section.maxWidth};
  }

  .banner-content {
    padding: ${({ theme }) => theme.heroBanner.padding};
    min-height: ${({ theme }) => theme.heroBanner.minHeight};
    display: flex;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    width: 100%;
    min-width: 320px;
    max-width: 1280px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    align-items: center;
  }

  .banner-video {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 300px;
    max-width: ${({ theme }) => theme.heroBanner.video.maxWidth};
    position: relative;

    .preview-video-button {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      svg {
        font-size: 24px;
        border-radius: 8px;
        background-color: rgba(0, 0, 0, 0.9);
        fill: #fff;
        width: 64px;
        height: 48px;
      }
    }

    .iframe-video {
      position: relative;
      overflow: hidden;
      padding-top: 56.25%;
      //height: 500px;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
      }
    }
  }

  .banner-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    flex-basis: 300px;
    line-height: 1.4;
    min-width: 320px;
    max-width: ${({ theme }) => theme.heroBanner.details.maxWidth};
    margin: 32px 0 32px 0;
    padding: ${({ theme }) => theme.heroBanner.details.padding};
    background-color: ${({ theme }) =>
      theme.heroBanner.details.backgroundColor};

    h1 {
      color: ${({ theme }) => theme.heroBanner.details.header.color};
      font-family: ${({ theme }) => theme.heroBanner.details.header.fontFamily};
      font-size: ${({ theme }) => theme.heroBanner.details.header.fontSize};
      line-height: 1.2;
      margin: 0;
      padding: 0;
      font-weight: ${({ theme }) => theme.heroBanner.details.header.fontWeight};
    }

    p {
      font-size: ${({ theme }) => theme.heroBanner.details.text.fontSize};
      color: ${({ theme }) => theme.heroBanner.details.text.color};
      font-family: ${({ theme }) => theme.heroBanner.details.text.fontFamily};
      font-weight: ${({ theme }) => theme.heroBanner.details.text.fontWeight};
      line-height: 1.4;
      margin-bottom: 0;
    }
  }

  .banner-details-ml {
    margin-left: 50px;
  }

  .banner-background {
    display: none;
  }

  @media (max-width: 769px) {
    .banner-video {
      margin-bottom: 20px;
      max-width: 100%;
    }
  }

  @media (min-width: 1110px) {
    .banner-content {
      justify-content: space-between;
      text-align: left;
    }

    .banner-background {
      display: block;
      background-size: auto 100%;
      background-repeat: no-repeat;
      background-position: right center;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
    }

    .banner-gradient {
      background: ${({ theme }) => theme.heroBanner.gradient};
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
`;

const HeroBannerImage = ({
  bannerVideo,
  headline,
  heroImageUrl = '',
  introCopy,
  optionalSectionTitle,
  optionalCopy,
  showOptionalCopyBlock,
  templateName,
  optionalCopyBackgroundColor,
  optionalCopyLinkColor = null,
}) => {
  const [showPreview, setShowPreview] = useState(false);
  const toggleShowPreview = () => {
    setShowPreview(!showPreview);
  };

  const indentedBannerDetails = (templateName && [
    msmPageTemplatesMap.UHC_MAIN,
    msmPageTemplatesMap.URS_MAIN_MA,
    msmPageTemplatesMap.LANDING_PAGE_MA,
    msmPageTemplatesMap.LANDING_PAGE_UHC,
    msmPageTemplatesMap.UHC_ADDITIONAL_RESOURCES,
    msmPageTemplatesMap.URS_ADDITIONAL_RESOURCES_MA,
  ].includes(templateName))

  const borderedOptionalCopyBlock =
    (templateName &&
      [
        msmPageTemplatesMap.LIFE_SCIENCES_OPTUM_BRAND,
        msmPageTemplatesMap.STATE_GOVERNMENT_OPTUM,
        msmPageTemplatesMap.RESOURCE_AND_DOCUMENT_LIBRARY_OPTUM_BRAND,
        msmPageTemplatesMap.PARTNER_SITE_WITH_TWO_CONTENT_AND_TEAM_OPTUM_RX,
        msmPageTemplatesMap.PROSPECT_SITE_WITH_RESOURCES_AND_LIBRARY_OPTUM_RX,
        msmPageTemplatesMap.EVENTS_OPTUM,
      ].includes(templateName)) ||
    false;

  return (
    <StyledHeroBannerImage>
      <div className="banner-section">
        <div className="banner-content">
          <div className={classNames("banner-details", { "banner-details-ml": indentedBannerDetails })} >
            <h1>{headline}</h1>
            <p>{introCopy}</p>
          </div>
          {bannerVideo && (
            <div className="banner-video">
              <div className="iframe-video">
                <VideoGalleryIframe videoUrl={bannerVideo?.link}/>
              </div>
              <div
                className="preview-video-button"
                data-video-name={bannerVideo?.title || 'Hero Video'} // required by GA4 do not remove
                onClick={toggleShowPreview}
                data-clatter-event={JSON.stringify({
                  type: 'Video Click',
                  data: {
                    name: bannerVideo?.title || 'Hero Video',
                  },
                })}
              >
                <PlayArrowIcon />
              </div>
              <VideoPreviewDialog
                title={bannerVideo?.title}
                videoUrl={bannerVideo?.link}
                open={showPreview}
                onCloseDialog={toggleShowPreview}
                iframeStyles={{
                  display: 'block',
                  width: 640,
                  maxWidth: '100%',
                  minWidth: 200,
                  height: 360,
                }} />
            </div>
          )}
        </div>
        <div
          className="banner-background"
          style={{ backgroundImage: `url(${heroImageUrl})` }}
        >
          <div className="banner-gradient" />
        </div>
      </div>
      {showOptionalCopyBlock && (
        <OptionalCopyBlockSection
          showBorder={borderedOptionalCopyBlock}
          backgroundColor={optionalCopyBackgroundColor}
          linkColor={optionalCopyLinkColor}
          data={[
            {
              headline: optionalSectionTitle,
              description: optionalCopy,
            },
          ]}
          heroSection
        />
      )}
    </StyledHeroBannerImage>
  );
};

export default HeroBannerImage;
