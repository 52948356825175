import { useEffect } from 'react';
import { Stack } from '@mui/material';
import {
  requestStatus,
  useEventReports,
  useGetActiveTool,
  eventRequestTypes,
  platformSelectors,
  fetchCustomAttributes,
  getUserCustomAttributeData,
  generateGroupByOptionsWithAdditionalProps,
  useDeepCompareMemo,
  useTableData,
  usePageTitleHeader,
} from '@clatter/platform';
import {
  ReportsChart,
  PageTitleHeader,
  useDocumentTitle,
  ReportToolsCards,
  ReportGroupsSection,
  ReportFiltersSection,
  ReportDetailsSection,
  FiltersSectionWrapper,
} from '@clatter/ui';
import Page from '../../../components/Page/Page';
import routes, { documentTitleMap } from '../../../routes/routes';
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers, selectUsersByEmail } from "../../../store";

const detailsReportWhatColumns = [
  {
    headerName: 'Microsite Page Name',
    field: 'microsite_page_name',
    sortable: true,
    flex: 1,
  },
  {
    headerName: 'Microsite Name',
    field: 'microsite_name',
    sortable: true,
    flex: 1,
  },
];

const MicrositePageCreateReportView = () => {
  const { documentFileName } = useDocumentTitle(documentTitleMap.reportsMicrositesPageCreate.document);
  const dispatch = useDispatch();
  const activeTool = useGetActiveTool()?.slug;
  const activeToolUppercase = activeTool ? activeTool.toUpperCase() : null;
  const usersByEmailMap = useSelector(selectUsersByEmail);
  const userCustomAttributes = useSelector(platformSelectors.userCustomAttributes.selectAllUserAttributes);
  const userCustomAttributesOptions = getUserCustomAttributeData(userCustomAttributes);
  const reportFiltersInputs = useDeepCompareMemo(
    () => [
      // filter by UCA
      ...(userCustomAttributesOptions.filtersInputs || []),
    ],
    [userCustomAttributes, activeTool],
  );
  const {
    isLoading,
    selectedFilterBy,
    totalReportData,
    totalReportByGroupData,
    reportData,
    detailsReportData,
    selectedToolsWithTotal,
    handleFilterByChange,
    handleGroupByChange,
  } = useEventReports({
    eventType: eventRequestTypes.micrositePageCreated,
    label: 'Microsite Page Created',
    filterByInputs: reportFiltersInputs,
    fetchTotal: {
      filterBy: {
        tool: activeToolUppercase,
      },
    },
    fetchTotalByGroups: {
      filterBy: {
        tool: activeToolUppercase,
      },
    },
    fetchByDay: {
      filterBy: {
        tool: activeToolUppercase,
      },
    },
    fetchDetails: {
      filterBy: {
        tool: activeToolUppercase,
      },
      who: {
        groupBy: 'user_email',
        groupByAdditionalFields: ['last_login', 'user_custom_attributes'],
      },
      what: {
        responseMode: 'details'
      },
    },
  });

  const { renderPageTitleHeader } = usePageTitleHeader({
    pageTitle: documentTitleMap.reportsMicrositesPageCreate.page,
    routes: routes,
  });

  const { usersRows, usersColumns } = useTableData({
    usersReportData: detailsReportData?.who,
    usersByEmailMap: usersByEmailMap,
    usersExtraColumns: [
      {
        headerName: '# of Create',
        field: 'count',
        sortable: true,
        flex: 1,
      },
    ],
  });

  const detailsReportWhatRows = useDeepCompareMemo(() => {
    if (!detailsReportData?.what) {
      return [];
    }

    return detailsReportData?.what?.map((detailReportDataRow, detailReportDataIndex) => ({
      id: detailReportDataIndex + 1,
      microsite_name: detailReportDataRow?.data?.microsite_name,
      microsite_page_name: detailReportDataRow?.data?.microsite_page_name,
    }));
  }, [detailsReportData?.what]);

  const detailsReportTableData = useDeepCompareMemo(() => {
    return {
      tabs: [
        {
          label: 'Who',
        },
        {
          label: 'What',
        },
      ],
      tabsData: [
        {
          rows: usersRows,
          columns: usersColumns,
          exportFileName: `${documentFileName} - Who`,
          tableName: 'report/microsite-page-create-who',
        },
        {
          rows: detailsReportWhatRows,
          columns: detailsReportWhatColumns,
          exportFileName: `${documentFileName} - What`,
          tableName: 'report/microsite-page-create-what',
        },
      ],
    };
  }, [documentFileName, usersRows, usersColumns]);

  //region FILTERS / GROUPS
  const isFiltersDataLoading =
    useSelector(platformSelectors.userCustomAttributes.loadingStatus) === requestStatus.pending;

  const reportGroupByInputs = useDeepCompareMemo(
    () =>
      generateGroupByOptionsWithAdditionalProps({
        options: [
          ...(userCustomAttributesOptions.groupByOptions || []),
        ],
        filterByInputs: reportFiltersInputs,
        selectedFilterBy: selectedFilterBy,
      }),
    [userCustomAttributesOptions, reportFiltersInputs, selectedFilterBy],
  );
  //endregion

  //region EFFECTS
  useEffect(() => {
    dispatch(fetchCustomAttributes());
    dispatch(fetchUsers());
  }, [dispatch]);
  //endregion

  return (
    <Page>
      { renderPageTitleHeader() }

      {!isFiltersDataLoading && (
        <FiltersSectionWrapper
          components={[
            <ReportFiltersSection
              onChange={handleFilterByChange}
              inputs={reportFiltersInputs}
              isLoading={isLoading}
              showDateRangePicker
            />,
            <ReportGroupsSection
              selectedFilterBy={selectedFilterBy}
              onChange={handleGroupByChange}
              inputs={reportGroupByInputs}
              isLoading={isLoading}
            />,
          ]}
        />
      )}

      <Stack direction="column" spacing={2}>
        <ReportToolsCards totalReportData={totalReportData} />

        <ReportsChart
          title="Microsite Page Created"
          reportData={reportData}
          selectedTools={selectedToolsWithTotal}
          loading={isLoading}
        />

        {totalReportByGroupData && (
          <ReportDetailsSection
            loading={isLoading}
            tableName="report/total-microsites-page-create-by-groups"
            exportFileName={`${documentFileName} - Total Microsite Page Created by Groups`}
            columns={[
              {
                headerName: '# of Create',
                field: 'count',
                sortable: true,
                flex: 1,
              },
            ]}
            rows={totalReportByGroupData}
          />
        )}

        <ReportDetailsSection
          loading={isLoading}
          exportFileName={documentFileName}
          tabsData={detailsReportTableData}
        />
      </Stack>
    </Page>
  );
};

export default MicrositePageCreateReportView;
