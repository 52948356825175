export const userRolesMap = {
  contentAdmin: 'MSM-ContentAdmin',
  admin: 'MSM-Admin',
  user: 'MSM-User',
};

export const pageTemplateBlockTypes = {
  BANNER: 'Banner',
  RESOURCES: 'Resources',
  VIDEOS: 'Videos',
  FOOTER: 'Footer',
  BIOS: 'Bios',
  VIDEOS_TABLE: 'Videos Table',
  INTERNAL_RESOURCES: 'Internal Resources',
  QUICK_LINKS: 'Quick Links',
  CONTENT: 'Content',
  ADDITIONAL_INFORMATION: 'Additional Information',
  OPTIONAL_COPY_BLOCKS: 'Optional Copy Blocks',
}

export const RTK_CACHE_TIMEOUT = 5 * 60 * 1000;
