import {
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { messageTypes } from '@clatter/ui';
import {
  hasRole,
  getAxiosCmsWithAuthorization,
  strapiCoBrandLogoToStoreMap,
  ENABLE_CO_BRAND_LOGO_REL,
  populateCoBrandLogoRel,
  requestStatus,
} from '@clatter/platform';
import { createMessage, messagesActions } from './messages.slice';
import { pagesAdapter } from './pages.slice';
import { userRolesMap } from '../constants';
import micrositesApi from "../api/microsites.api";

export const MICROSITES_FEATURE_KEY = 'microsites';

export const micrositesAdapter = createEntityAdapter({
  selectId: (row) => row.id,
  sortComparer: (a, b) => (a.updated_at > b.updated_at ? -1 : 1),
});
const apiBaseUrl = '/microsites';

const getUserByEmail = async (email) => {
  const response = await getAxiosCmsWithAuthorization({
    method: 'get',
    url: `/users?filters[email][$eq]=${email}`,
  });
  const user = response.data[0];
  return user;
};

const getNewSiteName = () => {
  return 'Default Microsite ' + Math.random().toString(36).slice(-4);
};

export const setIsCreatingMicrosite = createAction(
  'SET_IS_CREATING_MICROSITE',
);

export const createMicrosite = createAsyncThunk(
  `${MICROSITES_FEATURE_KEY}/create`,
  async (email) => {
    const response = await getAxiosCmsWithAuthorization({
      method: 'post',
      url: apiBaseUrl,
      data: {
        data: {
          name: getNewSiteName(),
          published: false,
          pages: [],
          owner: email,
          c_created_by: email,
        },
      },
    });

    return Promise.resolve(mapApiToStore(response.data.data));
  },
);

export const updateMicrosite = createAsyncThunk(
  `${MICROSITES_FEATURE_KEY}/update`,
  async (formData, { dispatch, rejectWithValue }) => {
    const payload = {
      ...formData,
    }

    if (ENABLE_CO_BRAND_LOGO_REL && payload?.cobrand_logo_rel?.id) {
      // don't send legacy logo data
      if (payload?.cobrand_logo) {
        delete payload.cobrand_logo;
      }

      // set relation properly
      payload['cobrand_logo_rel'] = { set: [formData?.cobrand_logo_rel.id] };
    }

    try {
      const response = await getAxiosCmsWithAuthorization({
        method: 'put',
        url: `${apiBaseUrl}/${formData.id}?populate=pages.page_template${populateCoBrandLogoRel}`,
        data: {
          data: payload,
        },
      });

      return mapApiToStore(response.data.data);
    } catch (error) {
      dispatch(
        messagesActions.setMessage({
          message: createMessage(
            messageTypes.error,
            'There was an error saving the microsite. Try changing the name and Save again.',
            'updateMicrosite',
          ),
        }),
      );
      return rejectWithValue(error.response.data);
    }
  },
);

// @todo: (almost) 1:1 copy of fetchMicrosites from below; refactor to fetchMicrosites
export const fetchAllMicrosites = createAsyncThunk(
  `${MICROSITES_FEATURE_KEY}/fetchall`,
  async ({ user = null }) => {
    // let's restrict the query by default
    // NOTE: if user will be "undefined" or "null" strapi will
    // correctly return an empty array, not breaking the UI...
    let restrict = `&filters[owner][$eq]=${encodeURIComponent(user.email)}`;

    // if conditions are met remove restriction ang fetch all data
    if (user && hasRole(userRolesMap.admin, user)) {
      restrict = '';
    }

    const response = await getAxiosCmsWithAuthorization({
      method: 'get',
      url: `${apiBaseUrl}?pagination[limit]=-1&populate[pages][count]=true${restrict}`,
    });

    return Promise.resolve(response.data);
  },
);

export const fetchMicrosites = createAsyncThunk(
  `${MICROSITES_FEATURE_KEY}/fetch`,
  async ({ user = null, fetchAll = false, micrositeId = null }) => {
    // keeping it in a variable just for better readability.
    const populate = `cobrand_logo,pages.page_template${populateCoBrandLogoRel}`;

    // let's restrict the query by default
    // NOTE: if user will be "undefined" or "null" strapi will
    // correctly return an empty array, not breaking the UI...
    let restrict = `&filters[owner][$eq]=${encodeURIComponent(user.email)}`;

    // if conditions are met remove restriction ang fetch all data
    if (user && hasRole(userRolesMap.admin, user) || fetchAll) {
      restrict = '';
    }

    if (micrositeId) {
      restrict += `&filters[id][$eq]=${micrositeId}`;
    }

    const response = await getAxiosCmsWithAuthorization({
      method: 'get',
      url: `${apiBaseUrl}?pagination[limit]=-1&populate=${populate}${restrict}`,
    });

    return Promise.resolve(response.data);
  },
);

// @todo: this is unused - remove
export const fetchMicrositeById = createAsyncThunk(
  `${MICROSITES_FEATURE_KEY}/fetchSingle`,
  async (micrositeId) => {
    const response = await getAxiosCmsWithAuthorization({
      method: 'get',
      url: `${apiBaseUrl}/${micrositeId}`,
    });

    return Promise.resolve(response.data);
  },
);

export const deleteMicrosite = createAsyncThunk(
  `${MICROSITES_FEATURE_KEY}/delete`,
  async ({ micrositeId }, { rejectWithValue, getState }) => {
    try {
      if (!micrositeId) {
        throw new Error('Error deleting microsite. No microsite ID provided! Please contact the administrator.');
      }

      if (isNaN(micrositeId)) {
        throw new Error('Error deleting microsite. Invalid microsite ID provided! Please contact the administrator.');
      }

      await micrositesApi.deleteMicrosite({ micrositeId: micrositeId });

      // get removed microsite data so we could
      // update store and create success message
      const microsite = getState()[MICROSITES_FEATURE_KEY].entities[micrositeId];
      const micrositeToDeletePagesIds = microsite?.pages?.map(page => page.id);

      return {
        micrositeId: micrositeId,
        pageIds: micrositeToDeletePagesIds,
        message: `Microsite "${microsite.name}" has been successfully deleted.`
      };
    } catch (error) {

      return rejectWithValue({
        response: error?.response || null,
        message: error?.response?.data?.message || error?.message || 'Error deleting microsite!',
        status: error?.response?.status,
      });
    }
  },
);

export const deleteMicrosites = createAsyncThunk(
  `${MICROSITES_FEATURE_KEY}/deleteBulk`,
  async (microsites) => {
    const deletedPages = await Promise.all(
      microsites
        .reduce(
          (acc, microsite) => [...acc, ...microsite.pages.map(({ id }) => id)],
          [],
        )
        .map((pageId) => getAxiosCmsWithAuthorization({
          method: 'delete',
          url: `/pages/${pageId}`,
        })),
    );

    const deletedMicrosites = await Promise.all(
      microsites.map((microsite) =>
        getAxiosCmsWithAuthorization({
          method: 'delete',
          url: `${apiBaseUrl}/${microsite.id}`,
        }),
      ),
    );

    return Promise.resolve({
      pageIds: deletedPages.map((response) => response.data.id),
      micrositeIds: deletedMicrosites.map((response) => response.data.id),
    });
  },
);

export const cloneMicrosite = createAsyncThunk(`${MICROSITES_FEATURE_KEY}/clone`, async ({ micrositeId }, { rejectWithValue }) => {
  try {
    if (!micrositeId) {
      throw new Error('Error cloning microsite. No microsite ID provided! Please contact the administrator.');
    }

    if (isNaN(micrositeId)) {
      throw new Error('Error cloning microsite. Invalid microsite ID provided! Please contact the administrator.');
    }

    const response = await micrositesApi.postMicrosite({ data: { cloneMicrositeId: micrositeId } });

    return mapApiToStore(response.data.data);

  } catch (error) {
    return rejectWithValue({
      response: error?.response || null,
      message: error?.response?.data?.message || error?.message || 'Error cloning microsite!',
      status: error?.response?.status,
    });
  }
});

export const initialMicrositesState = micrositesAdapter.getInitialState({
  loadingStatus: requestStatus.initial,
  isCreatingMicrosite: false,
  error: null,
});

const mapApiToStore = (item) => ({
  id: item.id,
  ...item.attributes,
  ...(ENABLE_CO_BRAND_LOGO_REL ?
    { cobrand_logo_rel: strapiCoBrandLogoToStoreMap(item?.attributes?.cobrand_logo_rel?.data) } :
    {}
  ),
  cobrand_logo: {
    id: item?.attributes?.cobrand_logo?.data?.id,
    ...(item?.attributes?.cobrand_logo?.data?.attributes || {})
  },
  pages: item.attributes?.pages?.data && Array.isArray(item.attributes?.pages?.data) ? item.attributes?.pages?.data.map(page => ({
    id: page.id,
    name: page.attributes?.title,
    ...page.attributes,
    page_template: page.attributes?.page_template && page.attributes?.page_template?.data !== null ? {
      id: page.attributes?.page_template?.data?.id,
      ...page.attributes?.page_template?.data?.attributes,
    } : null,
  })) : [],
  pagesCount: (() => {
    if (item.attributes?.pages?.data && Array.isArray(item.attributes?.pages?.data)) {
      return item.attributes?.pages?.data.length;
    }

    return item.attributes?.pages?.data?.attributes?.count || 0;
  })(),
});

export const micrositesSlice = createSlice({
  name: MICROSITES_FEATURE_KEY,
  initialState: initialMicrositesState,
  reducers: {
    add: micrositesAdapter.addOne,
    remove: micrositesAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(setIsCreatingMicrosite, (state, { payload }) => {
        state.isCreatingMicrosite = payload;
      })
      .addCase(fetchMicrosites.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(fetchMicrosites.fulfilled, (state, action) => {
        micrositesAdapter.setAll(state, (action.payload.data || []).map(mapApiToStore));
        state.loadingStatus = 'loaded';
      })
      .addCase(fetchMicrosites.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(fetchAllMicrosites.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(fetchAllMicrosites.fulfilled, (state, action) => {
        micrositesAdapter.setAll(state, (action.payload.data || []).map(mapApiToStore));
        state.loadingStatus = 'loaded';
      })
      .addCase(fetchAllMicrosites.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(updateMicrosite.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(updateMicrosite.fulfilled, (state, action) => {
        const { id, ...changes } = action.payload;
        micrositesAdapter.updateOne(state, {
          id: id,
          changes: changes,
        });
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(updateMicrosite.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(deleteMicrosite.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(deleteMicrosite.fulfilled, (state, action) => {
        micrositesAdapter.removeOne(state, action.payload.micrositeId);
        pagesAdapter.removeMany(state, action.payload.pageIds);
        state.loadingStatus =  requestStatus.fulfilled;
      })
      .addCase(deleteMicrosite.rejected, (state) => {
        state.loadingStatus = requestStatus.error;
      })
      .addCase(deleteMicrosites.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(deleteMicrosites.fulfilled, (state, action) => {
        micrositesAdapter.removeMany(state, action.payload.micrositeIds);
        pagesAdapter.removeMany(state, action.payload.pageIds);
        state.loadingStatus = 'loaded';
      })
      .addCase(cloneMicrosite.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(cloneMicrosite.fulfilled, (state, action) => {
        micrositesAdapter.addOne(state, action.payload);
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(cloneMicrosite.rejected, (state) => {
        state.loadingStatus = requestStatus.error;
      })
      .addCase(fetchMicrositeById.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(fetchMicrositeById.fulfilled, (state, action) => {
        micrositesAdapter.addOne(state, action.payload);
        state.loadingStatus = 'loaded';
      });
  },
});

export const micrositesReducer = micrositesSlice.reducer;
export const micrositesActions = micrositesSlice.actions;

const { selectAll, selectEntities } = micrositesAdapter.getSelectors();

export const getMicrositesState = (rootState) =>
  rootState[MICROSITES_FEATURE_KEY];

export const selectAllMicrosites = createSelector(
  getMicrositesState,
  selectAll,
);

export const selectMicrositesEntities = createSelector(
  getMicrositesState,
  selectEntities,
);

export const selectMicrositesLoadingStatus = createSelector(
  getMicrositesState,
  (state) => state.loadingStatus,
);
