import React from 'react';
import styled from 'styled-components';
import ControlError from './ControlError';
import Label from './Label';

const StyledFormControl = styled.div`
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;

  &.no-padding {
    padding: 0;
  }
`;

const FormControl = ({ children, label, error, noPadding }) => (
  <StyledFormControl className={`${noPadding ? 'no-padding' : ''}`}>
    {!!label && <Label>{label}</Label>}
    {children}
    {error && <ControlError message={error.message} />}
  </StyledFormControl>
);

export default FormControl;
