import React from 'react';
import styled from 'styled-components';
import { requestStatus } from '@clatter/platform';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, FormButtons, FormControl, Loader } from '@clatter/ui';
import { addPageTemplateIcon } from '../../../store/page-template-icons.slice';
import FileUpload from '../../FileUpload/FileUpload';

const StyledCreateCompanyForm = styled.form`
  max-width: 480px;
`;

const CreatePageIconForm = ({ templateId, onSuccess }) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState: { errors },
    control,
    formState,
  } = useForm({
    mode: 'onChange',
  });

  const loading =
    useSelector((state) => state.pageTemplateIcons.loadingStatus) ===
    requestStatus.pending;

  const onSubmit = async (formData) => {
    await dispatch(addPageTemplateIcon({ ...formData, templateId })).then(
      ({ payload }) => {
        onSuccess(payload);
      },
    );
  };

  return (
    <StyledCreateCompanyForm onSubmit={handleSubmit(onSubmit)}>
      {loading && <Loader />}
      <FormControl error={errors.file}>
        <Controller
          name="file"
          rules={{ required: { value: true, message: 'Field is required' } }}
          render={({ field: { onChange, value } }) => (
            <FileUpload onChange={onChange} value={value} />
          )}
          control={control}
          defaultValue={null}
        />
      </FormControl>
      <FormButtons>
        <Button disabled={!formState.isValid} type="submit">
          Upload
        </Button>
      </FormButtons>
    </StyledCreateCompanyForm>
  );
};

export default CreatePageIconForm;
