import React from 'react';
import styled from 'styled-components';

const StyledSiteMakerContent = styled.div`
  grid-area: content;

  .page-header {
    padding: 0 0 8px;
  }
`;

const SiteMakerContent = ({ children }) => (
  <StyledSiteMakerContent>{children}</StyledSiteMakerContent>
);

export default SiteMakerContent;
