import React from 'react';
import Section from './Section';
import styled from 'styled-components';
import classNames from 'classnames';
import { Stack } from '@mui/material';
import { EditorControl } from "@clatter/templates";

const StyledOptionalCopyBlockSection = styled(Section)`
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.optionalSection.backgroundColor};
  padding: ${({ theme }) => theme.optionalSection.padding};

  color: ${({ theme }) => theme.optionalSection.copy.color};
  font-family: ${({ theme }) => theme.optionalSection.copy.fontFamily};
  font-size: ${({ theme }) => theme.optionalSection.copy.fontSize};
  white-space: ${({ theme }) => theme.optionalSection.copy.whiteSpace};
  margin: 0;

  &.border-top {
    border-top: 1px solid lightgray;
  }

  &.border-bottom {
    border-bottom: 1px solid lightgray;
  }

  h1 {
    color: ${({ theme }) => theme.optionalSection.title.color};
    font-family: ${({ theme }) => theme.optionalSection.title.fontFamily};
    font-size: ${({ theme }) => theme.optionalSection.title.fontSize};
  }

  &.no-border-bottom {
    border-bottom: none;
  }
`;

const StyledOptionalCopyBlockButton = styled.button`
  cursor: pointer;
  position: relative;
  display: inline-block;
  min-width: 170px;
  padding: ${({ theme }) => theme.optionalSection?.button.padding};
  color: ${({ theme }) => theme.optionalSection?.button.color};
  background-color: ${({ theme }) =>
    theme.optionalSection?.button.backgroundColor};
  border: ${({ theme }) => theme.optionalSection?.button.border};
  border-radius: ${({ theme }) => theme.optionalSection?.button.borderRadius};
  font-family: ${({ theme }) => theme.optionalSection?.button.fontFamily};
  font-size: ${({ theme }) => theme.optionalSection?.button.fontSize};
  font-weight: ${({ theme }) => theme.optionalSection?.button.fontWeight};

  align-self: flex-start;
`;

const OptionalCopyBlockButton = ({ url, label = 'Button' }) => {
  const handleClick = () => {
    if (url) {
      window.open(url, '_blank', 'noopener');
    }
  };

  return (
    <StyledOptionalCopyBlockButton
      type="button"
      onClick={() => handleClick()}
      alt={url}
    >
      {label}
    </StyledOptionalCopyBlockButton>
  );
};

const OptionalCopyBlockItem = ({
  item,
  backgroundColor,
  listItem = false,
  showBorder = false,
  sectionContainer = false,
  linkColor,
  noBorderBottom,
}) => {
  return (
    <StyledOptionalCopyBlockSection
      className={classNames({
        'border-top': showBorder,
        'border-bottom': listItem,
        'no-border-bottom': noBorderBottom,
      })}
      backgroundColor={backgroundColor}
      container={sectionContainer}
    >
      {!!item?.headline && <h1 className="optional-copy-block-headline">{item?.headline}</h1>}
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={7}>
        <EditorControl readOnly noScrollbars value={item?.description} linkColor={linkColor} />
        {item?.showButton && (
          <OptionalCopyBlockButton
            url={item?.buttonUrl}
            label={item?.buttonText}
          />
        )}
      </Stack>
    </StyledOptionalCopyBlockSection>
  );
};

const OptionalCopyBlockSection = ({
  data,
  backgroundColor,
  heroSection,
  showBorder = false,
  linkColor,
}) => {
  if (!data?.length) {
    return null;
  }

  if (heroSection) {
    return (
      <OptionalCopyBlockItem
        showBorder={showBorder}
        item={data[0]}
        backgroundColor={backgroundColor}
        linkColor={linkColor}
      />
    );
  }

  return (
    <>
      {data?.map((item, index) => {
        if (!item?.enabled) {
          return null;
        }
        return (
          <OptionalCopyBlockItem
            item={item}
            key={index}
            backgroundColor={backgroundColor}
            sectionContainer
            listItem
            linkColor={linkColor}
            noBorderBottom={!data?.[1].enabled || index === data?.length - 1} // for now there could be only two items in the "data"
          />
        );
      })}
    </>
  );
};

export default OptionalCopyBlockSection;
