import React from 'react';
import styled from 'styled-components';
import { secondaryColor } from '@clatter/ui';

const StyledBioCard = styled.div`
  padding: 3px;
  border-radius: 4px;
  display: flex;
  position: relative;
  overflow: hidden;
  justify-content: space-between;

  .bio-card-content {
    background-color: #fff;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ddd;
    display: flex;
    width: 100%;
  }

  .bio-card-image {
    background-size: cover;
    background-position: center center;
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 50%;
  }

  .bio-card-details {
    padding-left: 24px;
    width: calc(100% - 48px);
  }

  .bio-card-name {
    font-size: 16px;
    line-height: 28px;
  }

  .bio-card-title {
    font-size: 12px;
    line-height: 16px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .bio-card-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 24px;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
  }

  &.hoverable {
    cursor: pointer;

    &:hover {
      .bio-card-content {
        border-color: ${secondaryColor};
      }
    }
  }

  &.bio-card-selected {
    background-color: ${secondaryColor};

    .bio-card-content {
      border-color: ${secondaryColor};
    }
  }
`;

const BioCard = ({
  details: { name, title, image, id },
  index = 0,
  onClick,
  renderActions,
  selected = false,
}) => {
  const handleClick = (event) => {
    if (typeof onClick === 'function') {
      onClick(event);
    }
  };

  return (
    <StyledBioCard
      className={`${typeof onClick === 'function' ? 'hoverable' : ''} ${
        selected ? 'bio-card-selected' : ''
      }`}
      onClick={handleClick}
      data-id={id}
    >
      <div className="bio-card-content">
        <div
          className="bio-card-image"
          style={{ backgroundImage: `url(${image})` }}
        />
        <div className="bio-card-details">
          <div className="bio-card-name">{name}</div>
          <div className="bio-card-title">{title}</div>
        </div>
      </div>
      {typeof renderActions === 'function' && (
        <div className="bio-card-actions">{renderActions(id, index)}</div>
      )}
    </StyledBioCard>
  );
};

export default BioCard;
