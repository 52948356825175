import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { getAxiosCmsWithAuthorization } from '@clatter/platform';

export const HERO_IMAGES_FEATURE_KEY = 'heroImages';
export const heroImagesAdapter = createEntityAdapter();
const baseUrl = '/hero-images';

const mapApiToStore = (item) => ({
  id: item.id,
  ...item.attributes,
  asset: item.attributes?.asset?.data !== null ? {
    id: item.attributes?.asset?.data?.id,
    ...item.attributes?.asset?.data?.attributes,
  } : null,
});

export const fetchHeroImages = createAsyncThunk(
  `${HERO_IMAGES_FEATURE_KEY}/fetch`,
  async () => {
    const response = await getAxiosCmsWithAuthorization({
      method: 'get',
      url: `${baseUrl}?pagination[limit]=-1&populate=asset&sort=updatedAt:desc`,
    });

    return Promise.resolve(response.data.data);
  },
);

export const createHeroImage = createAsyncThunk(
  `${HERO_IMAGES_FEATURE_KEY}/create`,
  async ({ description, c_created_by, file }) => {
    const response = await getAxiosCmsWithAuthorization({
      method: 'post',
      url: `${baseUrl}?populate=*`,
      data: { data: { description: description, c_created_by: c_created_by } },
    });
    const mappedResponse = mapApiToStore(response.data.data);

    const newUpload = new FormData();
    newUpload.append('files', file);
    newUpload.append('ref', 'api::hero-image.hero-image');
    newUpload.append('refId', mappedResponse.id);
    newUpload.append('field', 'asset');

    const uploadResponse = await getAxiosCmsWithAuthorization({
      method: 'post',
      url: '/upload',
      data: newUpload,
    });

    const newHeroImage = {
      ...mappedResponse,
      asset: {
        ...uploadResponse.data[0],
      },
    };

    return Promise.resolve(newHeroImage);
  },
);

export const updateHeroImage = createAsyncThunk(
  `${HERO_IMAGES_FEATURE_KEY}/update`,
  async ({ id, formData: { description, c_updated_by, file } }) => {
    const response = await getAxiosCmsWithAuthorization({
      method: 'put',
      url: `${baseUrl}/${id}?populate=*`,
      data: { data: { description: description, c_updated_by: c_updated_by }},
    });
    const mappedResponse = mapApiToStore(response.data.data);

    const updatedHeroImage = {
      ...mappedResponse,
    };

    if (file) {
      const newUpload = new FormData();
      newUpload.append('files', file);
      newUpload.append('ref', 'api::hero-image.hero-image');
      newUpload.append('refId', mappedResponse.id);
      newUpload.append('field', 'asset');

      const uploadResponse = await getAxiosCmsWithAuthorization({
        method: 'post',
        url: '/upload',
        data: newUpload,
      });

      updatedHeroImage.asset = uploadResponse.data[0];
    }

    return Promise.resolve(updatedHeroImage);
  },
);

export const deleteHeroImage = createAsyncThunk(
  `${HERO_IMAGES_FEATURE_KEY}/delete`,
  async (id) => {
    const response = await getAxiosCmsWithAuthorization({
      method: 'delete',
      url: `${baseUrl}/${id}`,
    });

    return Promise.resolve(mapApiToStore(response.data.data));
  },
);

export const initialHeroimagesState = heroImagesAdapter.getInitialState({
  loadingStatus: 'loading',
  error: null,
});

export const heroImagesSlice = createSlice({
  name: HERO_IMAGES_FEATURE_KEY,
  initialState: initialHeroimagesState,
  reducers: {
    add: heroImagesAdapter.addOne,
    remove: heroImagesAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHeroImages.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(fetchHeroImages.fulfilled, (state, action) => {
        heroImagesAdapter.setAll(state, (action.payload || []).map(mapApiToStore));
        state.loadingStatus = 'loaded';
      })
      .addCase(fetchHeroImages.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(createHeroImage.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(createHeroImage.fulfilled, (state, action) => {
        heroImagesAdapter.addOne(state, action.payload);
        state.loadingStatus = 'loaded';
      })
      .addCase(createHeroImage.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(deleteHeroImage.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(deleteHeroImage.fulfilled, (state, action) => {
        heroImagesAdapter.removeOne(state, action.payload.id);
        state.loadingStatus = 'loaded';
      })
      .addCase(deleteHeroImage.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(updateHeroImage.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(updateHeroImage.fulfilled, (state, action) => {
        heroImagesAdapter.upsertOne(state, action.payload);
        state.loadingStatus = 'loaded';
      })
      .addCase(updateHeroImage.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      });
  },
});

export const heroImagesReducer = heroImagesSlice.reducer;
export const heroImagesActions = heroImagesSlice.actions;

const { selectAll, selectEntities } = heroImagesAdapter.getSelectors();

export const getHeroimagesState = (rootState) =>
  rootState[HERO_IMAGES_FEATURE_KEY];

export const selectAllHeroimages = createSelector(
  getHeroimagesState,
  selectAll,
);

export const selectHeroimagesEntities = createSelector(
  getHeroimagesState,
  selectEntities,
);
