import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

const StyledTooltipTrigger = styled.div`
  display: inline-flex;

  & + & {
    margin-left: 8px;
  }
`;

const StyledTooltip = styled.div`
  position: fixed;
  top: ${(props) => ('top' in props ? props.top : 0)};
  left: ${(props) => ('left' in props ? props.left : 0)};
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.6);
  border-radius: 1px;
  color: #fff;
  line-height: 20px;
  padding: 0 8px;
  font-size: 11px;
  z-index: 9999;
`;

const Tooltip = ({ children, text }) => {
  const [position, setPosition] = useState(null);

  const handleShowTooltip = (event) => {
    const {
      top,
      left,
      width,
      height,
    } = event.currentTarget.getBoundingClientRect();

    setPosition({
      top: `${top - height - 8}px`,
      left: `${left + width / 2}px`,
    });
  };

  const handleHideTooltip = () => {
    setPosition(null);
  };

  return (
    <StyledTooltipTrigger
      onMouseEnter={handleShowTooltip}
      onMouseLeave={handleHideTooltip}
    >
      {children}
      {position &&
        ReactDOM.createPortal(
          <StyledTooltip top={position?.top} left={position?.left}>
            {text}
          </StyledTooltip>,
          document.body,
        )}
    </StyledTooltipTrigger>
  );
};

export default Tooltip;
