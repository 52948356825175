import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import SectionHeader from './SectionHeader';
import { ContentExpander } from '../../components';

const CTAButton = styled.a`
    text-align: center;
    position: relative;
    display: inline-block;
    cursor: pointer;
    padding: ${({ theme }) => theme.contentExpander?.button.padding};
    color: ${({ theme }) => theme.contentExpander?.button.color};
    background-color: ${({ theme }) => theme.contentExpander?.button.backgroundColor};
    border: ${({ theme }) => theme.contentExpander?.button.border};
    border-radius: ${({ theme }) => theme.contentExpander?.button.borderRadius};
    font-family: ${({ theme }) => theme.contentExpander?.button.fontFamily};
    font-size: ${({ theme }) => theme.contentExpander?.button.fontSize};
    font-weight: ${({ theme }) => theme.contentExpander?.button.fontWeight};

    &:hover {
      color: ${({ theme }) => theme.contentExpander?.button.backgroundColor};
      background-color: ${({ theme }) => theme.contentExpander?.button.color};
    }

    &.full-width {
      width: 100%;
    }
`

const CTAButtonContainer = styled.div`
  margin-top: 20px;
`

const StyledFeaturedResources = styled.div`
  padding: ${({ theme }) => theme.featuredResources?.padding};
  font-family: ${({ theme }) => theme.body?.fontFamily};

  .resources {
    margin: ${({ theme }) => theme.featuredResources?.margin};

    .resources-content {
      display: grid;
      grid-template-columns: ${({ theme }) => theme.featuredResources?.columns};
      gap: ${({ theme }) => theme.featuredResources?.gap};
    }

    .resource-item {
      display: block;
      text-decoration: none;

      span {
        display: block;
        font-size: ${({ theme }) => theme.featuredResources?.item.fontSize};
        font-family: ${({ theme }) => theme.featuredResources?.item.fontFamily};
        color: ${({ theme }) => theme.featuredResources?.item.color};
        text-transform: ${({ theme }) =>
          theme.featuredResources?.item.textTransform};
        font-weight: ${({ theme }) => theme.featuredResources?.item.fontWeight};
        text-decoration: ${({ theme }) => theme.featuredResources?.item.textDecoration};
      }

      p {
        color: ${({ theme }) =>
          theme.featuredResources?.item.description.color};
        font-size: ${({ theme }) =>
          theme.featuredResources?.item.description.fontSize};
        font-family: ${({ theme }) =>
          theme.featuredResources?.item.description.fontFamily};
        margin: ${({ theme }) =>
          theme.featuredResources?.item.description.margin};
      }
    }

    @media (max-width: 600px) {
      gap: ${({ theme }) => theme.featuredResources?.mobileGap};
    }
  }

  .more-resources-wrap {
    display: flex;
    justify-content: ${({ theme }) =>
      theme.featuredResources?.moreResourcesWrap.justifyContent};
    padding-top: ${({ theme }) =>
      theme.featuredResources?.moreResourcesWrap.paddingTop};

    a {
      cursor: pointer;
      padding: 12px 24px;
      color: #fff;
      background-color: #316bbe;
      border: 1px solid #316bbe;
      border-radius: 50px;
    }
  }

  &.resources-vertical {
    min-width: ${({ theme }) => theme.featuredResources?.vertical.minWidth};
    max-width: ${({ theme }) => theme.featuredResources?.vertical.maxWidth};
    background-color: ${({ theme }) =>
      theme.featuredResources?.vertical.backgroundColor};
    padding: ${({ theme }) => theme.featuredResources?.vertical.padding};
    border: ${({ theme }) => theme.featuredResources?.vertical.border};
    border-radius: ${({ theme }) =>
      theme.featuredResources?.vertical.borderRadius};

    .content-expander {
      margin-top: 1.8em;
      button {
        right: 0;
      }
    }

    @media (max-width: 1400px) {
      margin: auto;
      margin-bottom: 60px;
    }

    .resources {
      .resources-content {
        grid-template-columns: 1fr;
        gap: ${({ theme }) => theme.featuredResources?.vertical.gap};
        padding: ${({ theme }) =>
          theme.featuredResources?.vertical.contentPadding};
      }

      .resources-title {
        font-family: ${({ theme }) =>
          theme.featuredResources?.vertical.title.fontFamily};
        font-size: ${({ theme }) =>
          theme.featuredResources?.vertical.title.fontSize};
        font-weight: ${({ theme }) =>
          theme.featuredResources?.vertical.title.fontWeight};
        color: ${({ theme }) => theme.featuredResources?.vertical.title.color};
        margin: ${({ theme }) =>
          theme.featuredResources?.vertical.title.margin};
      }

      .resource-item {
        span {
          text-decoration: ${({ theme }) =>
            theme.featuredResources?.vertical.item.textDecoration};
          font-family: ${({ theme }) =>
            theme.featuredResources?.vertical.item.fontFamily};
          font-size: ${({ theme }) =>
            theme.featuredResources?.vertical.item.fontSize};
          font-weight: ${({ theme }) =>
            theme.featuredResources?.vertical.item.fontWeight};
          color: ${({ theme }) => theme.featuredResources?.vertical.item.color};
          border-bottom: ${({ theme }) =>
            theme.featuredResources?.vertical.item.separator};
        }

        p {
          color: ${({ theme }) =>
            theme.featuredResources?.vertical.item.description.color};
          font-size: ${({ theme }) =>
            theme.featuredResources?.vertical.item.description.fontSize};
          font-family: ${({ theme }) =>
            theme.featuredResources?.vertical.item.description.fontFamily};
          margin: ${({ theme }) =>
            theme.featuredResources?.vertical.item.description.margin};
        }
      }

      @media (max-width: 1400px) {
        .resources-title {
          font-size: 1.8em;
          margin-bottom: 1em;
        }

        .resource-item {
          span {
            font-size: 1.1em;
          }
        }
      }
    }
  }

  &.disable-inset {
    .resources {
      .resources-content {
        padding-left: 0;
      }
    }
  }
`;

const StyledResourceTitle = styled.span`
  &.resource-title-large {
    font-size: 18px !important;
  }
`

const renderResource = ({ resource, hideDescription, largeResourceTitle }) => (
  <a
    className="resource-item"
    key={resource.id}
    href={resource.asset?.url || resource.link}
    title={resource.title}
    target="_blank"
    download={!!resource.asset?.url}
    data-clatter-event={JSON.stringify({
      type: 'Resource Click',
      data: {
        name: resource.title,
      },
    })}
  >
    <StyledResourceTitle className={classNames({ 'resource-title-large': largeResourceTitle})}>
      {resource.title}
    </StyledResourceTitle>
    {!!resource.description && !hideDescription && (
      <p>{resource.description}</p>
    )}
  </a>
);

const renderResourcesContent = ({ resources, title, hideDescription, largeResourceTitle }) => {
  return (
    <div className="resources">
      {title && <h2 className="resources-title">{title}</h2>}
      <div className="resources-content">
        {resources.map((resource) =>
          renderResource({
            resource: resource,
            hideDescription: hideDescription,
            largeResourceTitle: largeResourceTitle
          }),
        )}
      </div>
    </div>
  );
};

const FeaturedResources = ({
  title,
  className,
  disableExpander = false,
  header,
  hideDescription = false,
  resources,
  vertical = false,
  disableInset = false,
  largeResourceTitle = false,
  limit = 4,
  ctaButton = null,
}) => {
  const renderResources = () => {
    if (!resources || (Array.isArray(resources) && !resources.length)) {
      return <p>No resources</p>;
    }

    if (disableExpander) {
      return renderResourcesContent({
        resources: resources,
        title: title,
        hideDescription: hideDescription,
        largeResourceTitle: largeResourceTitle
      });
    }

    return (
      <ContentExpander
        items={resources}
        limit={limit}
        moreText="More resources"
        lessText="Less resources"
      >
        {(items) =>
          renderResourcesContent({
            resources: items,
            hideDescription: hideDescription,
            title: title,
            largeResourceTitle: largeResourceTitle
          })
        }
      </ContentExpander>
    );
  };

  return (
    <StyledFeaturedResources
      className={classNames(className, {
        'resources-vertical': vertical,
        'disable-inset': disableInset,
      })}
    >
      {!!header && <SectionHeader as="h2">{header}</SectionHeader>}
      {renderResources(resources)}

      {ctaButton && ctaButton?.show &&
        <CTAButtonContainer>
          <CTAButton
            title={ctaButton.label}
            target="_blank"
            download
            href={ctaButton?.url}
            className="full-width"
          >
            {ctaButton.label}
          </CTAButton>
        </CTAButtonContainer>
      }
    </StyledFeaturedResources>
  );
};

export default FeaturedResources;
