import mockBlocks from './mockBlocks';
import { generatePath } from 'react-router-dom';

// these are the *fromStore schema transforms
// this mapping transforms pages-from-the-store to pages-the-components
// expect i.e. pageFromStore( page ) => mapped Page

// because of the CMS api limitation of only returning the second level
// object, we need to consult the normalized slice of blockTemplates to
// know what the block types are for a given page template
// for now, we continue cheating using the (currently true) fact that
// page.title === template.title
const pathFromBlockType = {
  resources: '/resources',
  videos: '/videos',
  bios: '/bios',
};

export const pageFromStore = (micrositeId, page, pageTemplates, pageEntity = null) => {
  if (!page?.page_template) {
    console.error('Error: pageFromStore:: page template not provided');
    return null;
  }

  // keeping this check to ensure regression safety...
  if (page?.page_template === 'number' && !pageTemplates.length) {
    console.error('Error: pageFromStore:: page templates data not provided');
    return null
  }

  const pageTemplate = typeof page?.page_template === 'number'
    ? (pageTemplates || []).find((t) => t?.id === page?.page_template)
    : page?.page_template;

  const templateName = pageTemplate?.template;
  const templateBlocks = mockBlocks[templateName];
  const pageBlocks = (templateBlocks || []).map((blockTemplate) => ({
    name: blockTemplate?.name,
    path: generatePath(blockTemplate?.path, { siteId: micrositeId, pageId: page?.id }),
    isComplete: false,
  }));

  const pageFromStore = {
    ...page,
    name: page?.title,
    templateName,
    variables: page?.variables ? page?.variables : '{}', // ensure JSON.parse-able value
    blocks: pageBlocks,
  };

  if (pageEntity) {
    pageFromStore.bios = pageEntity?.bios || [];
    pageFromStore.footer_bios = pageEntity?.footer_bios || [];
    pageFromStore.resources = pageEntity?.resources || [];
    pageFromStore.quick_links = pageEntity?.quick_links || [];
    pageFromStore.video_embeds = pageEntity?.video_embeds || [];
    pageFromStore.content_rails = pageEntity?.content_rails || [];
    pageFromStore.banner_video = pageEntity?.banner_video || null;
    pageFromStore.banner_image = pageEntity?.banner_image || null;
  }

  return pageFromStore;
};

export const resourceFromStore = (resource) => ({
  ...resource,
  name: resource?.title,
  category: resource?.resource_category?.categoryName
    ? resource.resource_category.categoryName
    : '',
});

export const resourceCategoryFromStore = (resourceCategory) => ({
  ...resourceCategory,
});

export const heroImageFromStore = (hero) => ({
  ...hero,
  url: hero?.asset?.url || '',
});

export const bioFromStore = (bio) => {
  // leave message in console about which bio is the problem
  if (bio && !('profilePicture' in bio)) {
    console.error('ERROR: bio with no profile picture', { bio });
  }

  if (bio && 'profilePicture' in bio && bio?.profilePicture === null) {
    console.error('ERROR: bio with null profilePicture', { bio });
  }

  const _bio = {
    ...bio,
    name: bio?.displayName || '',
    groupName: bio?.group?.groupName || '',
    image:
      bio?.profilePicture && bio?.profilePicture?.formats?.thumbnail?.url
        ? bio.profilePicture.formats.thumbnail.url
        : '',
    srcImg:
      bio?.profilePicture && bio?.profilePicture?.formats?.thumbnail?.url
        ? bio.profilePicture.formats.thumbnail.url
        : '',
  };

  return _bio;
};
