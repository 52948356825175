import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { Box } from "@mui/material";
import {
  PageHeader,
  PageContent,
  NoticesList,
  ApiUnavailableErrorBoundary,
} from '@clatter/ui';
import {
  featureFlagsMap,
  platformSelectors,
  requestStatus,
  useAuth,
  useGetActiveTool,
  useNotices,
} from '@clatter/platform';
import { getMenuItems } from '../../helpers/menuItems';
import routes, { documentTitleMap } from '../../routes/routes';
import styled from "styled-components";

const StyledPage = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor || 'none' };
`

const Page = ({ children, withPageContent = true, backgroundColor}) => {
  const { addNotice } = useNotices();
  const { user } = useAuth();
  const activeTool = useGetActiveTool();
  const toolsList = useSelector(platformSelectors.tools.selectAllTools);
  const toolInfo = useSelector(platformSelectors.toolInfo.selectToolInfo);
  const featureFlags = useSelector(
    platformSelectors.featureFlag.selectAllFeatureFlags,
  );
  const analyticsConfig = toolInfo?.data?.analyticsConfig;
  const analyticsEnabled = analyticsConfig?.plausible?.enabled;

  const menuItemsList = getMenuItems({
    user: user,
    featureFlags: featureFlags,
    activeTool: activeTool,
    reports: [
      {
        featureFlag: featureFlagsMap.MSM.pageviews,
        label: documentTitleMap.reportPageViews.page,
        url: generatePath(routes.reportsPageviews),
      },
      {
        featureFlag: featureFlagsMap.MSM.micrositeStatistics,
        label: documentTitleMap.micrositeStatistics.page,
        url: routes.micrositeStatistics,
      },
      {
        featureFlag: featureFlagsMap.MSM.micrositesDetail,
        label: documentTitleMap.reportsMicrositesDetail.page,
        url: generatePath(routes.reportsMicrositesDetail),
      },
      {
        featureFlag: featureFlagsMap.MSM.micrositesCreate,
        label: documentTitleMap.reportsMicrositesCreate.page,
        url: generatePath(routes.reportsMicrositesCreate),
      },
      {
        featureFlag: featureFlagsMap.MSM.micrositesPageCreate,
        label: documentTitleMap.reportsMicrositesPageCreate.page,
        url: generatePath(routes.reportsMicrositesPageCreate),
      },
    ],
    analyticsEnabled: analyticsEnabled,
  });

  const rolesLoadingStatus = useSelector(platformSelectors.roles.loadingStatus);

  useEffect(() => {
    if (rolesLoadingStatus === requestStatus.error) {
      addNotice({
        message: `Error loading roles data`,
        type: 'error',
        title: 'Error',
      });
    }
  }, [rolesLoadingStatus]);

  return (
    <StyledPage backgroundColor={backgroundColor}>
      <PageHeader
        logoUrl={toolInfo?.data?.companyLogo?.location}
        homeUrl="/microsites"
        menuItems={menuItemsList}
        toolItems={toolsList}
      />

      {withPageContent ? (
        <PageContent>
          <ApiUnavailableErrorBoundary>{children}</ApiUnavailableErrorBoundary>
        </PageContent>
      ) : (
        <>
          <Box maxWidth={'calc(100vw - 80px)'} margin="auto" marginTop="1rem">
            <NoticesList />
          </Box>
          {children}
        </>
      )}
    </StyledPage>
  );
};

export default Page;
