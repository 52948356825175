import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

const StyledSection = styled.section`
  font-family: ${({ theme }) => theme.body.fontFamily};
  background-color: ${({ backgroundColor, name, theme }) =>
    (name && theme[name]?.sectionBackgroundColor) || backgroundColor};

  .section-content {
    width: 82.5%;
    min-width: 320px;
    max-width: 1280px;
    margin: 0 auto;
    padding: 24px 0;
  }

  &.no-padding {
    .section-content {
      padding: 0;
    }
  }

  &.container {
    width: 82.5%;
    min-width: 320px;
    max-width: 1280px;
    margin: 0 auto;
    padding: 24px 0;

    .section-content {
      width: 100%;
      max-width: 100%;
      padding: none;
    }
  }


`;

const Section = ({
  children,
  className,
  backgroundColor = 'transparent',
  name,
  noPadding = false,
  container
}) => (
  <StyledSection
    name={name}
    backgroundColor={backgroundColor}
    className={classNames(className, {
      'no-padding': noPadding,
      'container': container
    })}
  >
    <div className={classNames("section-content")}>{children}</div>
  </StyledSection>
);

export default Section;
