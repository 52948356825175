import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { getAxiosCmsWithAuthorization } from '@clatter/platform';
import { requestStatus } from "@clatter/platform";
import { orderBy } from "lodash";

export const COBRANDLOGOS_FEATURE_KEY = 'cobrandLogos';
export const cobrandLogosAdapter = createEntityAdapter();

const mapApiToStore = (item) => ({
  id: item.id,
  ...item.attributes,
  owner: item.attributes?.owner?.data !== null ? {
    id: item.attributes?.owner?.data?.id,
    ...item.attributes?.owner?.data?.attributes,
  } : null,
  asset: item.attributes?.asset?.data !== null ? {
    id: item.attributes?.asset?.data?.id,
    ...item.attributes?.asset?.data?.attributes,
  } : null,
});

export const fetchCobrandLogos = createAsyncThunk(
  `${COBRANDLOGOS_FEATURE_KEY}/fetch`,
  async (props, { rejectWithValue }) => {
    try {
      const response = await getAxiosCmsWithAuthorization({
        method: 'get',
        url: `/cobrand-logos?pagination[limit]=-1&populate=asset&sort=updatedAt:desc`,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const updateCoBrandLogo = createAsyncThunk(
  `${COBRANDLOGOS_FEATURE_KEY}/update`,
  async ({ id, company, file, updatedBy }, { rejectWithValue }) => {
    try {
      const response = await getAxiosCmsWithAuthorization({
        method: 'put',
        url: `/cobrand-logos/${id}?populate=*`,
        data: { data: {
          company: company,
          c_updated_by: updatedBy,
        } },
      });

      const updatedCoBrandLogo = response.data.data;

      if (file) {
        const newUpload = new FormData();
        newUpload.append('files', file);
        newUpload.append('ref', 'api::cobrand-logo.cobrand-logo');
        newUpload.append('refId', updatedCoBrandLogo.id);
        newUpload.append('field', 'asset');

        const uploadResponse = await getAxiosCmsWithAuthorization({
          method: 'post',
          url: '/upload',
          data: newUpload,
        });

        updatedCoBrandLogo['attributes']['asset'] = {
          data: {
            id: uploadResponse.data[0]?.id,
            attributes: uploadResponse.data[0]
          }
        };
      }

      return updatedCoBrandLogo;
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const addCobrandLogo = createAsyncThunk(
  `${COBRANDLOGOS_FEATURE_KEY}/add`,
  async ({ company, file, createdBy }, { rejectWithValue }) => {
    try {
      const response = await getAxiosCmsWithAuthorization({
        method: 'post',
        url: '/cobrand-logos',
        data: { data: {
          company: company,
          c_created_by: createdBy,
        } },
      });

      const newUpload = new FormData();
      newUpload.append('files', file);
      newUpload.append('ref', 'api::cobrand-logo.cobrand-logo');
      newUpload.append('refId', response.data.data.id);
      newUpload.append('field', 'asset');

      const uploadResponse = await getAxiosCmsWithAuthorization({
        method: 'post',
        url: '/upload',
        data: newUpload,
      });

      let newItem = response.data.data;

      newItem['attributes']['asset'] = {
        data: {
          id: uploadResponse.data[0].id,
          attributes: uploadResponse.data[0],
        }
      };

      return mapApiToStore(newItem);
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const deleteCobrandLogo = createAsyncThunk(
  `${COBRANDLOGOS_FEATURE_KEY}/delete`,
  async (logoId, { rejectWithValue }) => {
    try {
      const response = await getAxiosCmsWithAuthorization({
        method: 'delete',
        url: `/cobrand-logos/${logoId}`,
      });

      return response.data.data.id;
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const initialCobrandLogosState = cobrandLogosAdapter.getInitialState({
  loadingStatus: requestStatus.pending,
  error: null,
});

export const cobrandLogosSlice = createSlice({
  name: COBRANDLOGOS_FEATURE_KEY,
  initialState: initialCobrandLogosState,
  reducers: {
    add: cobrandLogosAdapter.addOne,
    remove: cobrandLogosAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCobrandLogos.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(fetchCobrandLogos.fulfilled, (state, action) => {
        cobrandLogosAdapter.setAll(state, (action.payload.data || []).map(mapApiToStore));
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(fetchCobrandLogos.rejected, (state, action) => {
        state.error = action?.payload?.error?.message || action?.error?.message || 'Error fetching co brand logos';
        state.loadingStatus = requestStatus.error;
      })
      .addCase(addCobrandLogo.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(addCobrandLogo.fulfilled, (state, action) => {
        cobrandLogosAdapter.addOne(state, action.payload);
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(addCobrandLogo.rejected, (state, action) => {
        state.error = action?.payload?.error?.message || action?.error?.message || 'Error creating co brand logo';
        state.loadingStatus = requestStatus.error;
      })
      .addCase(updateCoBrandLogo.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(updateCoBrandLogo.fulfilled, (state, action) => {
        cobrandLogosAdapter.upsertOne(state, mapApiToStore(action.payload));
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(updateCoBrandLogo.rejected, (state, action) => {
        state.error = action?.payload?.error?.message || action?.error?.message || 'Error updating co brand logo';
        state.loadingStatus = requestStatus.error;
      })
      .addCase(deleteCobrandLogo.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(deleteCobrandLogo.fulfilled, (state, action) => {
        cobrandLogosAdapter.removeOne(state, action?.payload);
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(deleteCobrandLogo.rejected, (state, action) => {
        state.error = action?.payload?.error?.message || action?.error?.message || 'Error deleting co brand logo';
        state.loadingStatus = requestStatus.error;
      });
  },
});

export const cobrandLogosReducer = cobrandLogosSlice.reducer;
export const cobrandLogosActions = cobrandLogosSlice.actions;

const { selectAll } = cobrandLogosAdapter.getSelectors();

export const getCobrandLogosState = (rootState) =>
  rootState[COBRANDLOGOS_FEATURE_KEY];

export const selectAllCobrandLogos = createSelector(
  getCobrandLogosState,
  selectAll,
);

export const selectSortedCoBrandLogos = createSelector(getCobrandLogosState, (state) =>
  orderBy(selectAll(state), [(logo) => logo?.company?.toLowerCase()]),
);
