import React, { useEffect, useState } from 'react';
import { isRejected } from '@reduxjs/toolkit';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import {
  Button,
  FormButtons,
  FormControl,
  TextControl,
  SelectControl,
  IconButton,
  RadioGroup,
  noticeTypes,
  buttonVariants,
} from '@clatter/ui';
import { useAuth, useNotices } from '@clatter/platform';
import FileUpload from '../../FileUpload/FileUpload';
import { useDispatch, useSelector } from 'react-redux';
import { createResource, updateResource } from '../../../store/resources.slice';
import {
  fetchResourceCategories,
  selectAllResourceCategories,
} from '../../../store/resource-categories.slice';

const StyledResourceForm = styled.form`
  width: 480px;

  .file {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 8px;
    padding: 8px 12px;
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;

    .file-icon {
      fill: #1890ff;
      font-size: 16px;
    }

    .file-name {
      padding: 0 8px;
      font-size: 14px;
      max-width: 80%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .file-remove {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;

const ResourceForm = ({ isEdit, editableItem, onSuccess, onClose }) => {
  const dispatch = useDispatch();
  const { activeUser } = useAuth();
  const { addNotice } = useNotices();

  const [showUploadForm, setShowUploadForm] = useState(false);

  const getEditResourceType = () => editableItem && 'asset' in editableItem && editableItem?.asset ? 'file' : 'link';

  const {
    control,
    formState: { errors, isDirty, isValid },
    setError,
    reset,
    handleSubmit,
    register,
    watch,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      link: editableItem?.link || '',
      title: editableItem?.title || '',
      description: editableItem?.description || '',
      resource_category: editableItem?.resource_category || null,
      resourceType: getEditResourceType(),
    }
  });

  const resourceCategories = useSelector(selectAllResourceCategories);

  useEffect(() => {
    if (!resourceCategories || !resourceCategories.length) {
      dispatch(fetchResourceCategories());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (formData) => {
    const response = await dispatch(
      isEdit
        ? updateResource({ id: editableItem.id, formData: { ...formData, updatedBy: activeUser.email } })
        : createResource({ ...formData, createdBy: activeUser.email }),
    );

    // handle errors
    if (isRejected(response)) {
      // for now, we are only handling file upload error
      // for form errors that comes from strapi
      if (typeof response?.payload === "string" && response?.payload?.includes('upload')) {
        setError('file', {
          message: response.payload,
        });

        // this is form error: do not close modal
        return;
      }

      addNotice({
        message: response?.payload?.error?.message
          || response?.payload
          || `Sorry, something went wrong while ${isEdit ? 'updating' : `creating resource: ${formData?.title}`}!`,
        type: noticeTypes.error,
      });

      // error not related with form: close modal
      return onSuccess();
    }

    // success case
    addNotice({
      message: `Resource "${formData?.title}" has been successfully ${isEdit ? 'updated.' : 'created.'}`,
      type: noticeTypes.success,
    });

    return onSuccess(); // to be exact it is only closing the modal window currently
  };

  const handleShowUploadForm = () => {
    setShowUploadForm(true);
  };

  const renderResource = (resourceType) => {
    switch (resourceType) {
      case 'link':
        return (
          <FormControl label="Link" error={errors.link}>
            <TextControl
              {...register('link', {
                required: { value: true, message: 'Field is required' },
              })}
              placeholder="Enter url"
            />
          </FormControl>
        );

      case 'file':
        if (!showUploadForm && editableItem?.asset) {
          return (
            <div className="file">
              <InsertDriveFileOutlinedIcon className="file-icon" />
              <div className="file-name">{editableItem.asset.name}</div>
              <IconButton
                className="file-remove"
                onClick={handleShowUploadForm}
              >
                <ClearOutlinedIcon />
              </IconButton>
            </div>
          );
        }

        return (
          <FormControl error={errors.file}>
            <Controller
              name="file"
              rules={{
                required: { value: true, message: 'Field is required' },
              }}
              render={({ field: { onChange, value } }) => (
                <FileUpload onChange={onChange} value={value} />
              )}
              control={control}
            />
          </FormControl>
        );

      default:
        return null;
    }
  };

  return (
    <StyledResourceForm onSubmit={handleSubmit(onSubmit)}>
      <FormControl>
        <RadioGroup
          disabled={isEdit}
          {...register('resourceType', {
            validate: (value) => {
              if (!value) {
                return 'Field is required';
              }
            },
          })}
          options={[
            {
              label: 'Link',
              value: 'link',
            },
            {
              label: 'File',
              value: 'file',
            },
          ]}
        />
      </FormControl>
      {renderResource(watch('resourceType'))}
      <FormControl label="Name" error={errors.title}>
        <TextControl
          {...register('title', {
            required: { value: true, message: 'Field is required' },
          })}
          placeholder="Enter a name"
        />
      </FormControl>
      <FormControl label="Description" error={errors.description}>
        <TextControl
          {...register('description')}
          placeholder="Enter a description"
        />
      </FormControl>
      <FormControl label="Category">
        <Controller
          name="resource_category"
          rules={{
            required: { value: true, message: 'Field is required' },
          }}
          render={({ field: { onChange, value } }) => (
            <SelectControl
              onChange={onChange}
              options={resourceCategories || []}
              value={value}
              getOptionLabel={(option) => option.categoryName}
              getOptionValue={(option) => option.id}
            />
          )}
          control={control}
        />
      </FormControl>
      <FormButtons
        leftContent={
          <Button disabled={!isDirty} type="reset" variant={buttonVariants.clear} onClick={() => reset()}>
            Reset
          </Button>
        }
        rightContent={
          <>
            <Button type="button" variant={buttonVariants.clear} onClick={() => onClose()}>
              Cancel
            </Button>

            <Button disabled={!isValid || !isDirty} type="submit">
              {isEdit ? 'Save' : 'Create'}
            </Button>
          </>
        }
      />
    </StyledResourceForm>
  );
};

export default ResourceForm;
