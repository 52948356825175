import React, { useEffect, useRef } from 'react';
import { Grid } from '@mui/material';
import {
  Button,
  buttonVariants,
  FormControl,
  SelectControl,
} from '@clatter/ui';
import { DevTool } from '@hookform/devtools';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';

const StyledLandingPageForm = styled.form`
  width: 480px;
`;

const LandingPageForm = ({
  defaultLandingPage,
  onSubmit,
  options,
  disabled,
}) => {
  const formRef = useRef();

  const { control, watch, handleSubmit, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      landing_page: defaultLandingPage,
    },
  });

  useEffect(() => {
    setValue('landing_page', defaultLandingPage);
  }, [defaultLandingPage]);

  return (
    <StyledLandingPageForm ref={formRef} onSubmit={handleSubmit(onSubmit)}>
      <DevTool control={control} placement="bottom-left" />
      <p>
        Only published microsites without password protection will be listed.
      </p>
      <FormControl label="Landing Page">
        <Controller
          name="landing_page"
          render={({ field: { value, onChange } }) => (
            <SelectControl
              isDisabled={disabled}
              noOptionsMessage={() =>
                `No published microsites found. Please publish Landing Page site first!`
              }
              getOptionValue={({ siteName, pageName }) =>
                `${siteName} / ${pageName}`
              }
              getOptionLabel={({ siteName, pageName }) =>
                `${siteName} / ${pageName}`
              }
              // NOTE: if one microsite will have more than one Landing Page,
              // this will not work properly...
              value={options.find((site) => site.siteId === value?.siteId)}
              onChange={onChange}
              options={options}
            />
          )}
          control={control}
        />
      </FormControl>
      <Grid container spacing={3} direction="column">
        <Grid item>
          <Button
            variant={buttonVariants.secondary}
            disabled={
              // NOTE: if one microsite will have more than one Landing Page,
              // and it will be `defaultLandingPage` both landing page options will
              // be disabled!!
              watch('landing_page')?.siteId === defaultLandingPage?.siteId
            }
            type="submit"
          >
            Set as Landing Page
          </Button>
        </Grid>
        <Grid item>
          <Button
            disabled={!defaultLandingPage}
            variant={buttonVariants.primary}
            type="button"
            onClick={() => {
              if (
                window.confirm(
                  `Are you sure you want to remove current (${
                    watch('landing_page')?.siteName
                  }) landing page?`,
                )
              ) {
                setValue('landing_page', '');
                return handleSubmit(onSubmit)();
              }
            }}
          >
            Reset to default Landing Page
          </Button>
        </Grid>
      </Grid>
    </StyledLandingPageForm>
  );
};

export default LandingPageForm;
