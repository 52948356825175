import React from 'react';
import styled from 'styled-components';

export const StyledSectionHeader = styled.h1`
  padding: ${({ theme }) => theme?.sectionHeader?.padding};
  font-weight: ${({ theme }) => theme?.sectionHeader?.fontWeight};
  margin: 0;
  color: ${({ theme }) => theme?.sectionHeader?.color};
  font-family: ${({ theme }) => theme?.sectionHeader?.fontFamily};
  font-size: ${({ theme }) => theme?.sectionHeader?.fontSize};
  letter-spacing: ${({ theme }) => theme?.sectionHeader?.letterSpacing};
  line-height: ${({ theme }) => theme?.sectionHeader?.lineHeight};

  &.no-padding {
    padding: 0;
  }
`;

const SectionHeader = ({ as = 'h1', children, noPadding = false }) => (
  <StyledSectionHeader as={as} className={`${noPadding ? 'no-padding' : ''}`}>
    {children}
  </StyledSectionHeader>
);

export default SectionHeader;
