import React from 'react';
import styled from 'styled-components';
import { IconButton, Typography } from '@clatter/ui';
import { VisibilityOutlined as VisibilityOutlinedIcon } from '@mui/icons-material';

const StyledTemplateUsagesList = styled.div`
  max-height: 450px;
  min-width: 650px;

  .list-container {
    padding: 30px 20px;
  }

  .usage-list-item {
    cursor: default;
    display: flex;
    justify-content: space-between;
    align-items: center;
    row-gap: 10px;
    padding: 5px;

    h4 {
      margin: 0;
    }

    &:hover {
      background-color: ${({ theme }) => theme.palette.grey};
      transition: all 0.2s ease-in;
    }

    .actions-container {
      display: flex;
      justify-content: space-between;
    }
  }
`;

const TemplateUsagesList = ({
  template,
  pageTemplatesUsage,
  micrositesMap,
  onViewMicrosite,
}) => {
  return (
    <StyledTemplateUsagesList>
      <Typography variant="h4">
        "{template.title}" template is used in {pageTemplatesUsage.size} sites:
      </Typography>
      <hr />
      <div className="list-container">
        {(Array.from(pageTemplatesUsage) || []).map((id, index) => (
          <div className="usage-list-item" key={index}>
            <h4>{micrositesMap[id]?.name}</h4>
            <div>
              <IconButton onClick={() => onViewMicrosite(id)}>
                <VisibilityOutlinedIcon />
              </IconButton>
            </div>
          </div>
        ))}
      </div>
    </StyledTemplateUsagesList>
  );
};

export default TemplateUsagesList;
