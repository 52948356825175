import React, { useEffect, useState } from 'react';
import {
  DataTable,
  SelectControl,
  TextControl,
  FormControl,
} from '../../components';
import {
  IconButton2,
  CopyIcon,
  PlayArrowIcon,
  DownloadArrowIcon,
  VideoPreviewDialog,
} from '@clatter/ui';
import styled from 'styled-components';
import classNames from 'classnames';

export const videoTableColumns = [
  {
    label: 'Title',
    name: 'title',
    align: 'left',
  },
  {
    label: 'Category',
    name: 'category',
    align: 'left',
  },
  {
    label: 'Link',
    name: 'link',
    align: 'left',
    customRender: (row, cell) => (
      <div style={{ padding: 0 }}>
        <span style={{ paddingLeft: 0 }}>{cell}</span>
        <IconButton2 onClick={() => navigator.clipboard.writeText(cell)}>
          <CopyIcon />
        </IconButton2>
      </div>
    ),
  },
];

const StyledVideosTable = styled.div`
  .filters {
    display: flex;
    grid-column-gap: 60px;
    padding: 0 5px 5px;
    margin-bottom: 55px;
    flex-direction: column;

    > * {
      min-width: 220px;
    }

    @media (min-width: 660px) {
      flex-direction: row;
    }

    @media (min-width: 860px) {
      > * {
        min-width: 295px;
      }
    }
  }

  th,
  th * {
    font-family: ${({ theme }) => theme?.videosTable?.tableHeader?.fontFamily};
    font-size: ${({ theme }) => theme?.videosTable?.tableHeader?.fontSize};
    color: ${({ theme }) => theme?.videosTable?.tableHeader?.color};
    line-height: 32px !important;
    margin-bottom: 20px !important;
    padding: 0 !important;
  }

  .table-th-label {
    margin-bottom: 0;
  }

  .table-th-actions {
    display: flex;
    justify-content: flex-end;
  }

  td {
    /* display: flex !important; */
    margin: 0;
  }

  @media (max-width: 1060px) {
    // hide columns on mobile
    td:nth-child(2),
    td:nth-child(3),
    th:nth-child(2),
    th:nth-child(3) {
      display: none;
    }

    &.hide-download-button {
      // hide download button on mobile
      td:nth-child(4) {
        button:nth-child(2) {
          display: none;
        }
      }
    }
  }
`;

const VideosTable = ({
  videos,
  categories,
  tableName,
  categoryFieldName,
  searchLabel,
  selectLabel,
  tableColumns = null,
  actionsColumnName = null,
  hideDownloadButton,
  disableActions = false,
}) => {
  const [previewVideo, setPreviewVideo] = useState(null);
  const [availableCategories, setAvailableCategories] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredVideos, setFilteredVideos] = useState([]);

  useEffect(() => {
    const nextAvailableCategories = [...categories];

    nextAvailableCategories.unshift({
      label: 'All',
      value: null,
    });

    setAvailableCategories(nextAvailableCategories);
  }, [categories?.length]);

  useEffect(() => {
    let nextFilteredVideos = videos;

    if (categoryFilter && categoryFilter.value) {
      nextFilteredVideos = nextFilteredVideos.filter(
        (item) => item.category === categoryFilter.value,
      );
    }

    if (searchTerm) {
      nextFilteredVideos = nextFilteredVideos.filter((video) =>
        video.title.toLowerCase().includes(searchTerm.toLowerCase()),
      );
    }

    setFilteredVideos(nextFilteredVideos);
  }, [videos, searchTerm, categoryFilter, categoryFieldName]);

  const handleCategoryFilterChange = (option) => {
    setCategoryFilter(option);
  };

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePreviewClick = (event) => {
    const previewVideo = videos.find(
      (item) => item.id === parseInt(event.currentTarget.dataset.id),
    );

    setPreviewVideo(previewVideo);
  };

  const handleDownloadClick = (event) => {
    const previewVideo = videos.find(
      (item) => item.id === parseInt(event.currentTarget.dataset.id),
    );

    window.open(previewVideo.download_link, '_blank');
  };

  const handleClosePreviewDialog = () => {
    setPreviewVideo(null);
  };

  return (
    <StyledVideosTable
      className={classNames({ 'hide-download-button': hideDownloadButton })}
    >
      <div className="filters">
        <FormControl label={searchLabel}>
          <TextControl onChange={handleSearchTermChange} value={searchTerm} />
        </FormControl>
        <FormControl label={selectLabel}>
          <SelectControl
            onChange={handleCategoryFilterChange}
            options={availableCategories}
            value={categoryFilter}
          />
        </FormControl>
      </div>
      <DataTable
        name={tableName || 'videos-table'}
        autofit
        columns={tableColumns || videoTableColumns}
        rows={filteredVideos}
        actionsColumnName={actionsColumnName}
        renderActionColumn={
          disableActions
            ? null
            : (item) => (
                <>
                  <IconButton2
                    data-id={item.id}
                    data-video-name={item?.title || 'Video Template Video'} // required by GA4 - do not remove
                    onClick={handlePreviewClick}
                    data-clatter-event={JSON.stringify({
                      type: 'Video Click',
                      data: {
                        name: item?.title || 'Video Template Video',
                      },
                    })}
                  >
                    <PlayArrowIcon />
                  </IconButton2>
                  <IconButton2 data-id={item.id} onClick={handleDownloadClick}>
                    <DownloadArrowIcon />
                  </IconButton2>
                </>
              )
        }
      />
      <VideoPreviewDialog
        title={previewVideo?.title}
        videoUrl={previewVideo?.link}
        open={!!previewVideo}
        onCloseDialog={handleClosePreviewDialog}
      />
    </StyledVideosTable>
  );
};

export default VideosTable;
