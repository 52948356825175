import { getAxiosWithAuthorization } from '@clatter/platform';

const systemConfigurationApi = {
  updateSystemConfiguration: ({ configurationId, data }) =>
    getAxiosWithAuthorization({
      url: `/system-configuration/${configurationId}`,
      method: 'patch',
      data: data,
    }),
  getSystemConfiguration: ({ configurationId }) =>
    getAxiosWithAuthorization({
      url: `/system-configuration`,
      method: 'GET',
    }),
};

export default systemConfigurationApi;
