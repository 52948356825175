import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { EditorControl, getMaskedValue } from '../../components';

const StyledCustomerBio = styled.button`
  display: flex;
  align-self: baseline;
  outline: none;
  border: none;
  background-color: transparent;
  max-width: ${({ theme }) => theme.customerBio?.maxWidth};
  padding:  ${({ theme }) => theme.customerBio?.padding};

  .team-bio-image {
    border-radius: 50%;
    width: ${({ theme }) => theme.customerBio?.avatar.size};
    height: ${({ theme }) => theme.customerBio?.avatar.size};
    background-size: cover;
    background-position: center center;
    background-image: ${({ image }) => (image ? `url(${image})` : 'none')};

    flex-shrink: 0;

    &.no-image {
      background-color: #f4f4f4;
      border: 1px solid #ddd;

      span {
        margin-top: 50%;
        display: block;
        transform: translateY(-50%);
      }
    }
  }

  .team-bio-details {
    text-align: ${({ theme }) => theme.customerBio?.details?.textAlign};
    padding: ${({ theme }) => theme.customerBio?.details?.padding};
    font-family: ${({ theme }) => theme.body.fontFamily};

    &.no-padding-top {
      padding-top: 0;
    }

    a {
      cursor: pointer;
      :hover {
        color: ${({ theme }) => theme?.link?.color};
        text-decoration: underline;
      }
    }

    .team-bio-name {
      font-family: ${({ theme }) => theme.customerBio?.details?.name.fontFamily};
      font-size: ${({ theme }) => theme.customerBio?.details?.name.fontSize};
      font-weight: ${({ theme }) => theme.customerBio?.details?.name.fontWeight};
      color: ${({ theme }) => theme.customerBio?.details?.name.color};
      margin: ${({ theme }) => theme.customerBio?.details?.name.margin};
    }

    .team-bio-title {
      font-family: ${({ theme }) => theme.customerBio?.details?.title.fontFamily};
      font-size: ${({ theme }) => theme.customerBio?.details?.title.fontSize};
      font-weight: ${({ theme }) => theme.customerBio?.details?.title.fontWeight};
      color: ${({ theme }) => theme.customerBio?.details?.title.color};
      line-height: ${({ theme }) => theme.customerBio?.details?.title.lineHeight};
    }

    .team-bio-email {
      display: block;
      font-family: ${({ theme }) => theme.customerBio?.details?.email.fontFamily};
      font-size: ${({ theme }) => theme.customerBio?.details?.email.fontSize};
      font-weight: ${({ theme }) => theme.customerBio?.details?.email.fontWeight};
      color: ${({ theme }) => theme.customerBio?.details?.email.color};
      margin: ${({ theme }) => theme.customerBio?.details?.email.margin};
    }

    .team-bio-phone {
      display: block;
      font-family: ${({ theme }) => theme.customerBio?.details?.phone.fontFamily};
      font-size: ${({ theme }) => theme.customerBio?.details?.phone.fontSize};
      font-weight: ${({ theme }) => theme.customerBio?.details?.phone.fontWeight};
      color: ${({ theme }) => theme.customerBio?.details?.phone.color};
      margin: ${({ theme }) => theme.customerBio?.details?.phone.margin};
    }

    .team-bio-description {
      display: block;
      font-family: ${({ theme }) => theme.customerBio?.details?.description.fontFamily};
      font-size: ${({ theme }) => theme.customerBio?.details?.description.fontSize};
      font-weight: ${({ theme }) => theme.customerBio?.details?.description.fontWeight};
      color: ${({ theme }) => theme.customerBio?.details?.description.color};
      margin: ${({ theme }) => theme.customerBio?.details?.description.margin};
      line-height: ${({ theme }) => theme.customerBio?.details?.description.lineHeight};
    }

    .team-bio-click {
      font-family: ${({ theme }) => theme.customerBio?.details?.bioClick.fontFamily};
      margin: ${({ theme }) => theme.customerBio?.details?.bioClick.margin};
      color: ${({ theme }) => theme.customerBio?.details?.bioClick.color};
      text-decoration: ${({ theme }) => theme.customerBio?.details?.bioClick.textDecoration};
      font-size: ${({ theme }) => theme.customerBio?.details?.bioClick.fontSize};
      font-weight: ${({ theme }) => theme.customerBio?.details?.bioClick.fontWeight};

      &:hover {
        text-decoration: ${({ theme }) => theme.customerBio?.details?.bioClick.hoverTextDecoration};
      }
    }
  }

  &.clickable {
    cursor: pointer;
  }

  &.footer-item {
    .team-bio-email,
    .team-bio-phone {
      margin: 0.3rem auto;
    }
  }
`;

const CustomerBio = ({
  description,
  email,
  name,
  image,
  onClickText,
  phone,
  title,
  onClick,
  footerItem = false,
  ...dataAttributes
}) => (
  <StyledCustomerBio
    {...dataAttributes}
    type="button"
    image={image}
    onClick={onClick}
    className={classNames('customer-bio', {
      clickable: typeof onClick === 'function',
      'footer-item': footerItem,
    })}
    data-clatter-event={JSON.stringify({
      type: 'Bio Click',
      data: {
        name: dataAttributes['data-bio-name'],
      },
    })}
  >
    <div className={classNames('team-bio-image', { 'no-image': !image })}>
      {!image && <span>No image</span>}
    </div>
    <div
      className={classNames('team-bio-details', {
        'no-padding-top': footerItem && (email || phone),
      })}
    >
      <div className="team-bio-name">{name}</div>
      <div className="team-bio-title">{title}</div>
      {!!onClickText && <div className="team-bio-click">{onClickText}</div>}
      {!!email && (
        <a className="team-bio-email" href={`mailto: ${email}`}>
          {email}
        </a>
      )}
      {!!phone && (
        <p className="team-bio-phone">
          {getMaskedValue('xxx-xxx-xxxx', phone)}
        </p>
      )}
      {!!description && (
        <div className="team-bio-description">
          <EditorControl readOnly value={description} />
        </div>
      )}
    </div>
  </StyledCustomerBio>
);

export default CustomerBio;
