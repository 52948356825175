import React from 'react';
import styled from 'styled-components';

const StyledListItem = styled.div`
  position: relative;
  background-color: #fff;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  width: 100%;

  .list-item-component {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    width: 100%;
    z-index: 2;
  }

  &.hoverable:not(:disabled) {
    .list-item-component {
      &:hover,
      &.active {
        cursor: pointer;
        background-color: #f4f4f4;
      }
    }
  }
`;

const ListItem = ({
  children,
  component: Component = 'div',
  componentProps,
  disabled,
  hoverable,
  tag = 'div',
  onClick = () => {},
  ...dataAttributes
}) => (
  <StyledListItem
    as={tag}
    disabled={disabled}
    className={`${hoverable ? 'hoverable' : ''}`}
    onClick={onClick}
    {...dataAttributes}
  >
    <Component className="list-item-component" {...componentProps}>
      {children}
    </Component>
  </StyledListItem>
);

export default ListItem;
