import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import {
  LanguageOutlined as LanguageOutlinedIcon,
  ViewComfyOutlined as ViewCompactOutlinedIcon,
  ViewArrayOutlined as ViewArrayOutlinedIcon,
  RadioButtonUncheckedOutlined as RadioButtonUncheckedOutlinedIcon,
  CheckOutlined as CheckOutlinedIcon,
} from '@mui/icons-material';
import {
  List,
  ListItem,
  ListItemActions,
  ListItemIcon,
  ListItemText,
} from '@clatter/ui';
import { defaultBorderColor } from '@clatter/ui';
import { isBlockComplete, isPageComplete, isSiteComplete } from '../../helpers';

const StyledSiteMakerSidebar = styled.div`
  grid-area: sidebar;
  background: #fff;
  font-size: 12px;
  position: sticky;
  top: ${({ theme }) => `calc(${theme.pageHeader.height} + 16px)`};
  left: 0;
  padding: 16px 0;

  .item-status {
    svg {
      fill: ${defaultBorderColor};
      font-size: 16px;
    }

    .status-completed {
      fill: #76ca8a;
    }
  }

  p {
    text-align: center;
  }
`;

const SiteMakerSidebar = ({ site = {}, pages = [] }) => {
  const renderStatus = (isCompleted) => (
    <div className="item-status">
      {isCompleted ? (
        <CheckOutlinedIcon className="status-completed" />
      ) : (
        <RadioButtonUncheckedOutlinedIcon />
      )}
    </div>
  );

  const renderPageBlocks = (page) => {
    if (!page || !page.blocks || !page.blocks.length) {
      return <p>No page blocks</p>;
    }

    let blocks = page.blocks;

    return blocks.map((block) => (
      <ListItem
        hoverable
        key={block.path}
        component={NavLink}
        componentProps={{ to: block.path }}
        data-test-id="site-maker-sidebar-block-item"
      >
        <ListItemIcon>
          <ViewArrayOutlinedIcon />
        </ListItemIcon>
        <ListItemText text={block.name} />
        <ListItemActions>
          {renderStatus(isBlockComplete(page, block))}
        </ListItemActions>
      </ListItem>
    ));
  };

  // investigating thrown on key={page.id} cannot read 'id' in one
  // of the entries in pages. there is a case where pages = [null]

  const renderPages = () => {
    if (
      !pages ||
      !pages.length ||
      !pages.some((page) => page && 'id' in page)
    ) {
      return <p>No pages</p>;
    }

    return (
      <List nested>
        {pages
          .filter((page) => page && 'id' in page)
          .map((page) => (
            <div key={page.id}>
              <ListItem data-test-id="site-maker-sidebar-page-item">
                <ListItemIcon>
                  <ViewCompactOutlinedIcon />
                </ListItemIcon>
                <ListItemText text={page.title} />
                <ListItemActions>
                  {renderStatus(isPageComplete(page))}
                </ListItemActions>
              </ListItem>
              <List nested>{renderPageBlocks(page)}</List>
            </div>
          ))}
      </List>
    );
  };

  return (
    <StyledSiteMakerSidebar>
      <List>
        <ListItem
          hoverable
          component={NavLink}
          componentProps={{ to: `/${site.id}/sitesettings` }}
          data-test-id="site-maker-sidebar-microsite"
        >
          <ListItemIcon>
            <LanguageOutlinedIcon />
          </ListItemIcon>
          <ListItemText text={site.name} />
          <ListItemActions>
            {renderStatus(isSiteComplete(site, pages))}
          </ListItemActions>
        </ListItem>
        {renderPages()}
      </List>
    </StyledSiteMakerSidebar>
  );
};

export default SiteMakerSidebar;
