import React from 'react';
import {buttonWidths, DataGridPremium, IconButton} from '@clatter/ui';
import {
  GridToolbarContainer,
  useGridApiContext,
} from '@mui/x-data-grid';
import { CloudDownload as CloudDownloadIcon } from '@mui/icons-material';
import { handleExportAsCSV } from '@clatter/platform';

const CustomToolbar = ({ exportFileName }) => {
  const apiRef = useGridApiContext();

  return (
    <GridToolbarContainer
      style={{
        justifyContent: 'end',
      }}
    >
      <IconButton
        tooltip="Export"
        width={buttonWidths.md}
        onClick={() =>
          handleExportAsCSV({
            apiRef: apiRef,
            exportFileName: exportFileName,
          })
        }
      >
        <CloudDownloadIcon />
      </IconButton>
    </GridToolbarContainer>
  );
};

const MicrositeStatisticsViewsBySiteTable = ({ isLoading = false, rows = [], columns = [], exportFileName }) => (
  <DataGridPremium
    showVerticalSidebar
    loading={isLoading}
    dataGridProps={{
      components: {
        Toolbar: () => CustomToolbar({ exportFileName }),
      },
      getRowClassName: ({ row }) => (row.isOdd ? 'even' : 'odd'),
    }}
    name="report/msm-microsite-statistics-pageviews-page"
    columns={columns}
    rows={rows}
    getRowId={(row) => row.uuid}
    disableColumnFilter
  />
);

export default MicrositeStatisticsViewsBySiteTable;
