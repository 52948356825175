import { generatePath } from 'react-router-dom';
import {
  VerticalSplit as VerticalSplitIcon,
  Summarize as SummarizeIcon,
  SupervisorAccount as SupervisorAccountIcon,
  Help as HelpIcon,
  Analytics as AnalyticsIcon,
} from '@mui/icons-material';
import { featureFlagsMap, generateReportsUrls, hasActiveFeatureFlag, hasRole } from '@clatter/platform';
import { userRolesMap } from '../constants';
import routes from '../routes/routes';

export const getMenuItems = ({ user, featureFlags, activeTool, reports, analyticsEnabled = false }) => {
  const menuItems = [];

  if (!user) {
    return menuItems;
  }

  menuItems.push({
    label: 'Project Library',
    url: generatePath('/microsites'),
    icon: VerticalSplitIcon,
    exact: true,
  });

  if (hasRole(userRolesMap.admin, user) || hasRole(userRolesMap.contentAdmin, user)) {
    menuItems.push({
      label: 'Admin',
      url: generatePath('/admin'),
      icon: SupervisorAccountIcon,
    });
  }

  if (
    hasRole(userRolesMap.admin, user)
    && analyticsEnabled
    && featureFlags
    && Array.isArray(featureFlags)
    && hasActiveFeatureFlag({
      flagName: featureFlagsMap.MSM.analytics,
      featureFlags: featureFlags,
    })
  ) {
    menuItems.push({
      label: 'Analytics',
      url: generatePath(routes.analytics),
      icon: AnalyticsIcon,
      exact: true,
    });
  }

  const isAdmin = hasRole(userRolesMap.admin, user);
  const isContentAdmin = hasRole(userRolesMap.contentAdmin, user);
  const contentAdminAllowedReports = [featureFlagsMap.MSM.micrositeStatistics]
  const filteredFeatureFlags = !isAdmin && isContentAdmin
    ? featureFlags
        .map((item) => contentAdminAllowedReports.includes(item.name) ? item : null)
        .filter((item) => item)
    : featureFlags;

  if (isAdmin || isContentAdmin) {
    if (
      featureFlags &&
      Array.isArray(featureFlags) &&
      hasActiveFeatureFlag({
        flagName: featureFlagsMap.MSM.reports,
        featureFlags: featureFlags,
      })
    ) {
      menuItems.push({
        label: 'Reports',
        children: generateReportsUrls({
          reports: reports,
          featureFlags: filteredFeatureFlags,
        }),
        icon: SummarizeIcon,
      });
    }
  }

  if (
    activeTool
    && activeTool.help_url
    && hasActiveFeatureFlag({
      flagName: featureFlagsMap.MSM.help,
      featureFlags: featureFlags,
    })
  ) {
    menuItems.push({
      label: 'Support',
      url: activeTool.help_url,
      icon: HelpIcon,
      external: true,
    });
  }

  return menuItems;
};
