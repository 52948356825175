import React, { useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import dynamic from 'next/dynamic';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const Editor = dynamic(() => import('react-draft-wysiwyg').then((mod) => mod.Editor), { ssr: false });

const StyledEditorControl = styled.div`
  .editor-control-toolbar {
    border: none;
    padding: 8px 0;
    margin-bottom: 0;

    > * {
      margin: 0;
    }
  }

  .editor-control-body {
    div {
      margin: 0;
    }
  }

  &:not(.view-only) {
    .rdw-editor-main {
      padding: 16px;
      border: 1px solid #ddd;
      border-radius: 4px;
    }
  }

  &.no-scrollbars {
    .public-DraftEditor-content {
      overflow: hidden;
    }
  }

  .rdw-link-decorator-wrapper {
    position: relative;

    font-family: ${({ theme }) => theme?.rdwRender?.fontFamily};
    font-size: ${({ theme }) => theme?.rdwRender?.fontSize};
    font-weight: ${({ theme }) => theme?.rdwRender?.fontWeight};
    line-height: 18px;

    a {
      color: ${({ theme, linkColor }) =>  linkColor || theme?.rdwRender?.color} !important;
    }

    &:hover {
      text-decoration: underline;
      color: ${({ theme, linkColor }) =>  linkColor || theme?.rdwRender?.color} !important;
    }
  }

  .rdw-link-decorator-icon {
    display: none;
  }
`;

const EditorControl = ({
  onChange,
  readOnly = false,
  noScrollbars = false,
  value = '',
  linkColor = null, // if null it will default to the theme one
}) => {
  const [editorState, setEditorState] = useState('');

  useState(() => {
    if (typeof window === 'object') {
      const htmlToDraft = require('html-to-draftjs').default;

      const blocksFromHTML = htmlToDraft(value);
      const { contentBlocks, entityMap } = blocksFromHTML;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap,
      );

      setEditorState(EditorState.createWithContent(contentState));
    }
  }, []);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    const stringValue = draftToHtml(
      convertToRaw(editorState.getCurrentContent()),
    );
    onChange(stringValue);
  };

  return (
    <StyledEditorControl
      linkColor={linkColor}
      className={classNames({
        'view-only': readOnly,
        'no-scrollbars': noScrollbars,
      })}
    >
      <Editor
        editorClassName="editor-control-body"
        toolbarClassName="editor-control-toolbar"
        toolbarHidden={readOnly}
        readOnly={readOnly}
        toolbar={{
          options: ['inline', 'link'],
          inline: {
            inDropdown: false,
            options: ['bold', 'italic', 'underline'],
          },
          link: {
            inDropdown: false,
            showOpenOptionOnHover: true,
            defaultTargetOption: '_self',
            options: ['link', 'unlink'],
          },
        }}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        stripPastedStyles
      />
    </StyledEditorControl>
  );
};

export default EditorControl;
