import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDocumentTitle } from '@clatter/ui';
import {
  useAuth,
  fetchSystemProfile,
  platformSelectors,
  requestStatus,
  systemSettingsApi,
  useDeepCompareMemo,
  useEffectOnce,
  usePageTitleHeader,
} from '@clatter/platform';
import { fetchMicrosites, fetchPageTemplates } from '../../../store';
import {
  selectAllMicrosites,
  selectMicrositesLoadingStatus,
} from '../../../store/microsites.slice';
import LandingPageForm from './LandingPageForm';
import routes, { documentTitleMap } from '../../../routes/routes';

const pageBaseUrl = `${process.env.NX_SITES_HOST}/sites`;

const LandingPage = () => {
  useDocumentTitle(documentTitleMap.adminLandingPage);
  const { user } = useAuth();
  const dispatch = useDispatch();

  const { renderPageTitleHeader } = usePageTitleHeader({
    pageTitle: "Landing Page",
    linksType: 'msm-admin',
    routes: routes,
  });

  const { landingPageSiteName, loadingStatus: systemProfileLoadingStatus } = useSelector(
    platformSelectors.systemProfile.selectSystemProfile,
  );
  const microsites = useSelector(selectAllMicrosites);


  const isSystemProfileLoaded = systemProfileLoadingStatus === requestStatus.fulfilled;
  const micrositesLoadingStatus = useSelector(selectMicrositesLoadingStatus);
  const isLoading = micrositesLoadingStatus === requestStatus.pending;

  const micrositesOptions = useDeepCompareMemo(
    () =>
      microsites.reduce((acc, microsite) => {
        if (!microsite.id) {
          console.error('Invalid microsite data:: missing id');
          return;
        }

        (microsite.pages || []).forEach((page) => {
          if (!page?.id) {
            console.error('Invalid page data:: missing id');
            return;
          }

          if (
            !microsite?.password_hash &&
            microsite?.published &&
            page?.page_template?.template?.toLowerCase().includes('landing page')
          ) {
            acc.push({
              siteId: microsite.id,
              siteName: microsite?.name || 'Unknown',
              pageName: page?.title || 'Unknown',
              pageId: page.id,
            });
          }
        });

        return acc;
      }, []),
    [microsites],
  );

  const defaultLandingPage = micrositesOptions.find(
    ({ siteName }) => siteName === landingPageSiteName,
  );

  const onSubmit = async ({ landing_page }) => {
    const landingPageUrl = landing_page
      ? `${pageBaseUrl}/${landing_page.siteName}/${landing_page?.pageName}`
      : null;

    try {
      await systemSettingsApi.updateSystemProfile({
        landing_page_url: landingPageUrl,
      });
      await dispatch(fetchSystemProfile());

      const alertMessage = landingPageUrl
        ? 'We are setting up your landing page. It may take up to 15 mins.'
        : 'We are resetting your landing page to default. It may take up to 15 mins.';
      alert(alertMessage);
    } catch (error) {
      alert(`We were unable to set landing page. Reason: ${error.message}`);
    }
  };

  useEffectOnce(() => {
    dispatch(fetchPageTemplates());
    dispatch(fetchMicrosites({ user: user }));
  }, []);

  return (
    <>
      { renderPageTitleHeader() }

      {isSystemProfileLoaded && (
        <LandingPageForm
          options={micrositesOptions}
          defaultLandingPage={defaultLandingPage}
          onSubmit={onSubmit}
          disabled={isLoading}
        />
      )}
    </>
  );
};

export default LandingPage;
