export const templatesUsageMap = (microsites) => {
  const templatesMap = {};

  for (const microsite of microsites || []) {
    if (microsite.pages) {
      for (const page of microsite.pages) {
        templatesMap[page.page_template]
          ? (templatesMap[page.page_template] = templatesMap[
              page.page_template
            ].add(microsite.id))
          : (templatesMap[page.page_template] = new Set([microsite.id]));
      }
    }
  }

  return templatesMap;
};
