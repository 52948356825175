import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosApiV2BaseQuery } from '../services/baseQueries';
import { RTK_CACHE_TIMEOUT } from '../constants';

export const apiSlice = createApi({
  reducerPath: 'msmApi',
  keepUnusedDataFor: RTK_CACHE_TIMEOUT,
  baseQuery: axiosApiV2BaseQuery(),
  endpoints: (builder) => ({
    getPageViewsByDate: builder.query({
      query: ({ startDate, endDate }) => `/pageviews?startDate=${startDate}&endDate=${endDate}`,
      transformResponse: (response) => response.data,
    })
  }),
});

export const { useGetPageViewsByDateQuery } = apiSlice
