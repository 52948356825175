import React from 'react';
import styled from 'styled-components';

const StyledListItemText = styled.div`
  font-size: 14px;
  line-height: 20px;
  padding: 10px 0;
  color: #262626;
  font-weight: 400;
  text-decoration: none;
  text-align: left;
  flex: 1;
`;

const ListItemText = ({ text = '', icon = null }) => (
  <StyledListItemText>{text}{icon && icon}</StyledListItemText>
);

export default ListItemText;
