import React, { useState, useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { Loader } from '@clatter/ui';
import { isAuthCookieAvailable, useAuth, isLocalhost } from '@clatter/platform';

const withAuthenticationRequired = (Component) => (props) => {
  const { isAuthenticated, isLoading } = useAuth();
  const history = useHistory();

  const pathname = history.location.pathname;
  const search = history.location.search;

  useEffect(() => {
    if (!isAuthCookieAvailable() || (!isAuthenticated && !isLoading)) {
      history.push('/logout', { redirectTo: window.location.origin + pathname + search });
    }
  }, []);

  return isAuthenticated ? <Component {...props} /> : <Loader />;
};

export const withRoleBasedRedirect = (Component, options) => (props) => {
  const history = useHistory();
  const { user } = useAuth();
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    function hasAnyRole(rolesToCheck) {
      const roles = user?.[`${process.env.NX_AUTH0_NAMESPACE}/roles`] || [];
      const isAuthorized = rolesToCheck.some((role) => roles.includes(role));

      if (isAuthorized) {
        return setIsAuthorized(true);
      }

      console.info(
        `Access denied. User roles: [${roles.join(
          ', ',
        )}]. Access roles: [${rolesToCheck.join(', ')}]`,
      );

      return window.location.replace(
        isLocalhost()
          ? 'http://localhost:3000'
          : process.env.NX_APP_ROOT_DOMAIN,
      );
    }
    hasAnyRole(
      Array.isArray(options.accessRoles)
        ? options.accessRoles
        : [options.accessRoles],
    );
  }, [history, user?.id]);

  return isAuthorized ? <Component {...props} /> : <Loader />;
};

const ProtectedRoute = ({
  component,
  render,
  children,
  accessRoles,
  ...rest
}) => {
  if (accessRoles) {
    const renderComponent = component
      ? component
      : () => (
          <Route render={render} {...rest}>
            {children}
          </Route>
        );
    return (
      <Route
        component={withAuthenticationRequired(
          withRoleBasedRedirect(renderComponent, { accessRoles }),
        )}
        {...rest}
      />
    );
  }

  if (component) {
    // this case is the 'default' in the auth0 documentation, but we don't use it
    // so it's not tested...
    return (
      <Route component={withAuthenticationRequired(component)} {...rest} />
    );
  }

  if (render) {
    const _component = () => (
      <Route render={render} {...rest}>
        {children}
      </Route>
    );

    return (
      <Route component={withAuthenticationRequired(_component)} {...rest} />
    );
  }
  // if we're here, then neither of component-specified or render prop is used
  const _component = () => <Route {...rest}>{children}</Route>;
  return <Route component={withAuthenticationRequired(_component)} {...rest} />;
};

export default ProtectedRoute;
