import React from 'react';
import styled from 'styled-components';

import Nav from './Nav';

const Wrapper = styled.div`
  width: 82.5%;
  margin: 0 auto;
`;

const LogoSection = styled.div`
  display: flex;
  align-items: center;
`;

const OptumRxLogo = styled.h1`
  width: 180px;
  position: relative;
  transform: translateX(-10px);

  &::after {
    position: absolute;
    content: '';
    height: 33px;
    background-color: #242424;
    width: 1px;
    top: 38%;
  }
`;
const HeadingSubDescription = styled.span`
  padding-left: 20px;
  margin-top: 15px;
  font-weight: normal;
  text-rendering: optimizelegibility;
  font-size: 15px;
  letter-spacing: -0.1px;
  color: black;
`;

const StyledImage = styled.img`
  width: 100%;
  border: 0;
  vertical-align: middle;
`;
const StyledHeader = styled.header`
  width: 100%;
  padding-top: 12px;
  position: sticky;
  top: 0;
  min-height: 100px;
  background: #fff;
  border-bottom: 1px solid;
  z-index: 9;
  padding: 0 16px;

  a {
    display: inline-block;
    text-decoration: none;
  }
`;

const StyledCustomerName = styled.span`
  font-weight: 600;
`;

const Header = ({ customerName, pageIds, nav = false }) => {
  return (
    <StyledHeader>
      <Wrapper>
        <LogoSection>
          <OptumRxLogo>
            <a href={'/'} title="OptumRx">
              <StyledImage
                src="https://i2.wp.com/equalisgroup.org/wp-content/uploads/2020/03/Logo-OptumRx-PNG-e1590785075499.png?fit=400%2C145&amp;ssl=1"
                alt="OptumRx"
              ></StyledImage>
            </a>
          </OptumRxLogo>
          <HeadingSubDescription>
            A better path to affordable benefits for{' '}
            <StyledCustomerName>{customerName}</StyledCustomerName>
          </HeadingSubDescription>
        </LogoSection>
        {nav && (
          <Nav
            urlPartnerSite={`${pageIds[0]}/preview`}
            urlAdditionalResources={`${pageIds[1]}/preview`}
          />
        )}
      </Wrapper>
    </StyledHeader>
  );
};

export default Header;
