import React from 'react';

// one small complication stems from needing
// this gatsby-provided graphql in order to be
// able to include the gatsby graphql query
// import { graphql } from "gatsby";

import {
  HeroBannerImage,
  FeaturedResources,
  Header,
  Footer,
} from '../../blocks';
import Page from '../../blocks/Page';
import Section from '../../blocks/Section';

// these components take a graphql query result
export const Resources = ({ data }) => {
  const page = data.pages.edges[0].node;
  const resources = page.resources.map((resource) => ({
    ...resource,
    href: resource.link,
    target: '#',
    download: '#',
  }));

  const microsites = data.microsites.edges.map((e) => e.node);
  const microsite = microsites.find(
    (ms) => ms.pages.find((p) => p.id === page.id) !== undefined,
  );
  const customerName = microsite.client_name;
  const pageIds = microsite.pages.map((p) => p.id);
  const srcCustomerLogo = microsite.cobrand_logo.url;
  const { headline, introCopy } = JSON.parse(
    page.variables,
  );
  const bannerVideo = page?.banner_video;
  const heroImageUrl = page?.banner_image?.asset?.url;

  return (
    <Page>
      <Header customerName={customerName} pageIds={pageIds} nav={true} />
      <HeroBannerImage
        bannerVideo={bannerVideo}
        headline={headline}
        heroImageUrl={heroImageUrl}
        introCopy={introCopy}
      />
      <Section>
        <FeaturedResources
          disableExpander={page.templateName === 'Additional Resources'}
          resources={resources}
          header="Additional Resources"
        />
      </Section>
      <Footer logo={srcCustomerLogo} />
    </Page>
  );
};

export default Resources;
