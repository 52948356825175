import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
export const MICROSITESSETTINGS_FEATURE_KEY = 'micrositesSettings';
export const micrositesSettingsAdapter = createEntityAdapter();

export const headerOptions = [
  {
    default: true,
    label: 'Text',
    value: 'text',
  },
  {
    label: 'Brand logo',
    value: 'logo',
  },
];

const defaultConfig = {
  headerExtraItem: headerOptions.find((option) => option.default).value,
  headerText: 'A better path to affordable benefits for {companyName}',
  showBrandLogoInBrandFooter: false,
};

export const fetchMicrositesSettings = createAsyncThunk(
  `${MICROSITESSETTINGS_FEATURE_KEY}/fetch`,
  async () => {
    const userConfig = JSON.parse(
      localStorage.getItem('micrositesSettings'),
    ) || { ...defaultConfig };
    return Promise.resolve(userConfig);
  },
);

export const updateMicrositesSettings = createAsyncThunk(
  `${MICROSITESSETTINGS_FEATURE_KEY}/update`,
  async ({ config }) => {
    const userConfig = {
      ...config,
    };

    localStorage.setItem('micrositesSettings', JSON.stringify(userConfig));

    return Promise.resolve(userConfig);
  },
);

export const initialMicrositesSettingsState = micrositesSettingsAdapter.getInitialState(
  {
    loadingStatus: 'loading',
    error: null,
    config: null,
  },
);

export const micrositesSettingsSlice = createSlice({
  name: MICROSITESSETTINGS_FEATURE_KEY,
  initialState: initialMicrositesSettingsState,
  reducers: {
    add: micrositesSettingsAdapter.addOne,
    remove: micrositesSettingsAdapter.removeOne,
    // ...
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMicrositesSettings.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(fetchMicrositesSettings.fulfilled, (state, action) => {
        state.config = action.payload;
        state.loadingStatus = 'loaded';
      })
      .addCase(fetchMicrositesSettings.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(updateMicrositesSettings.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(updateMicrositesSettings.fulfilled, (state, action) => {
        state.config = action.payload;
        state.loadingStatus = 'loaded';
      })
      .addCase(updateMicrositesSettings.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      });
  },
});

export const micrositesSettingsReducer = micrositesSettingsSlice.reducer;

export const micrositesSettingsActions = micrositesSettingsSlice.actions;

const { selectAll, selectEntities } = micrositesSettingsAdapter.getSelectors();

export const getMicrositesSettingsState = (rootState) =>
  rootState[MICROSITESSETTINGS_FEATURE_KEY];

export const selectAllMicrositesSettings = createSelector(
  getMicrositesSettingsState,
  selectAll,
);

export const selectMicrositesSettingsEntities = createSelector(
  getMicrositesSettingsState,
  selectEntities,
);
