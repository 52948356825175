import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  isRejectedWithValue,
} from '@reduxjs/toolkit';
import { requestStatus } from '@clatter/platform';
import baseStrapi from '../api/base.strapi';
import pageTemplateIconsApi from '../api/pageTemplateIcons.api';

export const PAGE_TEMPLATE_ICONS_FEATURE_KEY = 'pageTemplateIcons';
export const pageTemplateIconsAdapter = createEntityAdapter({
  sortComparer: (a, b) => (a.updated_at > b.updated_at ? -1 : 1),
});

export const fetchPageTemplateIcons = createAsyncThunk(
  `${PAGE_TEMPLATE_ICONS_FEATURE_KEY}/fetchAll`,
  async () => {
    try {
      return pageTemplateIconsApi.getAllPageTemplateIcons();
    } catch (error) {
      return isRejectedWithValue({
        ...error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const addPageTemplateIcon = createAsyncThunk(
  `${PAGE_TEMPLATE_ICONS_FEATURE_KEY}/add`,
  async ({ file }) => {
    try {
      const newUpload = new FormData();
      newUpload.append('files', file);

      const uploadResponse = await baseStrapi.upload(newUpload);

      const response = await pageTemplateIconsApi.addPageTemplateIcon({
        asset: uploadResponse[0].id,
      });

      return mapApiToStore(response.data);
    } catch (error) {
      return isRejectedWithValue({
        ...error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const initialPageTemplateIconsState =
  pageTemplateIconsAdapter.getInitialState({
    loadingStatus: requestStatus.initial,
    error: null,
  });

const mapApiToStore = (item) => ({
  id: item.id,
  ...item.attributes,
  asset: item.attributes?.asset?.data !== null ? {
    id: item.attributes?.asset?.data?.id,
    ...item.attributes?.asset?.data?.attributes,
  } : null,
});

export const pageTemplateIconsSlice = createSlice({
  name: PAGE_TEMPLATE_ICONS_FEATURE_KEY,
  initialState: initialPageTemplateIconsState,
  reducers: {
    add: pageTemplateIconsAdapter.addOne,
    remove: pageTemplateIconsAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPageTemplateIcons.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(fetchPageTemplateIcons.fulfilled, (state, { payload }) => {
        pageTemplateIconsAdapter.setAll(state, (payload.data || []).map(mapApiToStore));
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(fetchPageTemplateIcons.rejected, (state, action) => {
        state.loadingStatus = requestStatus.error;
        state.error = action.error.message;
      })
      .addCase(addPageTemplateIcon.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(addPageTemplateIcon.fulfilled, (state, action) => {
        pageTemplateIconsAdapter.addOne(state, action.payload);
        state.loadingStatus = requestStatus.fulfilled;
      });
  },
});

export const pageTemplateIconsReducer = pageTemplateIconsSlice.reducer;

export const pageTemplateIconsActions = pageTemplateIconsSlice.actions;
const { selectAll, selectEntities } = pageTemplateIconsAdapter.getSelectors();
export const getPageTemplateIconsState = (rootState) =>
  rootState[PAGE_TEMPLATE_ICONS_FEATURE_KEY];
export const selectAllPageTemplateIcons = createSelector(
  getPageTemplateIconsState,
  selectAll,
);
export const selectPageTemplateIconsEntities = createSelector(
  getPageTemplateIconsState,
  selectEntities,
);
