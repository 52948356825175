import { getAxiosApiV2WithAuthorization, getAxiosCmsWithAuthorization } from '@clatter/platform';

const baseUrl = '/microsites';

const micrositesApi = {
  getMicrositeById: ({ micrositeId }) =>
    getAxiosCmsWithAuthorization({
      method: 'get',
      url: `${baseUrl}/${micrositeId}?populate=pages`,
    }),
  postMicrosite: ({ data }) =>
    getAxiosApiV2WithAuthorization({
      url: baseUrl,
      method: 'post',
      data: data,
    }),
  deleteMicrosite: ({ micrositeId }) =>
    getAxiosApiV2WithAuthorization({
      url: `${baseUrl}/${micrositeId}`,
      method: 'delete',
    }),
};

export default micrositesApi;
