import React from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Button, FormButtons, FormControl, TextControl } from '@clatter/ui';
import { useAuth } from "@clatter/platform";
import {
  createVideoCategory,
  updateVideoCategory,
} from '../../../store/video-categories.slice';

const StyledCreateVideoCategoryForm = styled.form`
  width: 480px;
`;

const CreateVideoCategoryForm = ({ editableItem, isEdit, onSuccess }) => {
  const dispatch = useDispatch();
  const { activeUser } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
    formState,
  } = useForm({
    mode: 'onChange',
    defaultValues: editableItem,
  });

  const onSubmit = async (formData) => {
    dispatch(
      isEdit
        ? updateVideoCategory({ id: editableItem.id, formData: { ...formData, c_updated_by: activeUser.email } })
        : createVideoCategory({ ...formData, c_created_by: activeUser.email }),
    ).then(() => {
      onSuccess();
    });
  };

  return (
    <StyledCreateVideoCategoryForm onSubmit={handleSubmit(onSubmit)}>
      <FormControl label="Category name" error={errors.categoryName}>
        <TextControl
          {...register('categoryName', {
            required: { value: true, message: 'Field is required' },
          })}
          placeholder="Enter category name"
        />
      </FormControl>
      <FormButtons>
        <Button
          disabled={!formState.isValid || !formState.isDirty}
          type="submit"
        >
          {isEdit ? 'Save' : 'Create'}
        </Button>
      </FormButtons>
    </StyledCreateVideoCategoryForm>
  );
};

export default CreateVideoCategoryForm;
