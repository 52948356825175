import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Stack } from '@mui/material';
import { Button, DataTable, Dialog, IconButton, Loader, useDocumentTitle } from '@clatter/ui';
import { ExportImport, formatDateTime, useNotices, usePageTitleHeader } from '@clatter/platform';
import { BioDetailsDialog } from '@clatter/templates';
import { bioFromStore } from '../../../pages/fromStore';
import CreateBioForm from './CreateBioForm';
import EditBioForm from './EditBioForm';
import { deleteBio, fetchBios } from '../../../store/bios.slice';
import { format } from 'date-fns';
import routes, { documentTitleMap } from "../../../routes/routes";

const bioColumns = [
  {
    label: 'Name',
    name: 'name',
    align: 'left',
    sortable: true,
  },
  {
    label: 'Group',
    name: 'groupName',
    align: 'left',
    sortable: true,
  },
  {
    label: 'Created by',
    name: 'c_created_by',
    align: 'left',
    sortable: true,
  },
  {
    label: 'Date added',
    name: 'createdAt',
    customRender: (row) => formatDateTime(row.createdAt),
    align: 'left',
    sortable: true,
  },
  {
    label: 'Updated by',
    name: 'c_updated_by',
    align: 'left',
    sortable: true,
  },
  {
    label: 'Date updated',
    name: 'updatedAt',
    customRender: (row) => formatDateTime(row?.updatedAt) || '',
    align: 'left',
    sortable: true,
  },
];

const Bios = () => {
  useDocumentTitle(documentTitleMap.adminBios)
  const [previewBio, setPreviewBio] = useState(null);
  const [showCreateBioDialog, setShowCreateBioDialog] = useState(false);
  const [editableItem, setEditableItem] = useState(null);
  const { addNotice } = useNotices();
  const dispatch = useDispatch();

  const { renderPageTitleHeader } = usePageTitleHeader({
    pageTitle: "Bios",
    linksType: 'msm-admin',
    routes: routes,
  });

  const handleImportSuccess = (response) => {
    dispatch(fetchBios());

    addNotice({
      message: response?.message || 'Successfully imported bio(s).',
      type: 'success',
      title:'Success',
    });
  };

  useEffect(() => {
    dispatch(fetchBios());
  }, [dispatch]);

  const bios = useSelector((state) =>
    Object.values(state.bios.entities).map(bioFromStore),
  );

  const loading = useSelector(
    (state) => state.bios.loadingStatus === 'loading',
  );

  const toggleShowCreateBioDialog = () => {
    setShowCreateBioDialog(!showCreateBioDialog);
  };

  const handleCreateOnSuccess = () => {
    setShowCreateBioDialog(!showCreateBioDialog);
    dispatch(fetchBios());
  };

  const handleShowPreview = (event) => {
    const nextPreviewBio = bios.find(
      (bio) => bio.id === parseInt(event.currentTarget.dataset.id),
    );

    setPreviewBio(nextPreviewBio);
  };

  const handleHidePreview = () => {
    setPreviewBio(null);
  };

  const handleEditClick = (event) => {
    const item = bios.find((bio) => bio.id === parseInt(event.currentTarget.dataset.id));

    if (!item) {
      return;
    }

    setEditableItem(item);
  };

  const handleDismissEdit = () => {
    setEditableItem(null);
  };

  const handleEditOnSuccess = () => {
    setEditableItem(null);
    dispatch(fetchBios());
  };

  const handleDeleteClick = (event) => {
    if (window.confirm('Are you sure you want to remove this bio?')) {
      dispatch(deleteBio(parseInt(event.currentTarget.dataset.id)));
    }
  };

  return (
    <>
      {loading && <Loader />}

      { renderPageTitleHeader({
        headerActions:
          <Stack spacing={1} direction="row" alignItems="center">
            <ExportImport
              exportUrl="/bios"
              exportFileName={(() =>
                `bios_${process.env.NX_CLIENT}_${format(
                  new Date(),
                  'yyyy-MM-dd_HH-mm-ss',
                )}`)()}
              exportDisabled={!bios || bios?.length === 0}
              onImportSuccessCallback={handleImportSuccess}
            />
            <Button onClick={toggleShowCreateBioDialog}>Add bio</Button>
          </Stack>
        })
      }

      <div>
        <DataTable
          autofit
          columns={bioColumns}
          defaultSortField="name"
          filterColumns={['name', 'groupName']}
          name="adminBios"
          rows={bios}
          renderActionColumn={(row) => (
            <>
              <IconButton data-id={row.id} onClick={handleShowPreview}>
                <VisibilityOutlinedIcon />
              </IconButton>
              <IconButton data-id={row.id} onClick={handleEditClick}>
                <EditOutlinedIcon />
              </IconButton>
              <IconButton data-id={row.id} onClick={handleDeleteClick}>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </>
          )}
        />
      </div>
      <BioDetailsDialog
        onCloseDialog={handleHidePreview}
        details={previewBio}
        open={!!previewBio}
      />
      <Dialog
        title="Add bio"
        onCloseDialog={toggleShowCreateBioDialog}
        open={showCreateBioDialog}
      >
        <CreateBioForm onSuccess={handleCreateOnSuccess} />
      </Dialog>
      <Dialog
        title="Edit bio"
        onCloseDialog={handleDismissEdit}
        open={!!editableItem}
      >
        <EditBioForm
          editableItem={editableItem}
          onSuccess={handleEditOnSuccess}
        />
      </Dialog>
    </>
  );
};

export default Bios;
