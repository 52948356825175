import { AxiosRequestConfig, AxiosInstance } from 'axios';
import { errorHandlingInterceptor, getAxiosClientWithRefreshToken } from '@clatter/platform';

/**
 * Creates a new instance of axios with a custom config
 *
 * @type {AxiosInstance} client
 */
const client = getAxiosClientWithRefreshToken({
  baseURL: `${process.env.NX_CMS_HOST}/api`,
  authHeaderName: 'Clatter-Authorization',
});

client.interceptors.response.use(
  (response) => response,
  errorHandlingInterceptor,
);

/**
 * Axios instance that uses auth0 access token
 *
 * @param {AxiosRequestConfig} requestConfigOptions
 * @returns {Promise}
 */
const getAxiosWithStrapi = (requestConfigOptions) => {
  const onSuccess = (response) => {
    return Promise.resolve(response.data);
  };

  return client(requestConfigOptions).then(onSuccess);
};

export default getAxiosWithStrapi;
