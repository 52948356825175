import React from 'react';
import styled from 'styled-components';

const StyledListItemActions = styled.div`
  display: flex;
  padding-left: 12px;
`;

const ListItemActions = ({ children }) => (
  <StyledListItemActions>{children}</StyledListItemActions>
);

export default ListItemActions;
