import { useEffect, useState } from 'react';

export const useIsComponentMounted = () => {
  const [isComponentMounted, setIsComponentMounted] = useState(false);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      setIsComponentMounted(true);
    }

    return () => {
      mounted = false;
    };
  }, []); // Empty dependency array ensures it runs once on mount

  return isComponentMounted;
};
