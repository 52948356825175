import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { getAxiosCmsWithAuthorization } from '@clatter/platform';

export const VIDEO_CATEGORIES_FEATURE_KEY = 'videoCategories';
export const videoCategoriesAdapter = createEntityAdapter();
const baseUrl = '/video-categories';

const mapApiToStore = (item) => ({
  id: item.id,
  ...item.attributes,
});

export const fetchVideoCategories = createAsyncThunk(
  `${VIDEO_CATEGORIES_FEATURE_KEY}/fetch`,
  async () => {
    const response = await getAxiosCmsWithAuthorization({
      method: 'get',
      url: `${baseUrl}?pagination[limit]=-1&sort=categoryName:asc`,
    });

    return Promise.resolve((response.data.data || []).map(mapApiToStore));
  },
);

export const createVideoCategory = createAsyncThunk(
  `${VIDEO_CATEGORIES_FEATURE_KEY}/create`,
  async (formData) => {
    const response = await getAxiosCmsWithAuthorization({
      method: 'post',
      url: baseUrl,
      data: { data: formData },
    });

    return Promise.resolve(mapApiToStore(response.data.data));
  },
);

export const updateVideoCategory = createAsyncThunk(
  `${VIDEO_CATEGORIES_FEATURE_KEY}/update`,
  async ({ id, formData }) => {
    const response = await getAxiosCmsWithAuthorization({
      method: 'put',
      url: `${baseUrl}/${id}`,
      data: { data: formData },
    });

    return Promise.resolve(mapApiToStore(response.data.data));
  },
);

export const deleteVideoCategory = createAsyncThunk(
  `${VIDEO_CATEGORIES_FEATURE_KEY}/delete`,
  async (id) => {
    const response = await getAxiosCmsWithAuthorization({
      method: 'delete',
      url: `${baseUrl}/${id}`,
    });

    return Promise.resolve(mapApiToStore(response.data.data));
  },
);

export const initialVideoCategoriesState = videoCategoriesAdapter.getInitialState(
  {
    loadingStatus: 'loading',
    error: null,
  },
);

export const videoCategoriesSlice = createSlice({
  name: VIDEO_CATEGORIES_FEATURE_KEY,
  initialState: initialVideoCategoriesState,
  reducers: {
    add: videoCategoriesAdapter.addOne,
    remove: videoCategoriesAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVideoCategories.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(fetchVideoCategories.fulfilled, (state, action) => {
        videoCategoriesAdapter.setAll(state, action.payload);
        state.loadingStatus = 'loaded';
      })
      .addCase(fetchVideoCategories.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(createVideoCategory.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(createVideoCategory.fulfilled, (state, action) => {
        videoCategoriesAdapter.addOne(state, action.payload);
        state.loadingStatus = 'loaded';
      })
      .addCase(createVideoCategory.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(updateVideoCategory.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(updateVideoCategory.fulfilled, (state, action) => {
        videoCategoriesAdapter.upsertOne(state, action.payload);
        state.loadingStatus = 'loaded';
      })
      .addCase(updateVideoCategory.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(deleteVideoCategory.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(deleteVideoCategory.fulfilled, (state, action) => {
        videoCategoriesAdapter.removeOne(state, action.payload.id);
        state.loadingStatus = 'loaded';
      })
      .addCase(deleteVideoCategory.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      });
  },
});

export const videoCategoriesReducer = videoCategoriesSlice.reducer;
export const videoCategoriesActions = videoCategoriesSlice.actions;

const { selectAll, selectEntities } = videoCategoriesAdapter.getSelectors();

export const getVideoCategoriesState = (rootState) =>
  rootState[VIDEO_CATEGORIES_FEATURE_KEY];

export const selectAllVideoCategories = createSelector(
  getVideoCategoriesState,
  selectAll,
);

export const selectVideoCategoriesEntities = createSelector(
  getVideoCategoriesState,
  selectEntities,
);
