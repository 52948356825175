import getAxiosWithStrapi from './strapiFactory';

const templatesApi = {
  fetchTemplates: () =>
    getAxiosWithStrapi({
      url: '/page-templates?pagination[limit]=-1&populate=icon_uri.asset',
      method: 'get',
    }),
  getTemplateById: (templateId) =>
    getAxiosWithStrapi({
      url: `/page-templates/${templateId}?populate=icon_uri.asset`,
      method: 'get',
    }),
  updateTemplate: ({ updatedTemplate, templateId }) =>
    getAxiosWithStrapi({
      url: `/page-templates/${templateId}?populate=icon_uri.asset`,
      method: 'put',
      data: {
        data: updatedTemplate,
      },
    }),
  deleteTemplate: (templateId) =>
    getAxiosWithStrapi({
      url: `/page-templates/${templateId}?populate=icon_uri.asset`,
      method: 'delete',
    }),
};

export default templatesApi;
