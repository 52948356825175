import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { generatePath, useHistory } from 'react-router-dom';
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi/dist/joi";
import {
  Button,
  Checkbox,
  EditorControl,
  FormControl,
  StepCard,
  StepCardContent,
  StepCardHeader,
  StepCardTitle,
  StyledCheckboxContainer,
} from '@clatter/ui';
import { updatePage } from '../../store';
import SiteMakerActions from '../SiteMaker/SiteMakerActions';
import PreviewButton from '../SiteMaker/PreviewButton';
import { getNextPage, isSiteComplete } from '../../helpers';
import BioPicker from '../BioPicker/BioPicker';
import routes from '../../routes/routes';
import { msmPageTemplatesMap, useDeepCompareEffect, useDeepCompareMemo, useIsComponentMounted } from '@clatter/platform';
import { Stack } from '@mui/material';


const getSchema  = (min) => Joi.object({
  footerBio: Joi.array().min(min)
}).messages({
  'array.min': '', // no need to display message
}).unknown();

const FooterSettingsForm = ({
  currentMicrosite,
  currentPage,
  bios,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isComponentMounted = useIsComponentMounted();

  const joiSchema =
    currentPage?.templateName === msmPageTemplatesMap.UHC_ADVOCACY_TEMPLATE ? getSchema(0) : getSchema(1);

  const {
    handleSubmit,
    formState: { errors, isDirty, isValid },
    control,
    watch,
    getValues,
    register,
    reset,
  } = useForm({
    mode: 'onChange',
    resolver: joiResolver(joiSchema),
  });

  const pageVariables = useDeepCompareMemo(() => {
    if (currentPage) {
      return JSON.parse(currentPage.variables);
    }

    return {
      headline: '[default headline]',
    };
  }, [currentPage]);

  useDeepCompareEffect(() => {
    if (isComponentMounted) {
      console.log('pageVariables.footerBio @ FooterSettingsForm: ', pageVariables.footerBio);
      console.log('currentPage?.footer_bios @ FooterSettingsForm: ', currentPage?.footer_bios);

      reset({
        footerBio: currentPage?.footer_bios || [],
        showFooterOptionalCopyBlock: pageVariables?.showFooterOptionalCopyBlock || false,
        footerOptionalCopy: pageVariables?.footerOptionalCopy || '',
      });
    }
  }, [reset, currentPage, pageVariables, isComponentMounted]);

  const redirectToPublish = () => {
    history.push(
      generatePath(routes.publishSite, { siteId: currentMicrosite.id }),
    );
  };

  const biosLimit = useMemo(() => {
    switch (currentPage?.templateName) {
      case msmPageTemplatesMap.LIFE_SCIENCES_OPTUM_BRAND:
      case msmPageTemplatesMap.PROSPECT_SITE_WITH_RESOURCES_AND_LIBRARY_OPTUM_RX:
      case msmPageTemplatesMap.RESOURCE_AND_DOCUMENT_LIBRARY_OPTUM_BRAND:
        return 1;
      case msmPageTemplatesMap.PROSPECT_SITE_UHC:
      case msmPageTemplatesMap.PROSPECT_SITE_UHC_UMR:
        return 4;
      default:
        return 2;
    }
  }, [currentPage?.templateName]);

  const enableOptionalCopyBlock = useMemo(
    () => [
        msmPageTemplatesMap.LIFE_SCIENCES_OPTUM_BRAND,
        msmPageTemplatesMap.PROSPECT_SITE_WITH_RESOURCES_AND_LIBRARY_OPTUM_RX,
        msmPageTemplatesMap.RESOURCE_AND_DOCUMENT_LIBRARY_OPTUM_BRAND,
      ].includes(
        currentPage?.templateName,
      ),
    [currentPage?.templateName],
  );

  const handleFormSubmit = async (formData) => {
    if (isDirty) {
      await dispatch(
        updatePage({
          id: currentPage.id,
          footer_bios: formData?.footerBio || [],
          variables: JSON.stringify({
            ...pageVariables,
            ...formData, // this object "still" has the obsolete footerBio...
          }),
        }),
      );
      history.push(getNextPage(currentMicrosite, currentMicrosite.pages));
    } else {
      history.push(getNextPage(currentMicrosite, currentMicrosite.pages));
    }
  };

  const onPreviewClick = async () => {
    const formData = getValues();
    await dispatch(
      updatePage({
        id: currentPage.id,
        footer_bios: formData?.footerBio || [],
        variables: JSON.stringify({
          ...pageVariables,
          ...formData,
        }),
      }),
    );
    // reset form "dirty" state so further "preview"
    // clicks will not trigger the page update
    reset(formData);
  };

  const renderButtons = () => (
    <>
      <Button disabled={!isValid} type="submit">
        {isDirty ? 'Save Footer and continue' : 'Continue'}
      </Button>
      <PreviewButton
        siteName={currentMicrosite?.name}
        pageName={currentPage?.name}
        onPreviewClick={isDirty ? onPreviewClick : true}
      />
      <Button
        disabled={isDirty || !isSiteComplete(currentMicrosite, currentMicrosite?.pages)}
        onClick={redirectToPublish}
      >
        Publish
      </Button>
    </>
  );

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <StepCard>
        <StepCardHeader>
          <StepCardTitle text="Select a client lead bio" />
        </StepCardHeader>
        <StepCardContent>
          <FormControl noPadding error={errors.footerBio}>
            <Controller
              render={({ field: { onChange, value } }) => (
                <BioPicker
                  max={biosLimit}
                  onChange={onChange}
                  options={bios}
                  value={value}
                />
              )}
              control={control}
              name="footerBio"
              defaultValue={[]}
            />
          </FormControl>
        </StepCardContent>
      </StepCard>

      {enableOptionalCopyBlock && (
        <StepCard>
          <StepCardHeader>
            <StepCardTitle text="Optional copy block" />
          </StepCardHeader>
          <StepCardContent>
            <Stack direction="column" spacing={2}>
              <StyledCheckboxContainer>
                <Checkbox
                  {...register('showFooterOptionalCopyBlock')}
                  text="Include Optional copy block"
                />
              </StyledCheckboxContainer>

              {watch('showFooterOptionalCopyBlock') && (
                <div>
                  <FormControl label="Copy" error={errors?.footerOptionalCopy}>
                    <Controller
                      name={`footerOptionalCopy`}
                      rules={{
                        required: 'Field is required',
                        maxLength: {
                          value: 500,
                          message: 'Maximum length is 500 characters',
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <EditorControl
                          onChange={onChange}
                          value={value}
                        />
                      )}
                      control={control}
                    ></Controller>
                  </FormControl>
                </div>
              )}
            </Stack>
          </StepCardContent>
        </StepCard>
      )}

      <SiteMakerActions renderButtons={renderButtons} />
    </form>
  );
};

export default FooterSettingsForm;
