import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { groupBy } from "lodash";
import { usersApi } from "@clatter/platform";
export const USERS_FEATURE_KEY = 'users';

const mapUserApiToStore = (user) => ({
  ...user,
  id: user.id.toString(),
  _id: user.id,
  name: `${user.first_name?.trim()} ${user.last_name?.trim()}`,
});
export const usersAdapter = createEntityAdapter();
/**
 * Export an effect using createAsyncThunk from
 * the Redux Toolkit: https://redux-toolkit.js.org/api/createAsyncThunk
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(fetchUsers())
 * }, [dispatch]);
 * ```
 */

export const fetchUsers = createAsyncThunk(
  `${USERS_FEATURE_KEY}/fetchStatus`,
  async () => await usersApi.fetchUsers(),
);

export const initialUsersState = usersAdapter.getInitialState({
  loadingStatus: 'loading',
  error: null,
});

const mapAiToStore = (users) => users.map(mapUserApiToStore);

export const usersSlice = createSlice({
  name: USERS_FEATURE_KEY,
  initialState: initialUsersState,
  reducers: {
    add: usersAdapter.addOne,
    remove: usersAdapter.removeOne,
    // ...
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(fetchUsers.fulfilled, (state, { payload }) => {
        usersAdapter.setAll(state, mapAiToStore(payload?.data) || []);
        state.loadingStatus = 'loaded';
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      });
  },
});
/*
 * Export reducer for store configuration.
 */
export const usersReducer = usersSlice.reducer;
/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(usersActions.add({ id: 1 }))
 * }, [dispatch]);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#usedispatch
 */
export const usersActions = usersSlice.actions;
/*
 * Export selectors to query state. For use with the `useSelector` hook.
 *
 * e.g.
 * ```
 * import { useSelector } from 'react-redux';
 *
 * // ...
 *
 * const entities = useSelector(selectAllUsers);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#useselector
 */
const { selectAll, selectEntities } = usersAdapter.getSelectors();
export const getUsersState = (rootState) => rootState[USERS_FEATURE_KEY];
export const selectAllUsers = createSelector(getUsersState, selectAll);
export const selectUsersEntities = createSelector(
  getUsersState,
  selectEntities,
);
export const selectUsersByEmail = createSelector(getUsersState, state =>
  groupBy(selectAll(state), 'email')
);
