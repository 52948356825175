import React from 'react';
import styled from 'styled-components';

const StyledNav = styled.nav`
  margin: 10px 0;
  display: flex;

  a {
    margin: 0 24px 0 0;
    text-decoration: none;
  }
`;

// at this point, this a very hard-wired component
// the only thing needed now is for it to render the
// correct link hrefs to support nav
// note how the need to support nav in the preview mode of the
// app is different than nav in the built static site.
// the current request is for nav in the preview experience
// so the urls we need are e.g.
// /:pageId/preview (for the partner site page)
// /:pageId/preview (for the additional resources page)
const Nav = ({ urlPartnerSite, urlAdditionalResources }) => (
  <StyledNav>
    <a href={urlPartnerSite}>Partner Site</a>
    <a href={urlAdditionalResources}>Additional Resources</a>
  </StyledNav>
);

export default Nav;
