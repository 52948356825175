import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Button, DataTable, Dialog, IconButton, Loader, useDocumentTitle } from '@clatter/ui';
import { ExportImport, formatDateTime, useNotices, usePageTitleHeader } from '@clatter/platform';
import { fetchHeroImages } from '../../../store';
import HeroImageForm from './HeroImageForm';
import {
  deleteHeroImage,
  selectAllHeroimages,
} from '../../../store/hero-images.slice';
import routes, { documentTitleMap } from "../../../routes/routes";
import { format } from 'date-fns';
import { Stack } from '@mui/material';

const heroImageColumns = [
  {
    label: 'Image',
    customRender: (row) => (
      <img
        style={{ width: '100%', maxWidth: '245px' }}
        src={row?.asset?.formats?.thumbnail?.url || row?.asset?.url }
        alt={row.description}
      />
    ),
    align: 'left',
  },
  {
    label: 'Description',
    name: 'description',
    align: 'left',
    sortable: true,
  },
  {
    label: 'Created by',
    name: 'c_created_by',
    align: 'left',
    sortable: true,
  },
  {
    label: 'Date added',
    name: 'createdAt',
    customRender: (row) => formatDateTime(row.createdAt),
    align: 'left',
    sortable: true,
  },
  {
    label: 'Updated by',
    name: 'c_updated_by',
    align: 'left',
    sortable: true,
  },
  {
    label: 'Date updated',
    name: 'updatedAt',
    customRender: (row) => formatDateTime(row?.updatedAt) || '',
    align: 'left',
    sortable: true,
  },
];

const HeroImages = () => {
  useDocumentTitle(documentTitleMap.adminHeroImages)
  const [editableItem, setEditableItem] = useState(null);
  const { addNotice } = useNotices();
  const dispatch = useDispatch();

  const { renderPageTitleHeader } = usePageTitleHeader({
    pageTitle: "Hero images",
    linksType: 'msm-admin',
    routes: routes,
  });

  const handleImportSuccess = (response) => {
    dispatch(fetchHeroImages());

    const message = [];

    if (response.data?.imported?.length > 0) {
      message.push(
        `Successfully imported ${response.data?.imported?.length} hero image(s).`,
      );
    }

    if (response.data?.omitted?.length > 0) {
      message.push(
        `${response.data?.omitted?.length} hero image(s) omitted (duplicated description).`,
      );
    }

    addNotice({
      message: message.join(' '),
      type: 'success',
      title: 'Success',
    });
  };

  useEffect(() => {
    dispatch(fetchHeroImages());
  }, [dispatch]);

  const heroImages = useSelector(selectAllHeroimages);

  const loading = useSelector(
    (state) => state.heroImages.loadingStatus === 'loading',
  );

  const handleEditClick = (event) => {
    const clickedItemId = parseInt(event.currentTarget?.dataset?.id);

    setEditableItem(
      (clickedItemId &&
        heroImages.find((heroImage) => heroImage.id === clickedItemId)) ||
        {},
    );
  };

  const handleDismissEdit = () => {
    setEditableItem(null);
  };

  const handleOnSuccess = () => {
    setEditableItem(null);
    dispatch(fetchHeroImages());
  };

  const handleDeleteClick = (event) => {
    if (window.confirm('Are you sure you want to remove this hero image?')) {
      dispatch(deleteHeroImage(parseInt(event.currentTarget.dataset.id)));
    }
  };

  const isEdit = editableItem && 'asset' in editableItem;

  return (
    <>
      {loading && <Loader />}

      { renderPageTitleHeader({
        headerActions:
          <Stack spacing={1} direction="row" alignItems="center">
            <ExportImport
              exportUrl="/hero-images"
              exportFileName={`hero_images_${process.env.NX_CLIENT}_${format(new Date(), 'yyyy-MM-dd_HH-mm-ss')}`}
              exportDisabled={!heroImages || heroImages?.length === 0}
              onImportSuccessCallback={handleImportSuccess}
            />

            <Button onClick={handleEditClick}>
              Add Hero image
            </Button>
          </Stack>
        })
      }

      <div>
        <DataTable
          autofit
          columns={heroImageColumns}
          defaultSortField="created_at"
          filterColumns={['description']}
          name="adminHeroImages"
          rows={heroImages}
          renderActionColumn={(row) => (
            <>
              <IconButton data-id={row.id} onClick={handleEditClick}>
                <EditOutlinedIcon />
              </IconButton>
              <IconButton data-id={row.id} onClick={handleDeleteClick}>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </>
          )}
        />
      </div>
      <Dialog
        title={isEdit ? 'Edit hero image' : 'Add hero image'}
        onCloseDialog={handleDismissEdit}
        open={!!editableItem}
      >
        <HeroImageForm
          isEdit={isEdit}
          editableItem={editableItem}
          onSuccess={handleOnSuccess}
        />
      </Dialog>
    </>
  );
};

export default HeroImages;
