import React from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { generatePath, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { Button, errorMessages, StepCard, StepCardContent, StepCardHeader, StepCardTitle } from '@clatter/ui';

import routes from '../../routes/routes';
import RailContent from '../RailContent/RailContent';
import PreviewButton from '../SiteMaker/PreviewButton';
import { getNextPage, isSiteComplete } from '../../helpers';
import SiteMakerActions from '../SiteMaker/SiteMakerActions';
import { defaultRailItem } from '../../pages/Content/Content';
import { updatePage } from '../../store';

const railItemsObject = {
  headline: Joi.string().min(1).required(),
  description: Joi.string().min(10).required(),
  showButton: Joi.boolean(),
  buttonText: Joi.when('showButton', {
    is: Joi.boolean().valid(true),
    then: Joi.string().required(),
    otherwise: Joi.string().allow('').optional(),
  }),
  buttonUrl: Joi.when('showButton', {
    is: Joi.boolean().valid(true),
    then: Joi.string().uri().required(),
    otherwise: Joi.string().allow('').optional(),
  }),
};

const railObject = {
  headline: Joi.string().min(1).required(),
  description: Joi.when('items', {
    is: Joi.array().min(1).required(),
    then: Joi.optional().allow(''),
    otherwise: Joi.string().min(10).required(),
  }),
  showButton: Joi.boolean(),
  buttonText: Joi.when('showButton', {
    is: Joi.boolean().valid(true),
    then: Joi.string().required(),
    otherwise: Joi.string().allow('').optional(),
  }),
  buttonUrl: Joi.when('showButton', {
    is: Joi.boolean().valid(true),
    then: Joi.string().uri().required(),
    otherwise: Joi.string().allow('').optional(),
  }),
  items: Joi.array().optional().items(railItemsObject),
};

const validationSchema = Joi.object()
  .keys({
    rails: Joi.array().items(railObject),
  })
  .messages({
    'any.required': errorMessages.REQUIRED.message,
    'string.uri': errorMessages.INVALID.message,
    'string.empty': errorMessages.REQUIRED.message,
    'string.base': errorMessages.REQUIRED.message,
    'array.base': errorMessages.REQUIRED.message,
    'array.min': errorMessages.REQUIRED.message,
    'string.min': errorMessages.REQUIRED.message,
  })
  .options({
    allowUnknown: true,
  });

const defaultRailsConfig = {
  leftMaxItems: 0,
  centerMaxItems: 0,
};
const ChallengeForm = ({ currentMicrosite, currentPage, railsData, pageId, blockName, railsConfig = defaultRailsConfig }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    control,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    getValues,
    setValue,
    reset,
  } = useForm({
    resolver: joiResolver(validationSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      rails: railsData,
    },
  });
  const handleUpdate = async (formData) => {
    if (isValid && isDirty) {
      const variables = JSON.parse(currentPage?.variables);

      const updatedVariables = {
        ...variables,
        [blockName]: formData?.rails || [],
      };

      await dispatch(
        updatePage({
          id: pageId,
          variables: JSON.stringify(updatedVariables),
        }),
      );
    }
  };

  const handleFormSubmit = async (formData) => {
    if (isDirty) {
      await handleUpdate(formData);
    }
    history.push(getNextPage(currentMicrosite, currentMicrosite.pages));
  };

  const onPreviewClick = async () => {
    if (isDirty && isValid) {
      const formData = getValues();
      await handleUpdate(formData);
      reset(formData);
    }
  };

  const handleAddRailItem = (railIndex) => {
    const updated = [
      ...getValues(`rails[${railIndex}].items`),
      {
        ...defaultRailItem,
        id: uuidv4(),
      },
    ];
    setValue(`rails[${railIndex}].items`, updated, {
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const handleDeleteRailItem = ({ railIndex, railItemIndex }) => {
    if (window.confirm('Are you sure you want to remove this item?')) {
      let updated = [...getValues(`rails[${railIndex}].items`)];
      updated.splice(railItemIndex, 1);
      setValue(`rails[${railIndex}].items`, updated, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    }
  };

  const redirectToPublish = () => history.push(generatePath(routes.publishSite, { siteId: currentMicrosite.id }));

  const renderButtons = () => (
    <>
      <Button disabled={!isValid} type="submit">
        {isDirty ? 'Save Challenge and continue' : 'Continue'}
      </Button>
      <PreviewButton siteName={currentMicrosite?.name} pageName={currentPage?.name} onPreviewClick={onPreviewClick} />
      <Button
        disabled={isDirty || !isSiteComplete(currentMicrosite, currentMicrosite?.pages)}
        onClick={redirectToPublish}
      >
        Publish
      </Button>
    </>
  );

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <StepCard>
        <StepCardHeader step={1}>
          <StepCardTitle text={railsConfig?.leftTitle} />
        </StepCardHeader>
        <StepCardContent>
          <RailContent
            index={0}
            control={control}
            errors={errors}
            railsConfig={railsConfig}
            hideCta={railsConfig?.hideLeftCta}
            headline={railsConfig?.leftHeadline}
            maxItems={railsConfig?.leftMaxItems}
            minItems={railsConfig?.leftMinItems}
            itemHeadline={railsConfig?.itemHeadline}
            disableHeadline={railsConfig?.disableLeftHeadline}
            handleAddRailItem={handleAddRailItem}
            handleDeleteRailItem={handleDeleteRailItem}
          />
        </StepCardContent>
      </StepCard>

      {
        !railsConfig.hideCenterRail &&
        <StepCard>
          <StepCardHeader step={2}>
            <StepCardTitle text={railsConfig?.centerTitle} />
          </StepCardHeader>
          <StepCardContent>
            <RailContent
              index={1}
              control={control}
              errors={errors}
              headline={railsConfig?.centerHeadline}
              maxItems={railsConfig?.centerMaxItems}
              disableHeadline={railsConfig?.disableCenterHeadline}
              hideCta={railsConfig?.hideCenterCta}
              handleAddRailItem={handleAddRailItem}
              handleDeleteRailItem={handleDeleteRailItem}
            />
          </StepCardContent>
        </StepCard>
      }

      <SiteMakerActions renderButtons={renderButtons} />
    </form>
  );
};

export default ChallengeForm;
