import React, { useState } from 'react';
import styled from 'styled-components';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {
  List,
  ListItem,
  ListItemActions,
  ListItemIcon,
  ListItemText,
} from '@clatter/ui';
import { IconButton, TextControl } from '@clatter/ui';
import { msmPageTemplatesMap } from "@clatter/platform";
import { isDev } from '../../helpers';
import {
  Link as LinkIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
} from '@mui/icons-material';

const StyledListSelector = styled.div`
  .resource-picker-grid {
    display: grid;
    column-gap: 16px;
    grid-template-areas:
      'pickedResourcesHeader'
      'pickedResourcesContent'
      'availableResourcesHeader'
      'availableResourcesContent'
      'addResourceForm';
  }

  .available-resources-header {
    grid-area: availableResourcesHeader;
  }

  .available-resources-content {
    grid-area: availableResourcesContent;
  }

  .picked-resources-header {
    grid-area: pickedResourcesHeader;
  }

  .picked-resources-content {
    grid-area: pickedResourcesContent;
  }

  .available-resources-content,
  .picked-resources-content {
    max-height: 200px;
    overflow-y: auto;
  }

  .new-resource-form {
    grid-area: addResourceForm;
  }

  @media (min-width: 600px) {
    .resource-picker-grid {
      display: grid;
      column-gap: 32px;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        'availableResourcesHeader pickedResourcesHeader'
        'availableResourcesContent pickedResourcesContent'
        'addResourceForm addResourceForm';
    }
  }

  .no-resources {
    padding: 16px;
  }
`;

const StyledOpenInNewListIcon = styled.span`
  margin-left: 10px;
  font-size: 10px;

  svg {
    color: grey;
    width: 15px;
    height: 15px;
    margin-bottom: -2px;
  }
`

const OpenInNewListIcon = () => (
  <StyledOpenInNewListIcon>
    <LinkIcon />
  </StyledOpenInNewListIcon>
)

const ListSelector = ({
  disabled,
  itemIcon: ItemIcon,
  handleMovePage,
  onAdd,
  onDelete,
  onItemChange,
  options,
  value,
  assetDisplayName,
}) => {
  const [editedItem, setEditedItem] = useState(null);

  if (!options) {
    return null;
  }

  const toggleEditedItem = (event) => {
    setEditedItem(value[event.currentTarget.dataset.index]);
  };

  const handleAddClick = (event) => {
    onAdd(event.currentTarget.dataset.template);
  };

  const onItemMove = (event, direction) => {
    const index = +event.currentTarget.dataset.index;
    return handleMovePage(index, direction === 'up' ? index - 1 : index + 1);
  };

  const handleDeleteClick = (event) => {
    onDelete(value[event.currentTarget.dataset.index]);
  };

  const handleNameChange = (event) => {
    const nextEditedItem = {
      ...editedItem,
      title: event.target.value,
    };

    setEditedItem(nextEditedItem);
  };

  const handleNameChangeConfim = () => {
    onItemChange(editedItem);
    setEditedItem(null);
  };

  const development = isDev();

  const renderOptions = () => (
    <List>
      {options.filter(option => development || (!development && !option.hidden)).map(option => (
        <ListItem
          disabled={disabled}
          hoverable
          tag="button"
          type="button"
          key={option.title}
          data-template={option.title}
          onClick={handleAddClick}
        >
          <ListItemIcon>
            <ItemIcon />
          </ListItemIcon>

          <ListItemText text={option.display_name} />
        </ListItem>
      ))}
    </List>
  );

  const renderValue = (item, index) => {
    if (!item) {
      return <strong>Invalid template!</strong>;
    }

    const isExternalLinkPage = item?.page_template?.template === msmPageTemplatesMap.EXTERNAL_LINK;

    if (item.id === editedItem?.id) {
      return (
        <>
          <TextControl value={editedItem.title} onChange={handleNameChange} />
          <ListItemActions>
            <IconButton onClick={handleNameChangeConfim} tooltip="Confirm">
              <CheckOutlinedIcon />
            </IconButton>
            <IconButton onClick={toggleEditedItem} tooltip="Dismiss">
              <CloseOutlinedIcon />
            </IconButton>
          </ListItemActions>
        </>
      );
    }

    return (
      <>
        <ListItemIcon>
          <ItemIcon />
        </ListItemIcon>
        <ListItemText text={item.title} icon={isExternalLinkPage ?  <OpenInNewListIcon /> : null}/>
        <ListItemActions>
          <IconButton
            disabled={!index}
            data-index={index}
            data-testid="move-up-page-button"
            onClick={(event) => onItemMove(event, 'up')}
            tooltip="Move up"
          >
            <ArrowUpwardIcon />
          </IconButton>
          <IconButton
            disabled={value.length <= index + 1}
            data-index={index}
            data-testid="move-down-page-button"
            onClick={(event) => onItemMove(event, 'down')}
            tooltip="Move down"
          >
            <ArrowDownwardIcon />
          </IconButton>
          <IconButton
            data-index={index}
            data-testid="edit-page-button"
            onClick={toggleEditedItem}
            tooltip="Edit"
          >
            <EditOutlinedIcon />
          </IconButton>
          <IconButton
            data-index={index}
            data-testid="delete-page-button"
            onClick={handleDeleteClick}
            tooltip="Delete"
          >
            <DeleteOutlinedIcon />
          </IconButton>
        </ListItemActions>
      </>
    );
  };

  const renderValues = () => {
    if (!value || !value.length) {
      return (
        <div className="no-resources">
          Choose a page template on the left to get started.
        </div>
      );
    }

    return (
      <List>
        {value.map((item, index) => (
          <ListItem key={`${item?.id}-${index}`}>
            {renderValue(item, index)}
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <StyledListSelector>
      <div className="resource-picker-grid">
        <div className="available-resources-header">
          <h4>Available {assetDisplayName}</h4>
        </div>
        <div className="available-resources-content">{renderOptions()}</div>
        <div className="picked-resources-header">
          <h4>Pages</h4>
        </div>
        <div className="picked-resources-content">{renderValues()}</div>
      </div>
    </StyledListSelector>
  );
};

export default ListSelector;
