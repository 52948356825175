import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { defaultBorderColor, FormMessage } from '@clatter/ui';
import MessageProvider from '../MessageProvider/MessageProvider';

const StyledSiteMakerActions = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-top: 1px solid ${defaultBorderColor};

  .site-maker-actions-content {
    max-width: 1400px;
    padding-left: 312px;
    padding-right: 16px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .site-maker-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0;

    > * + * {
      margin-left: 8px;
    }
  }

  .site-maker-message {
    margin-top: 8px;
    margin-left: 8px;
  }
`;

const SiteMakerActions = ({ renderButtons }) => (
  <StyledSiteMakerActions>
    <MessageProvider subscriber="updateMicrosite">
      {(message, handleDismiss) => (
        <div className="site-maker-message">
          <FormMessage message={message} onDismiss={handleDismiss} />
        </div>
      )}
    </MessageProvider>
    <div className="site-maker-actions-content">
      <div className="site-maker-buttons">{renderButtons()}</div>
    </div>
  </StyledSiteMakerActions>
);

SiteMakerActions.propTypes = {
  renderButtons: PropTypes.func.isRequired,
};

export default SiteMakerActions;
