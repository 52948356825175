import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { requestStatus } from '@clatter/platform';
import contentRailsApi from '../api/contentRails.api';
export const CONTENT_RAILS_FEATURE_KEY = 'contentRails';
export const contentRailsAdapter = createEntityAdapter();

export const fetchContentRailsById = createAsyncThunk(
  `${CONTENT_RAILS_FEATURE_KEY}/fetch`,
  async ({ ids }, thunkAPI) =>
    contentRailsApi.fetchContentRailsById(Array.isArray(ids) ? ids : [ids]),
);

export const updateContentRail = createAsyncThunk(
  `${CONTENT_RAILS_FEATURE_KEY}/update`,
  async ({ id, data }, thunkAPI) =>
    contentRailsApi.updateContentRail({ id: id, data: data }),
);

export const createContentRail = createAsyncThunk(`${CONTENT_RAILS_FEATURE_KEY}/create`, async ({ data }) => {
  const contentRail = await contentRailsApi.createContentRail({ data: data });

  return mapApiToStore(contentRail.data);
});

export const initialContentRailsState = contentRailsAdapter.getInitialState({
  loadingStatus: requestStatus.initial,
  error: null,
});

const isPendingAction = (action) => {
  const contentPendingActions = [
    `${CONTENT_RAILS_FEATURE_KEY}/update/pending`,
    `${CONTENT_RAILS_FEATURE_KEY}/create/pending`,
  ];

  return contentPendingActions.includes(action.type);
};

const isRejectedAction = (action) => {
  const contentRejectedActions = [
    `${CONTENT_RAILS_FEATURE_KEY}/update/rejected`,
    `${CONTENT_RAILS_FEATURE_KEY}/create/rejected`,
  ];

  return contentRejectedActions.includes(action.type);
};

const mapApiToStore = (item) => ({
  id: item.id,
  ...item.attributes,
});

export const contentRailsSlice = createSlice({
  name: CONTENT_RAILS_FEATURE_KEY,
  initialState: initialContentRailsState,
  reducers: {
    add: contentRailsAdapter.addOne,
    remove: contentRailsAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateContentRail.fulfilled, (state, { payload }) => {
        contentRailsAdapter.upsertOne(state, payload);
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(createContentRail.fulfilled, (state, { payload }) => {
        contentRailsAdapter.upsertOne(state, payload);
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(fetchContentRailsById.fulfilled, (state, { payload }) => {
        contentRailsAdapter.setAll(state, payload);
        state.loadingStatus = requestStatus.fulfilled;
      })

      .addMatcher(isPendingAction, (state, action) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addMatcher(isRejectedAction, (state, { error }) => {
        state.loadingStatus = requestStatus.error;
        state.error = error.message;
      });
  },
});
export const contentRailsReducer = contentRailsSlice.reducer;

export const contentRailsActions = contentRailsSlice.actions;

const { selectAll, selectEntities } = contentRailsAdapter.getSelectors();
export const getContentRailsState = (rootState) =>
  rootState[CONTENT_RAILS_FEATURE_KEY];
export const selectAllContentRails = createSelector(
  getContentRailsState,
  selectAll,
);
export const selectContentRailsEntities = createSelector(
  getContentRailsState,
  selectEntities,
);
