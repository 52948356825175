import React from 'react';
import {
  Button,
  buttonVariants,
  Checkbox,
  EditorControl,
  FormControl,
  IconButton,
  TextControl,
} from '@clatter/ui';
import { Controller, useWatch } from 'react-hook-form';
import styled from 'styled-components';
import { Stack } from '@mui/material';

import { DeleteOutlined as DeleteOutlinedIcon } from '@mui/icons-material';

const StyledRailItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 2rem 0;

  &:not(:last-child) {
    border-bottom: 2px solid lightgray;
  }
`;


const RailContent = ({
   control,
   index = 0,
   errors,
   headline = '',
   maxItems,
   minItems = 1,
   handleAddRailItem,
   handleDeleteRailItem,
   disableHeadline = false,
   hideCta = false,
   itemHeadline = false,
}) => {
  const { register } = control;

  const isButtonEnabled = useWatch({
    control,
    name: `rails[${index}].showButton`,
  });

  const items = useWatch({
    control,
    name: `rails[${index}].items`,
  });

  return (
    <>
      <FormControl label={headline} error={errors?.rails?.[index]?.headline}>
        <TextControl
          {...register(`rails[${index}].headline`)}
          placeholder="Enter a headline"
          disabled={disableHeadline}
          maxLength={45}
        />
      </FormControl>

      {maxItems <= 0 && (
        <FormControl error={errors?.rails?.[index]?.description}>
          <Controller
            name={`rails[${index}].description`}
            render={({ field: { onChange, value } }) => (
              <EditorControl onChange={onChange} value={value} />
            )}
            control={control}
          ></Controller>
        </FormControl>
      )}

      {maxItems > 0 && (
        <Stack direction="column" spacing={4} mt={2}>
          {items?.map((railItem, railItemIndex) => {
            const railItemButtonEnabled = railItem?.showButton;
            return (
              <StyledRailItem key={railItem.id}>
                <Stack direction="row" justifyContent="flex-end">
                  <IconButton
                    disabled={items?.length <= minItems}
                    onClick={() =>
                      handleDeleteRailItem({
                        railIndex: index,
                        railItemIndex: railItemIndex,
                      })
                    }
                    tooltip="Delete Item"
                  >
                    <DeleteOutlinedIcon />
                  </IconButton>
                </Stack>

                {/* Conditionally render the headline for each item */}
                {itemHeadline && (
                  <FormControl
                    label="Headline"
                    error={errors?.rails?.[index]?.items?.[railItemIndex]?.headline}
                  >
                    <TextControl
                      {...register(`rails[${index}].items[${railItemIndex}].headline`)}
                      placeholder="Enter Headline"
                      maxLength={45}
                    />
                  </FormControl>
                )}

                <FormControl
                  error={
                    errors?.rails?.[index]?.items?.[railItemIndex]?.description
                  }
                >
                  <Controller
                    name={`rails[${index}].items[${railItemIndex}].description`}
                    render={({ field: { onChange, value } }) => (
                      <EditorControl onChange={onChange} value={value} />
                    )}
                    control={control}
                  ></Controller>
                </FormControl>

                {
                  !hideCta &&
                  <>
                    <FormControl
                      error={
                        errors?.rails?.[index]?.items?.[railItemIndex]?.showButton
                      }
                    >
                      <Checkbox
                        text="Include Call-to-Action Button"
                        {...register(
                          `rails[${index}].items[${railItemIndex}].showButton`,
                        )}
                      />
                    </FormControl>

                    {railItemButtonEnabled && (
                      <>
                        <FormControl
                          error={
                            errors?.rails?.[index]?.items?.[railItemIndex]
                              ?.buttonText
                          }
                        >
                          <TextControl
                            {...register(
                              `rails[${index}].items[${railItemIndex}].buttonText`,
                            )}
                            placeholder="Enter Button Text"
                            maxLength={20}
                          />
                        </FormControl>

                        <FormControl
                          error={
                            errors?.rails?.[index]?.items?.[railItemIndex]
                              ?.buttonUrl
                          }
                        >
                          <TextControl
                            {...register(
                              `rails[${railItemIndex}].items[${railItemIndex}].buttonUrl`,
                            )}
                            placeholder="Enter URL"
                          />
                        </FormControl>
                      </>
                    )}
                  </>
                }
              </StyledRailItem>
            );
          })}
          <Button
            disabled={items?.length === maxItems}
            variant={buttonVariants.clear}
            onClick={() => handleAddRailItem(index)}
            testId={`add-rail-item[${index}]-button`}
          >
            + Add Item
          </Button>
        </Stack>
      )}

      {maxItems <= 0 && !hideCta && (
        <>
          <FormControl error={errors?.rails?.[index]?.showButton}>
            <Checkbox
              text="Include Call-to-Action Button"
              {...register(`rails[${index}].showButton`)}
            />
          </FormControl>

          {isButtonEnabled && (
            <>
              <FormControl error={errors?.rails[`${index}`]?.buttonText}>
                <TextControl
                  {...register(`rails[${index}].buttonText`)}
                  placeholder="Enter Button Text"
                  maxLength={20}
                />
              </FormControl>

              <FormControl error={errors?.rails[`${index}`]?.buttonUrl}>
                <TextControl
                  {...register(`rails[${index}].buttonUrl`)}
                  placeholder="Enter URL"
                />
              </FormControl>
            </>
          )}
        </>
      )}
    </>
  );
};

export default RailContent;

