import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { generatePath, useHistory } from 'react-router-dom';
import {
  Button,
  Checkbox,
  FormControl,
  EditorControl,
  StepCard,
  StepCardContent,
  StepCardHeader,
  StepCardTitle,
  StyledCheckboxContainer,
  TextControl,
} from '@clatter/ui';
import { updatePage } from '../../store';
import ImagePicker from '../../components/ImagePicker/ImagePicker';
import { videoTableColumns } from '../../pages/ComponentsList/mocks';
import SingleVideoPicker from '../SingleVideoPicker/SingleVideoPicker';
import SiteMakerContentHead from '../SiteMaker/SiteMakerContentHead';
import PreviewButton from '../SiteMaker/PreviewButton';
import SiteMakerActions from '../SiteMaker/SiteMakerActions';
import { getNextPage, isSiteComplete} from '../../helpers';
import routes from '../../routes/routes';
import {
  msmPageTemplatesMap,
  useDeepCompareEffect,
  useDeepCompareMemo,
  useIsComponentMounted,
  usePageTitleHeader,
} from '@clatter/platform';
import { Stack } from '@mui/material';

const mapPageSettingsToApi = ({ pageId, formData, pageVariables }) => ({
  id: pageId,
  title: formData.pageName,
  banner_video: formData?.bannerVideo?.id || null,
  banner_image: formData?.heroImage?.id,
  variables: JSON.stringify({
    ...pageVariables,
    headline: formData.headline,
    introCopy: formData.introCopy,
    showOptionalCopyBlock: formData.showOptionalCopyBlock,
    optionalCopy: formData.optionalCopy,
    optionalSectionTitle: formData.optionalSectionTitle,
  }),
});

const PageSettingsForm = ({
  currentMicrosite,
  currentPage,
  heroImages,
  videos,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isComponentMounted = useIsComponentMounted();

  const {
    control,
    formState: { errors, isDirty, isValid },
    trigger,
    getValues,
    handleSubmit,
    register,
    watch,
    reset,
  } = useForm({
    mode: 'onChange',
  });

  const pageVariables = useDeepCompareMemo(() => {
    if (currentPage) {
      return JSON.parse(currentPage.variables);
    }

    return {
      headline: '[default headline]',
      showOptionalCopyBlock: false,
    };
  }, [currentPage]);

  useDeepCompareEffect(() => {
    if (isComponentMounted && currentPage) {
      reset({
        bannerVideo: currentPage?.banner_video,
        pageName: currentPage.title?.trim(),
        headline: pageVariables.headline,
        introCopy: pageVariables.introCopy,
        heroImage: currentPage?.banner_image,
        showOptionalCopyBlock: pageVariables.showOptionalCopyBlock,
        optionalCopy: pageVariables.optionalCopy,
        optionalSectionTitle: pageVariables.optionalSectionTitle,
      });
    }
  }, [reset, currentPage, pageVariables, isComponentMounted]);

  const showOptionalCopyBlock = watch('showOptionalCopyBlock')

  const redirectToPublish = () => {
    history.push(
      generatePath(routes.publishSite, { siteId: currentMicrosite.id }),
    );
  };

  const { renderPageTitleHeader } = usePageTitleHeader({
    currentPage: currentPage,
    currentMicrosite: currentMicrosite,
    pageTitle: "Customize Your Page Banner",
    routes: routes
  });

  const enableOptionalCopyBlock = [
    msmPageTemplatesMap.PARTNER_SITE,
    msmPageTemplatesMap.PARTNER_SITE_COLEY,
    msmPageTemplatesMap.PARTNER_SITE_OPTUM,
    msmPageTemplatesMap.PARTNER_SITE_OPTUM_RX,
    msmPageTemplatesMap.LIFE_SCIENCES_OPTUM_BRAND,
    msmPageTemplatesMap.PROSPECT_SITE_WITH_RESOURCES_AND_LIBRARY_OPTUM_RX,
    msmPageTemplatesMap.RESOURCE_AND_DOCUMENT_LIBRARY_OPTUM_BRAND,
    msmPageTemplatesMap.STATE_GOVERNMENT_OPTUM,
    msmPageTemplatesMap.PARTNER_SITE_WITH_TWO_CONTENT_AND_TEAM_OPTUM_RX,
    msmPageTemplatesMap.EVENTS_OPTUM,
    msmPageTemplatesMap.LANDING_PAGE_MA,
    msmPageTemplatesMap.LANDING_PAGE_UHC,
  ].includes(currentPage?.templateName);

  const handleFormSubmit = async (formData) => {
    const nextPage = getNextPage(currentMicrosite, currentMicrosite.pages);
    if (isDirty) {
      await dispatch(
        updatePage(
          mapPageSettingsToApi({
            pageId: currentPage?.id,
            formData: formData,
            pageVariables: pageVariables,
          }),
        ),
      );
    }
    history.push(nextPage);
  };

  const onPreviewClick = async () => {
    const formData = getValues();
    await dispatch(
      updatePage(
        mapPageSettingsToApi({
          pageId: currentPage?.id,
          formData: formData,
          pageVariables: pageVariables,
        }),
      ),
    );
    // reset form "dirty" state so further "preview"
    // clicks will not trigger the page update
    reset(formData);
  };

  const renderButtons = () => (
    <>
      <Button disabled={!isValid} type="submit">
        {isDirty ? 'Save Banner and continue' : 'Continue'}
      </Button>
      <PreviewButton
        siteName={currentMicrosite?.name}
        pageName={currentPage?.name}
        onPreviewClick={isDirty ? onPreviewClick : true}
      />
      <Button
        disabled={
          isDirty || !isSiteComplete(currentMicrosite, currentMicrosite?.pages)
        }
        onClick={redirectToPublish}
      >
        Publish
      </Button>
    </>
  );

  // unregister will remove fields content
  // so we are using trigger to revalidate
  // optional fields so form could be submitted
  // if no values for optional fields are provided
  // and user will uncheck include optional field
  useEffect(() => {
    if(enableOptionalCopyBlock && !showOptionalCopyBlock) {
      trigger('optionalCopy');
      trigger('optionalSectionTitle')
    }
  }, [enableOptionalCopyBlock, showOptionalCopyBlock])

  return (
    <>
      { renderPageTitleHeader() }

      <SiteMakerContentHead subhead="A preview of your banner will appear below."/>

      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <StepCard>
          <StepCardHeader step={1}>
            <StepCardTitle text="Enter headline and intro copy" />
          </StepCardHeader>
          <StepCardContent>
            <FormControl label="Headline" error={errors.headline}>
              <TextControl
                {...register('headline', {
                  required: { value: true, message: 'Field is required' },
                })}
                placeholder="Enter a headline"
                maxLength={45}
              />
            </FormControl>
            <FormControl label="Intro Copy" error={errors.introCopy}>
              <TextControl
                {...register('introCopy', {
                  required: { value: true, message: 'Field is required' },
                })}
                placeholder="Enter the intro copy"
                maxLength={120}
              />
            </FormControl>
          </StepCardContent>
        </StepCard>
        <StepCard>
          <StepCardHeader step={2}>
            <StepCardTitle text="Select banner background" />
          </StepCardHeader>
          <StepCardContent>
            <FormControl noPadding error={errors.heroImage}>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <ImagePicker
                    images={heroImages}
                    onChange={onChange}
                    value={value}
                    showBorderForNonSelectedImages
                  />
                )}
                control={control}
                name="heroImage"
                defaultValue={null}
              />
            </FormControl>
          </StepCardContent>
        </StepCard>
        <StepCard>
          <StepCardHeader step={3}>
            <StepCardTitle text="Select banner video" />
          </StepCardHeader>
          <StepCardContent>
            <p>
              Video selection in the banner is optional. In the upcoming video
              step, you will be able to select additional video options.
            </p>
            <FormControl noPadding error={errors.bannerVideo}>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <SingleVideoPicker
                    onChange={onChange}
                    options={videos}
                    tableColumns={videoTableColumns}
                    value={value}
                  />
                )}
                control={control}
                name="bannerVideo"
                defaultValue={null}
              />
            </FormControl>
          </StepCardContent>
        </StepCard>
        {enableOptionalCopyBlock && (
          <StepCard>
            <StepCardHeader step={4}>
              <StepCardTitle text="Optional copy block" />
            </StepCardHeader>
            <StepCardContent>
              <Stack direction="column" spacing={2}>
                <StyledCheckboxContainer>
                  <Checkbox
                    {...register('showOptionalCopyBlock')}
                    text="Include Optional copy block"
                  />
                </StyledCheckboxContainer>

                {showOptionalCopyBlock && (
                  <div>
                    <FormControl
                      label="Section title"
                      error={errors.optionalSectionTitle}
                    >
                      <TextControl
                        maxLength={100}
                        {...register('optionalSectionTitle')}
                        placeholder="Enter section title"
                        defaultValue={currentPage?.optionalSectionTitle || ''}
                      />
                    </FormControl>
                    <FormControl label="Copy" error={errors.optionalCopy}>
                      <Controller
                        name={`optionalCopy`}
                        rules={{
                          required: 'Field is required', // FIXME: this is not working for some reason
                          maxLength: {
                            value: 500,
                            message: 'Maximum length is 500 characters',
                          },
                        }}
                        defaultValue={currentPage?.optionalCopy || ''}
                        render={({ field: { onChange, value } }) => (
                          <EditorControl
                            onChange={onChange}
                            value={value}
                          />
                        )}
                        control={control}
                      ></Controller>
                    </FormControl>
                  </div>
                )}
              </Stack>
            </StepCardContent>
          </StepCard>
        )}
        <SiteMakerActions renderButtons={renderButtons} />
      </form>
    </>
  );
};

export default PageSettingsForm;
