import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {
  DataTable,
  FormControl,
  IconButton,
  SelectControl,
  TextControl,
  VideoPreviewDialog,
} from '@clatter/ui';

const StyledVideoPicker = styled.div`
  .filters {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: column;
    gap: 16px;
  }

  h3 {
    margin: 24px 0 8px;
  }
`;

const VideoPicker = ({ max = 0, onChange, options, tableColumns, value }) => {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [availableCategories, setAvailableCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [previewVideo, setPreviewVideo] = useState(null);

  useEffect(() => {
    const nextAvailableCategories = [
      ...new Set(
        options.map((resource) => resource.video_category?.categoryName),
      ),
    ]
      .filter((resource) => !!resource)
      .map((category) => ({
        label: category,
        value: category,
      }));

    nextAvailableCategories.unshift({
      label: 'All',
      value: null,
    });

    setAvailableCategories(nextAvailableCategories);
  }, [value, options]);

  useEffect(() => {
    let nextFilteredOptions = options.filter(
      ({ id: optionId }) =>
        (value &&
          Array.isArray(value) &&
          !value.some(({ id: valueId }) => valueId === optionId)) ||
        !value,
    );

    if (categoryFilter && categoryFilter.value) {
      nextFilteredOptions = nextFilteredOptions.filter(
        (item) => item?.video_category?.categoryName === categoryFilter.value,
      );
    }

    if (searchTerm) {
      nextFilteredOptions = nextFilteredOptions.filter((video) =>
        video.title.toLowerCase().includes(searchTerm.toLowerCase()),
      );
    }

    setFilteredOptions(nextFilteredOptions);
  }, [value, searchTerm, categoryFilter, options]);

  if (!options || !options.length) {
    return <div>Error: no videos provided. Check the CMS.</div>;
  }

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCategoryFilterChange = (option) => {
    setCategoryFilter(option);
  };

  const handlePreviewClick = (event) => {
    const previewVideo = options.find(
      (item) => item.id === parseInt(event.currentTarget.dataset.id),
    );

    setPreviewVideo(previewVideo);
  };

  const handleClosePreviewDialog = () => {
    setPreviewVideo(null);
  };

  const handleAdd = (event) => {
    const nextValue = cloneDeep(value);
    const clickedItemId = parseInt(event.currentTarget.dataset.id);
    const newItem = cloneDeep(
      filteredOptions.find(({ id }) => id === clickedItemId),
    );
    nextValue.push(newItem);
    onChange(nextValue);
  };

  return (
    <StyledVideoPicker>
      <h4>Available videos</h4>
      <div className="filters">
        <FormControl>
          <TextControl
            onChange={handleSearchTermChange}
            placeholder="Search videos"
            value={searchTerm}
          />
        </FormControl>
        <FormControl>
          <SelectControl
            onChange={handleCategoryFilterChange}
            options={availableCategories}
            placeholder="Select a category"
            value={categoryFilter}
          />
        </FormControl>
      </div>
      <DataTable
        name='video-picker-list'
        columns={tableColumns}
        rows={filteredOptions}
        renderActionColumn={(row) => (
          <>
            <IconButton
              disabled={max && value?.length >= max}
              data-id={row.id}
              onClick={handleAdd}
              tooltip="Add"
            >
              <AddIcon />
            </IconButton>
            <IconButton
              data-id={row.id}
              onClick={handlePreviewClick}
              tooltip="Preview"
            >
              <PlayArrowIcon />
            </IconButton>
          </>
        )}
      />
      <h3>Your selected videos</h3>
      <DataTable
        name='video-picker-selected'
        columns={tableColumns}
        rows={(value || []).map(({ id }) =>
          options.find((resource) => resource.id === id),
        )}
        noRowsMessage="No videos selected"
        onReorder={onChange}
        onRemoveItems={onChange}
        fullHeight
        selectable
        disableSearch
        selectableActions
      />
      <VideoPreviewDialog
        title={previewVideo?.title}
        videoUrl={previewVideo?.link}
        open={previewVideo?.link}
        onCloseDialog={handleClosePreviewDialog}
      />
    </StyledVideoPicker>
  );
};

export default VideoPicker;
