import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  FormButtons,
  FormControl,
  Loader,
  TextareaControl,
} from '@clatter/ui';
import AdminContentHead from '../AdminContentHead';
import systemConfigurationApi from '../../../api/systemConfiguration';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { platformSelectors, useAuth, useNotices, revalidatePages } from '@clatter/platform';
import { fetchMicrosites } from '../../../store';
import { selectAllMicrosites } from '../../../store/microsites.slice';

const SITES_HOST = process.env.NX_SITES_HOST;
const revalidateUrl = `${SITES_HOST}/api/revalidate`;

const StyledTextAreaWrapper = styled.div`
  textarea {
    font-family: monospace;
  }
`;

const TrackingPixel = () => {
  const {
    formState: { errors },
    reset,
    formState,
    handleSubmit,
    register,
  } = useForm({
    mode: 'onChange',
  });
  const { addNotice } = useNotices();
  const dispatch = useDispatch();
  const { user, isLoading: isAuthLoading } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  const systemConfigurationId = useSelector(
    platformSelectors.toolInfo.selectToolInfo,
  )?.data?._id;

  const microsites = useSelector(selectAllMicrosites);

  const showLoader = isAuthLoading || isLoading || !systemConfigurationId;
  const handleFormSubmit = async (formData) => {
    setIsLoading(true);
    const data = {
      msmConfig: {
        trackingPixel: formData?.trackingPixel?.trim(),
      },
    };
    try {
      await systemConfigurationApi.updateSystemConfiguration({
        configurationId: systemConfigurationId,
        data: data,
      });

      for (let currentMicrosite of microsites) {
        await revalidatePages({
          currentMicrosite: currentMicrosite,
          revalidateUrl: revalidateUrl,
        });
      }

      reset({ trackingPixel: formData.trackingPixel });

      addNotice({
        message: 'Tracking pixel has been successfully updated.',
        type: 'success',
        title: 'Success',
      });
    } catch (error) {
      addNotice({
        message: error?.message || 'Error updating tracking pixel.',
        type: 'error',
        title: 'Error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      dispatch(fetchMicrosites({ user: user }));
    }
  }, [user]);

  useEffect(() => {
    if (!systemConfigurationId) {
      return;
    }

    (async () => {
      try {
        const { data } = await systemConfigurationApi.getSystemConfiguration({
          configurationId: systemConfigurationId,
        });
        setIsLoading(false);
        reset({ trackingPixel: data?.msmConfig?.trackingPixel || '' });
      } catch (error) {
        addNotice({
          message: error?.message || 'Error fetching tracking pixel data.',
          type: 'error',
          title: 'Error',
        });
      }
    })();

    // needed for prevent memory leaks ^
    return () => {
      setIsLoading(false);
    };
  }, [systemConfigurationId]);

  return (
    <>
      {showLoader && <Loader />}
      <AdminContentHead>
        <h1>Tracking Pixel</h1>
      </AdminContentHead>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <FormControl error={errors.trackingPixel}>
          <StyledTextAreaWrapper>
            <TextareaControl
              {...register('trackingPixel', {
                required: { value: true, message: 'Field is required' },
              })}
              rows={15}
              placeholder="Paste tracking pixel code (javascript) here. This will be added to every published microsite page."
            />
          </StyledTextAreaWrapper>
          <small>
            <strong>NOTE:</strong> Submitting the form will revalidate all
            microsites pages.
          </small>
        </FormControl>
        <FormButtons>
          <Button
            disabled={
              isLoading || !formState.isDirty || !!Object.keys(errors)?.length
            }
            type="submit"
          >
            Save
          </Button>
        </FormButtons>
      </form>
    </>
  );
};

export default TrackingPixel;
