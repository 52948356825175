import getAxiosWithStrapi from './strapiFactory';

const baseStrapi = {
  upload: (data) =>
    getAxiosWithStrapi({
      url: '/upload',
      method: 'post',
      data: data,
    }),
};

export default baseStrapi;
