import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Loader, SsoCallbackPage, TrackPageviewEvent } from '@clatter/ui';
import { Analytics, LogoutPageView, ProtectedRoute, sharedRoutes } from '@clatter/platform';
import Content from "../pages/Content/Content";
import MyMicrosites from '../pages/MyMicrosites/MyMicrosites';
import SiteSettings from '../pages/SiteSettings/SiteSettings';
import PageSettings from '../pages/PageSettings/PageSettings';
import PagePreview from '../pages/PagePreview/PagePreview';
import PreviewModal from '../pages/PreviewModal/PreviewModal';
import ComponentsList from '../pages/ComponentsList/ComponentsList';
import VideosBlockConfig from '../pages/VideosBlockConfig/VideosBlockConfig';
import ResourcesBlockConfig, { renderPreviewTypes } from '../pages/ResourcesBlockConfig/ResourcesBlockConfig';
import LinkResourcesBlockConfig from '../pages/ResourcesBlockConfig/LinkResourcesBlockConfig';
import BiosBlockConfig from '../pages/BiosBlockConfig/BiosBlockConfig';
import Publish from '../pages/Publish/Publish';
import FooterSettings from '../pages/FooterSettings/FooterSettings';
import AdditionalVideosBlock from '../pages/AdditionalVideosBlock/AdditionalVideosBlock';
import AnalyticsView from '../pages/Analytics/AnalyticsView';
import ReportPageviewsView from '../pages/Reports/Pageviews/PageViewsView';
import MicrositeStatisticsView from '../pages/Reports/MicrositeStatistics/MicrositeStatisticsView';
import ReportMicrositesDetailView from '../pages/Reports/MicrositesDetail/MicrositesDetailView';
import ReportMicrositesCreatedView from '../pages/Reports/MicrositeCreate/MicrositeCreateReportView';
import ReportMicrositesPageCreatedView from '../pages/Reports/MicrositePageCreate/MicrositePageCreateReportView';
import ExternalLinkBlock from '../pages/ExternalLinkBlock/ExternalLinkBlock';
import Admin from '../components/Admin/Admin';
import Page from '../components/Page/Page';
import { userRolesMap } from '../constants';
import routes from './routes';
import LandingPageView from '../components/LandingPage/LandingPageView';
import OptionalCopyBlock from '../pages/OptionalCopyBlock/OptionalCopyBlock';
import PerformanceBlock from '../pages/Performance/PerformanceBlock';
import ChallengeBlock from '../pages/Challenge/ChallengeBlock';
import TestimonialsBlock from '../pages/Testimonials/TestimonialsBlock';
const AppRoutes = () => (
  <Router>
    <TrackPageviewEvent />
    <Analytics />

    <Suspense fallback={<Loader />}>
      <Switch>
        <Route path={routes.index} exact component={LandingPageView} />
        <Route exact path={routes.logout} component={LogoutPageView} />
        <Route exact path={sharedRoutes.ssoCallback} component={SsoCallbackPage} />
        <ProtectedRoute path="/admin" accessRoles={[userRolesMap.admin, userRolesMap.contentAdmin]}>
          <Page>
            <Admin />
          </Page>
        </ProtectedRoute>
        <ProtectedRoute
          path={routes.microsites}
          exact
          accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
        >
          <Page>
            <MyMicrosites />
          </Page>
        </ProtectedRoute>
        <ProtectedRoute
          path={routes.siteSettings}
          accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
        >
          <Page>
            <SiteSettings />
          </Page>
        </ProtectedRoute>
        <ProtectedRoute
          path={routes.pageSettings}
          accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
          render={(props) => (
            <Page key={props.match.params.pageId}>
              <PageSettings />
            </Page>
          )}
        />
        <ProtectedRoute
          path={routes.pageContent}
          accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
          component={Content}
        />
        <ProtectedRoute
          path={routes.pageResourcesTable}
          exact
          accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
          render={(props) => (
            <Page key={props.match.params.pageId}>
              <ResourcesBlockConfig renderPreviewAs={renderPreviewTypes.table} />
            </Page>
          )}
        />
        <ProtectedRoute
          path={routes.pageQuickLinks}
          accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
          component={LinkResourcesBlockConfig}
        />
        <ProtectedRoute
          path={routes.pageOptionalCopyBlock}
          accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
          component={OptionalCopyBlock}
        />
        <ProtectedRoute
          path={routes.pagePerformance}
          accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
          component={PerformanceBlock}
        />
        <ProtectedRoute
          path={routes.pageTestimonials}
          accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
          component={TestimonialsBlock}
        />
        <ProtectedRoute
          path={routes.pageChallenge}
          accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
          component={ChallengeBlock}
        />
        <ProtectedRoute
          path={routes.publishSite}
          exact
          accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
        >
          <Page>
            <Publish />
          </Page>
        </ProtectedRoute>
        <ProtectedRoute
          path={routes.pageResources}
          exact
          accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
          render={(props) => (
            <Page key={props.match.params.pageId}>
              <ResourcesBlockConfig />
            </Page>
          )}
        />
        <ProtectedRoute
          path={routes.pageVideos}
          exact
          accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
          render={(props) => (
            <Page key={props.match.params.pageId}>
              <VideosBlockConfig />
            </Page>
          )}
        />
        <ProtectedRoute
          path={routes.pageVideosTable}
          exact
          accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
          render={(props) => (
            <Page key={props.match.params.pageId}>
              <AdditionalVideosBlock />
            </Page>
          )}
        />
        <ProtectedRoute
          path={routes.pageBios}
          exact
          accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
          render={(props) => (
            <Page key={props.match.params.pageId}>
              <BiosBlockConfig />
            </Page>
          )}
        />
        <ProtectedRoute
          path={routes.externalLink}
          exact
          accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
          render={(props) => (
            <Page key={props.match.params.pageId}>
              <ExternalLinkBlock />
            </Page>
          )}
        />
        <ProtectedRoute
          path={routes.pageFooter}
          exact
          accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
          render={(props) => (
            <Page key={props.match.params.pageId}>
              <FooterSettings />
            </Page>
          )}
        />
        <ProtectedRoute
          path={routes.pagePreview}
          accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
        >
          <Page clean>
            <PagePreview />
          </Page>
        </ProtectedRoute>
        <ProtectedRoute
          path={routes.pageBuildPreview}
          accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
        >
          <Page>
            <PreviewModal />
          </Page>
        </ProtectedRoute>
        <ProtectedRoute
          path={routes.components}
          exact
          accessRoles={[userRolesMap.user, userRolesMap.contentAdmin, userRolesMap.admin]}
        >
          <Page>
            <ComponentsList />
          </Page>
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={routes.analytics}
          component={AnalyticsView}
          accessRoles={userRolesMap.admin}
        />
        <ProtectedRoute
          path={routes.reportsPageviews}
          accessRoles={[userRolesMap.admin]}
          component={ReportPageviewsView}
        />
        <ProtectedRoute
          path={routes.micrositeStatistics}
          accessRoles={[userRolesMap.admin, userRolesMap.contentAdmin]}
          component={MicrositeStatisticsView}
        />
        <ProtectedRoute
          path={routes.reportsMicrositesDetail}
          accessRoles={[userRolesMap.admin]}
          component={ReportMicrositesDetailView}
        />
        <ProtectedRoute
          path={routes.reportsMicrositesCreate}
          accessRoles={[userRolesMap.admin]}
          component={ReportMicrositesCreatedView}
        />
        <ProtectedRoute
          path={routes.reportsMicrositesPageCreate}
          accessRoles={[userRolesMap.admin]}
          component={ReportMicrositesPageCreatedView}
        />
      </Switch>
    </Suspense>
  </Router>
);

export default AppRoutes;
