import { useEffect, useState } from 'react';

const useSortModel = (dataTableName) => {
  const [sortSettings, setSortSettings] = useState({});

  useEffect(() => {
    setSortSettings(
      JSON.parse(window.localStorage.getItem('sortSettings') || null) || {},
    );
  }, [dataTableName]);

  const handleSortModelChange = ([nextSortModel]) => {
    const nextSortSettings = {
      ...sortSettings,
    };

    if (nextSortModel) {
      nextSortSettings[dataTableName] = nextSortModel;
    } else {
      delete nextSortSettings[dataTableName];
    }

    window.localStorage.setItem(
      'sortSettings',
      JSON.stringify(nextSortSettings),
    );
    setSortSettings(nextSortSettings);
  };

  return {
    sortModel: sortSettings[dataTableName],
    handleSortModelChange,
  };
};

export default useSortModel;
