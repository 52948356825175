import React, { useEffect } from 'react';
import { createGlobalStyle } from 'styled-components';
import { matchPath, useLocation } from 'react-router-dom';
import AppRoutes from './routes/AppRoutes';
import { appGlobalStyles, AppThemeProvider } from '@clatter/ui';
import {
  fetchFeatureFlag,
  fetchToolInfo,
  fetchToolsConfig,
  useAuth,
  fetchSystemProfile,
  sharedRoutes,
} from '@clatter/platform';
import { useDispatch } from 'react-redux';
import routes from './routes/routes';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const GlobalStyle = createGlobalStyle`
  ${appGlobalStyles};
`;

const App = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, checkSession } = useAuth();
  const location = useLocation();
  const isOnLogoutPage = !!matchPath(location.pathname, {
    path: routes.logout,
    exact: true,
  });
  const isOnSsoCallbackPage = !!matchPath(location.pathname, {
    path: sharedRoutes.ssoCallback,
    exact: true,
  });

  useEffect(() => {
    dispatch(fetchToolInfo());
  }, [dispatch]);

  // set auth session after page refresh
  useEffect(() => {
    if (!isOnLogoutPage) {
      checkSession();
    }
  }, [checkSession]);

  useEffect(() => {
    if (isAuthenticated && !isOnLogoutPage && !isOnSsoCallbackPage) {
      dispatch(fetchFeatureFlag());
      dispatch(fetchSystemProfile());
      dispatch(fetchToolsConfig());
    }
  }, [dispatch, isAuthenticated]);

  return (
    <AppThemeProvider>
      <GlobalStyle />
      <AppRoutes />
    </AppThemeProvider>
  );
};

export default App;
