import styled from 'styled-components';

const Label = styled.label`
  display: flex;
  align-items: center;
  line-height: ${({ theme }) => theme.form.label.lineHeight};
  font-size: ${({ theme }) => theme.form.label.fontSize};
  color: ${({ theme }) => theme.form.label.color};
  font-family: ${({ theme }) => theme.form.label.fontFamily};
`;

export default Label;
