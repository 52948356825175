import { merge } from 'lodash';

/**
 * THIS STYLES APPLIES ONLY TO MSM-NEXT PAGES
 */

export const themesNames = {
  uhc: 'uhc',
  blue: 'blue',
  default: 'default',
  optumEnterprise: 'Optum Enterprise',
  coley: 'coley',
};

const coleyThemeConsts = {
  primaryFontFamily: 'Maven Pro, sans-serif',
  primaryColor: '#002677',
  textColor: '#5A5A5A',
  textLinkColor: '#196ECF',
};

export const tertiaryColor = '#e6f0f7';
export const successColor = '#7CC522FF';
export const dangerColor = '#ff4d4d';

export const primaryColor = '#257789';
export const secondaryColor = '#ee8b4f';

export const textColor = '#454b49';

export const defaultBorderColor = '#d2d2d2';
export const defaultBorderRadius = '4px';

export const linkColor = '#454b49';
export const linkHoverColor = '#257789';

export const pageHeaderBackgroundColor = '#fff';
export const pageHeaderTextColor = '#454b49';
export const pageHeaderHeight = '64px';

export const inputFontSize = '14px';
export const inputHeight = '40px';
export const inputBorderColor = '#d2d2d2';
export const inputActiveBorderColor = '#257789';

export const COLOR_UHC_PRIMARY = '#002677';
export const COLOR_UHC_LINK = '#1b55de';
export const COLOR_UHC_SECTION = '#E5F8FB';
export const COLOR_WHITE = '#FFF';
export const COLOR_TEXT = '#333333';

export const FONT_SIZE_HEADER = '2rem';
export const FONT_FAMILY_HEADER_UHC = 'UHCSerifHeadline, serif';
export const FONT_FAMILY_PARAGRAPH_UHC = 'EnterpriseSans, Arial, sans-serif';
export const SIZE_SECTION_PADDING = '2rem 1rem';

const defaultTheme = {
  body: {
    backgroundColor: '#fff',
    fontFamily: 'optumsans-regular, sans-serif, Arial',
  },
  link: {
    color: '#454b49',
  },
  content: {
    card: {
      border: '1px solid lightgrey',
      header: {
        fontFamily: 'optumsans-bold, sans-serif, Arial',
        fontSize: '28.83px',
        fontWeight: 700,
        lineHeight: '32px',
        color: '#002677',
      },
      description: {
        fontFamily: 'optumsans-regular, sans-serif, Arial',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
      },
      button: {
        color: '#002677',
        fontFamily: 'optumsans-bold, sans-serif, Arial',
        fontSize: '16px',
        fontWeight: 700,
      },
    },
  },
  pageHeader: {
    height: '80px',
    backgroundColor: '#FBF9F4',
    borderBottom: 'none',
    alignItems: 'center',
    padding: '8px 40px 10px 0',
    mobilePadding: '0 0px 10px 0',
    maxWidth: '1300px',
    logo: {
      height: '48px',
      margin: '8px 0',
      transform: 'translateX(-5px) translateY(4px)',
    },
    branding: {
      position: {
        left: '5px',
      },
      text: {
        fontSize: '44px',
        color: '#5A5A5A',
        fontWeight: 400,
        fontFamily: 'optumsans-regular, sans-serif, Arial',
      },
    },
    separator: {
      color: '#5A5A5A',
      height: '38px',
      width: '1px',
      bottom: '10px',
    },
    nav: {
      color: '#5A5A5A',
      hoverColor: '#257789',
      padding: '0 0 13px 0',
      fontFamily: 'optumsans-bold, sans-serif, Arial',
      activeColor: '',
      activeTextDecoration: 'underline',
      fontWeight: 'inherit',
    },
  },
  section: {
    maxWidth: '1280px',
  },
  sectionHeader: {
    color: '#002677',
    fontSize: '28px',
    fontWeight: 500,
    padding: '0 0 20px',
    fontFamily: 'optumsans-bold, sans-serif, Arial',
    letterSpacing: '0.7px',
    lineHeight: '58px',
  },
  heroBanner: {
    padding: '0',
    backgroundColor: '#fff',
    minHeight: '390px',
    gradient: 'transparent',
    video: {
      maxWidth: '55%',
    },
    details: {
      header: {
        color: '#002677',
        fontFamily: 'optumsans-bold, sans-serif, Arial',
        fontSize: '46px',
        fontWeight: 700,
      },
      text: {
        color: '#5A5A5A',
        fontSize: '28px',
        fontWeight: 600,
        fontFamily: 'optumsans-bold, sans-serif, Arial',
      },
      backgroundColor: 'transparent',
      padding: '0 4px',
      maxWidth: '500px',
    },
  },
  optionalSection: {
    backgroundColor: '#D9F6FA',
    padding: '15px 0 22px',
    title: {
      color: '#002677',
      fontFamily: 'optumsans-bold, sans-serif, Arial',
      fontSize: '29px',
    },
    copy: {
      color: '#5A5A5A',
      fontFamily: 'optumsans-regular, sans-serif, Arial',
      fontSize: '14px',
      whiteSpace: 'break-spaces',
    },
    button: {
      right: '-55px',
      padding: '12px 30px',
      color: '#196ECF',
      backgroundColor: '#fff',
      border: '2px solid #196ECF',
      borderRadius: '50px',
      fontFamily: 'Arial, open-sans',
      fontWeight: 600,
      fontSize: '16px',
    },
  },
  videoGallery: {
    gap: '55px 16px',
    item: {
      padding: '4px',
      width: '120px',
      title: {
        fontSize: '19px',
        color: '#002677',
        fontFamily: 'optumsans-bold, sans-serif, Arial',
        fontWeight: 700,
        padding: '4px 0',
        lineHeight: '1.3',
      },
      description: {
        fontSize: '12px',
        color: '#002677',
      },
    },
  },
  linkResources: {
    border: '1px solid #DADBDC',
    padding: '20px 35px',
    boxShadow: 'none',
    vertical: {
      backgroundColor: '#fff',
    },
    item: {
      color: '#196ECF',
      fontSize: '15px',
      separator: 'none',
      padding: '15px 0px',
    },
  },
  form: {
    input: {
      height: '36px',
      border: '1px solid #333333',
      fontFamily: 'optumsans-regular, sans-serif, Arial',
      hover: {
        border: '1px solid #002677',
      },
    },
    select: {
      height: '36px',
      border: '1px solid #333333',
      fontFamily: 'optumsans-regular, sans-serif, Arial',
      hover: {
        border: '1px solid #002677',
      },
    },
    label: {
      fontFamily: 'optumsans-bold, sans-serif, Arial',
      fontSize: '14.22p',
      color: '#333333',
      lineHeight: '28px',
    },
  },
  table: {
    header: {
      th: {
        firstChildPadding: '0',
        padding: '0px 8px',
        fontSize: '14.83px',
        lineHeight: '32px',
        fontWeight: 700,
        fontFamily: 'optumsans-bold, sans-serif, Arial',
        color: '#002677',
      },
    },
    body: {
      tr: {
        borderBottom: '1px solid #D8D8D8',
      },
      td: {
        fontFamily: 'optumsans-regular, sans-serif, Arial',
        fontSize: '14.22px',
        color: '#333',
        lineHeight: '20px',
        padding: '2px 8px',
      },
    },
  },
  tableResources: {
    padding: '16px',
    boxShadow: 'none',
    border: '1px solid #DADBDC',
  },
  customerTeamSection: {
    sectionBackgroundColor: '#fff',
    sectionContent: {
      padding: '39px 0 48px',
    },
  },
  additionalVideosSection: {
    sectionContent: {
      padding: '69px 8px',
    },
  },
  featuredResourcesSection: {
    sectionBackgroundColor: '#FBF9F4',
    sectionContent: {
      padding: '16px 0 28px',
    },
  },
  featuredResourcesProspectSection: {
    sectionBackgroundColor: '#D9F6FA',
    sectionContent: {
      padding: '53px 0',
    },
  },
  featuredResourcesAdditionalSection: {
    sectionBackgroundColor: '#D9F6FA',
    sectionContent: {
      padding: '53px 0',
    },
  },
  customerTeam: {
    padding: '0',
    team: {
      padding: '5px 0',
    },
  },
  customerBio: {
    maxWidth: '420px',
    padding: '0',
    avatar: {
      size: '85px',
    },
    details: {
      textAlign: 'left',
      padding: '5px 0 0 26px',
      name: {
        fontFamily: 'Arial, sans-serif',
        fontSize: '20px',
        fontWeight: 700,
        color: '#002677',
        margin: '0',
      },
      title: {
        fontFamily: 'Arial, sans-serif',
        fontSize: '16px',
        fontWeight: 400,
        color: '#404040',
        lineHeight: '1.7',
      },
      email: {
        fontFamily: 'inherit',
        fontSize: 'inherit',
        fontWeight: 'inherit',
        color: 'inherit',
        margin: '16px 0',
      },
      phone: {
        fontFamily: 'inherit',
        fontSize: 'inherit',
        fontWeight: 'inherit',
        color: 'inherit',
        margin: '16px 0',
      },
      description: {
        fontFamily: 'inherit',
        fontSize: 'inherit',
        fontWeight: 'inherit',
        color: 'inherit',
        margin: '16px 0 0',
        lineHeight: '1.4',
      },
      bioClick: {
        fontFamily: 'optumsans-bold, sans-serif, Arial',
        color: '#196ECF',
        margin: '4px 0',
        fontSize: '1.05em',
        textDecoration: 'none',
        hoverTextDecoration: 'underline',
        fontWeight: 'inherit',
      },
    },
  },
  featuredResources: {
    columns: 'repeat(auto-fit, minmax(240px, 1fr))',
    padding: '23px 5px 18px',
    margin: '15px 0 0',
    gap: '80px',
    mobileGap: '30px',
    vertical: {
      backgroundColor: '#fff',
      padding: '10px 32px 42px',
      border: '1px solid #E0E0E0',
      borderRadius: '8px',
      gap: '28px',
      minWidth: '300px',
      maxWidth: '390px',
      contentPadding: '0 0 0 19px',
      title: {
        color: '#002677',
        fontSize: '28px',
        fontWeight: 700,
        fontFamily: 'optumsans-bold, sans-serif, Arial',
        margin: '0 0 14px',
      },
      item: {
        color: '#196ECF',
        fontSize: '16px',
        fontWeight: 'inherit',
        fontFamily: 'optumsans-bold, sans-serif, Arial',
        textDecoration: 'underline',
        separator: 'none',
        description: {
          color: 'inherit',
          fontSize: '14px',
          fontFamily: 'optumsans-regular, sans-serif, Arial',
          margin: '10px 0',
        },
      },
    },
    item: {
      color: '#002677',
      fontSize: '14px',
      fontWeight: 'inherit',
      textTransform: 'capitalize',
      fontFamily: 'optumsans-bold, sans-serif, Arial',
      separator: '1px solid #666',
      textDecoration: 'none',
      description: {
        color: 'inherit',
        fontSize: '14px',
        fontFamily: 'optumsans-regular, sans-serif, Arial',
        margin: '10px 0',
      },
    },
    moreResourcesWrap: {
      justifyContent: 'flex-end',
      paddingTop: '16px',
    },
  },
  contentExpander: {
    margin: '16px 0 0',
    padding: '0',
    button: {
      right: '-55px',
      padding: '12px 30px',
      color: '#196ECF',
      backgroundColor: '#fff',
      border: '2px solid #196ECF',
      borderRadius: '50px',
      fontFamily: 'Arial, open-sans',
      fontWeight: 600,
      fontSize: '16px',
    },
  },
  footerBrand: {
    backgroundColor: '#fff',
    padding: '20px 0 15px 0',
    borderTop: 'none',
  },
  footerClean: {
    padding: '15px 0',
    backgroundColor: '#fff',
    borderTop: '4px solid #f5f5f5',
    item: {
      color: '#636363',
      borderLeft: 'solid 1px #e0e0e0',
      padding: '0 10px',
      fontFamily: 'optumsans-regular, sans-serif, Arial',
      fontSize: '12.64px',
      lineHeight: '16px',
      letterSpacing: '0',
    },
  },
  contentRail: {
    sectionBackgroundColor: '#D9F6FA',
    card: {
      header: {
        fontSize: '2.1rem',
        color: '#002677',
        letterSpacing: '-0.5px',
        lineHeight: '32px',
        marginBottom: '20px',
        fontWeight: 600,
      },
      description: {
        fontSize: '16px',
        color: '#5a5a5a',
        lineHeight: '20px',
        marginBottom: '20px',
      },
    },
    link: {
      backgroundColor: '#d9f6fa',
      border: '2px solid #d9f6fa',
      borderRadius: '22px',
      fontSize: '16px',
      color: '#002677',
      padding: '10px 30px',
    },
  },
  rdwRender: {
    fontFamily: 'inherit',
    fontSize: 'inherit',
    color: 'inherit',
    fontWeight: 700,
  },
  landingPage: {
    header: {
      fontFamily: 'optumsans-bold, sans-serif, Arial',
      color: '#002677',
      fontWeight: 700,
      maxWidth: {
        xl: '100%',
        lg: '100%',
        md: '70%',
      },
      fontSize: {
        lg: '46.17px !important',
        md: '46.17px !important',
        sm: '38px !important',
        xs: '33px !important',
      },
      text: {
        fontSize: '20px',
        fontWeight: 300,
        color: '#5a5a5a',
      },
      link: {
        backgroundColor: '#ffffff',
        border: '2px solid #196ecf',
        fontSize: '16px',
        color: '#196ecf',
        borderRadius: '23px',
        fontWeight: 400,
        padding: '10px 25px',
        margin: '1.5rem 0',
      },
    },
    section: {
      fontSize: '28.83px',
      color: '#002677',
      padding: '10px 0 24px',
      fontWeight: 700,
      fontFamily: 'optumsans-bold, sans-serif, Arial',
    },
    card: {
      backgroundColor: '#fff',
      border: '1px solid #dadbdc',
      padding: '20px 18px',
      header: {
        fontSize: '18.25px',
        color: '#002677',
        fontFamily: 'optumsans-bold, sans-serif, Arial',
        fontWeight: 700,
      },
      text: {
        fontSize: '14.22px',
        color: '#5a5a5a',
        margin: '15px 0px 5px',
        fontFamily: 'optumsans-regular, sans-serif, Arial',
      },
      helpLink: {
        fontSize: '14px',
        color: '#196ecf',
        fontFamily: 'optumsans-bold, sans-serif, Arial',
        fontWeight: 700,
      },
    },
  },
  videosTable: {
    tableHeader: {
      fontFamily: 'optumsans-bold, sans-serif, Arial !important',
      fontSize: '28.83px !important',
      color: '#002677 !important',
    },
  },
  palette: {
    white: '#ffffff',
    textColor: textColor,
    primaryColor: primaryColor,
    secondaryColor: secondaryColor,
    error: '#fe615a',
    errorDimmed: '#ffb3b0',
    grey: '#ececec',
    darkGrey: '#8d8d8d',
    linkHoverColor: '#257789',
    linkColor: '#454b49',
    textLinkColor: '#215EFFFF',
    warning: '#F44336',
    danger: dangerColor,
    success: successColor,
    hoverBackgroundColor: '#25778917',
    buttonTextColor: '#262626',
  },
};

export const themes = {
  default: defaultTheme,
  'Optum Enterprise': merge({}, defaultTheme, {
    body: {
      backgroundColor: '#f4f7fc',
      fontFamily: 'optumsans-regular, sans-serif, Arial',
    },
    link: {
      color: '#006ca2',
    },
    pageHeader: {
      height: '64px',
      mobilePadding: '0',
      logo: {
        transform: 'translateX(5px) translateY(4px)',
      },
      separator: {
        height: '38px',
        width: '1px',
        bottom: '0',
      },
      branding: {
        text: {
          fontSize: '24px',
          color: '#454b49',
          fontWeight: 700,
          fontFamily: 'inherit',
        },
      },
    },
    videoGallery: {
      item: {
        title: {
          fontSize: '14px',
          color: '#454b49',
        },
        description: {
          fontSize: '12px',
          color: '#002677',
        },
      },
    },
    heroBanner: {
      padding: 0,
      minHeight: '390px',
      gradient: 'transparent',
      backgroundColor: '#fff',
      video: {
        maxWidth: '55%',
      },
      details: {
        color: '#454b49',
        maxWidth: '400px',
        header: {
          fontFamily: 'optumsans-bold, sans-serif, Arial',
          color: '#002677',
          fontSize: '32px',
        },
        text: {
          fontFamily: 'optumsans-regular, sans-serif, Arial',
          color: '#5a5a5a',
          fontSize: '18px',
          fontWeight: 600,
        },
        backgroundColor: 'transparent',
        padding: '0',
      },
    },
    optionalSection: {
      backgroundColor: 'inherit',
      padding: 'inherit',
      title: {
        color: 'inherit',
        fontFamily: 'inherit',
        fontSize: 'inherit',
      },
      copy: {
        color: 'inherit',
        fontFamily: 'inherit',
        fontSize: 'inherit',
        whiteSpace: 'inherit',
      },
    },
    form: {
      input: {
        height: '40px',
        border: '1px solid #333333',
        fontFamily: 'optumsans-regular, sans-serif, Arial',
        hover: {
          border: '1px solid #1890ff',
        },
      },
      select: {
        height: '40px',
        border: '1px solid #333333',
        fontFamily: 'optumsans-regular, sans-serif, Arial',
        hover: {
          border: '1px solid #1890ff',
        },
      },
      label: {
        fontFamily: 'inherit',
        fontSize: '14px',
        color: '#000',
        lineHeight: '32px',
      },
    },
    table: {
      header: {
        th: {
          firstChildPadding: '12px',
          padding: '8px',
          fontSize: '18px',
          lineHeight: '24px',
          fontWeight: 700,
          fontFamily: 'inherit',
          color: '#000000',
        },
      },
      body: {
        tr: {
          borderBottom: '1px solid #d2d2d2',
        },
        td: {
          fontFamily: 'inherit',
          fontSize: '14px',
          color: '#000',
          lineHeight: '20px',
          padding: '4px 8px',
        },
      },
    },
    sectionHeader: {
      color: '#0093d1',
      fontSize: '25px',
      fontWeight: 700,
      padding: '0 0 24px',
      fontFamily: 'inherit',
      letterSpacing: 'unset',
      lineHeight: 1.4,
    },
    tableResources: {
      padding: '16px',
      boxShadow: 'rgb(0 0 0 / 14%) 4px 8px 20px 0px',
      border: 'none',
    },
    customerTeamSection: {
      sectionBackgroundColor: '#efefee',
      sectionContent: {
        padding: '24px 8px',
      },
    },
    additionalVideosSection: {
      sectionContent: {
        padding: '24px 8px',
      },
    },
    featuredResourcesSection: {
      sectionBackgroundColor: 'transparent',
      sectionContent: {
        padding: '24px 8px',
      },
    },
    customerTeam: {
      padding: '32px 0',
      team: {
        padding: '32px 0',
      },
    },
    customerBio: {
      maxWidth: '100%',
      padding: 'auto',
      avatar: {
        size: '85px',
      },
      details: {
        textAlign: 'left',
        padding: '8px 0 0 12px',
        name: {
          fontFamily: 'inherit',
          fontSize: '19px',
          fontWeight: 700,
          color: 'inherit',
          margin: '0',
        },
        title: {
          fontFamily: 'inherit',
          fontSize: '14px',
          fontWeight: 'inherit',
          color: 'inherit',
          lineHeight: '1.2',
        },
        email: {
          fontFamily: 'inherit',
          fontSize: 'inherit',
          fontWeight: 'inherit',
          color: 'inherit',
        },
        phone: {
          fontFamily: 'inherit',
          fontSize: 'inherit',
          fontWeight: 'inherit',
          color: 'inherit',
        },
        description: {
          fontFamily: 'inherit',
          fontSize: 'inherit',
          fontWeight: 'inherit',
          color: 'inherit',
          margin: '16px 0 0',
          lineHeight: '1.4',
        },
        bioClick: {
          fontFamily: 'inherit',
          color: '#316bbe',
          margin: '8px 0 0',
          textDecoration: 'underline',
          hoverTextDecoration: 'underline',
        },
      },
    },
    featuredResources: {
      padding: '24px 0',
      sectionBackgroundColor: '',
      columns: 'repeat(auto-fit, minmax(240px, 1fr))',
      margin: 'none',
      gap: '24px',
      mobileGap: '24px',
      vertical: {
        backgroundColor: 'transparent',
        padding: 0,
        border: 'none',
        borderRadius: 0,
        gap: '16px',
        minWidth: '300px',
        maxWidth: '460px',
        contentPadding: '0',
        item: {
          color: '#4a71b0',
          fontSize: '16px',
          fontWeight: 600,
          fontFamily: 'inherit',
          textDecoration: 'underline',
          separator: '',
          description: {
            color: '#5a5a5a',
            fontSize: '16px',
            fontFamily: 'inherit',
            margin: '10px 0',
          },
        },
      },
      item: {
        color: '#4a71b0',
        fontSize: '16px',
        fontWeight: 600,
        separator: 'none',
        fontFamily: 'inherit',
        textDecoration: 'underline',
        textTransform: 'capitalize',
        description: {
          fontSize: '16px',
          color: '#5a5a5a',
          fontFamily: 'inherit',
          margin: '10px 0',
        },
      },
      moreResourcesWrap: {
        justifyContent: 'flex-end',
        paddingTop: '16px',
      },
    },
    contentExpander: {
      margin: '16px 0 0',
      padding: '0',
      button: {
        right: '0',
        padding: '12px 24px',
        color: '#fff',
        backgroundColor: '#316bbe',
        border: '1px solid #316bbe',
        borderRadius: '50px',
        fontFamily: 'Arial, open-sans',
        fontWeight: 600,
        fontSize: '16px',
      },
    },
  }),
  blue: merge({}, defaultTheme, {
    body: {
      backgroundColor: '#fff',
      fontFamily: 'Arial, sans-serif',
    },
    pageHeader: {
      backgroundColor: '#D9F6FA',
    },
    optionalSection: {
      backgroundColor: '#002677',
      padding: '15px 0 22px',
      title: {
        color: '#FFFFFF',
      },
      copy: {
        color: '#FFFFFF',
      },
    },
    featuredResources: {
      item: {
        color: '#196ECF',
      },
    },
    videoGallery: {
      gap: '55px 16px',
      item: {
        title: {
          fontSize: '20px',
        },
      },
    },
  }),
  uhc: merge({}, defaultTheme, {
    body: {
      backgroundColor: '#f4f7fc',
      fontFamily: FONT_FAMILY_PARAGRAPH_UHC,
      color: '#333',
    },
    downloadDecks: {
    },
    pageHeader: {
      height: '64px',
      backgroundColor: '#fff',
      borderBottom: 'none',
      alignItems: 'baseline',
      padding: '8px 40px 8px 0',
      mobilePadding: '0',
      logo: {
        height: '48px',
        margin: '8px 0',
        transform: 'none',
      },
      separator: {
        color: '#8092bb',
        height: '48px',
        width: '1px',
        bottom: '0',
      },
      branding: {
        position: {
          left: '0',
        },
        text: {
          fontSize: '24px',
          color: '#454b49',
          fontWeight: 700,
          fontFamily: 'inherit',
        },
      },
      nav: {
        color: '#454b49',
        hoverColor: '#257789',
        padding: '0',
        fontFamily: 'inherit',
      },
    },
    sectionHeader: {
      color: '#002677',
      fontFamily: FONT_FAMILY_HEADER_UHC,
      fontSize: '25px',
      fontWeight: 700,
      padding: '0 0 24px',
      letterSpacing: 'unset',
      lineHeight: 1.4,
    },
    heroBanner: {
      backgroundColor: '#ffffff',
      gradient: '',
      padding: '64px 0',
      minHeight: '100px',
      video: {
        maxWidth: '500px',
      },
      details: {
        backgroundColor: '#fff',
        padding: '24px',
        maxWidth: '400px',
        header: {
          fontSize: '32px',
          color: '#002677',
          fontFamily: FONT_FAMILY_HEADER_UHC,
        },
        text: {
          color: '#333',
          fontSize: '14px',
          fontWeight: 400,
          fontFamily: FONT_FAMILY_PARAGRAPH_UHC,
        },
      },
    },
    optionalSection: {
      backgroundColor: 'inherit',
      padding: 'inherit',
      title: {
        color: 'inherit',
        fontFamily: 'inherit',
        fontSize: 'inherit',
      },
      copy: {
        color: 'inherit',
        fontFamily: 'inherit',
        fontSize: 'inherit',
        whiteSpace: 'inherit',
      },
    },
    videoGallery: {
      sectionBackgroundColor: '#fff',
      gap: '16px 0',
      item: {
        title: {
          fontSize: '14px',
          color: '#002677',
          fontWeight: 400,
          fontFamily: FONT_FAMILY_PARAGRAPH_UHC,
        },
        description: {
          fontSize: '12px',
          color: '#333',
        },
      },
    },
    form: {
      input: {
        height: '40px',
        border: '1px solid #333333',
        fontFamily: FONT_FAMILY_PARAGRAPH_UHC,
        hover: {
          border: '1px solid #002677',
        },
      },
      select: {
        height: '40px',
        border: '1px solid #333333',
        fontFamily: FONT_FAMILY_PARAGRAPH_UHC,
        hover: {
          border: '1px solid #002677',
        },
      },
      label: {
        fontFamily: 'inherit',
        fontSize: '14px',
        color: '#000',
        lineHeight: '32px',
      },
    },
    table: {
      header: {
        th: {
          firstChildPadding: '12px',
          padding: '8px',
          fontSize: '18px',
          lineHeight: '24px',
          fontWeight: 700,
          fontFamily: 'inherit',
          color: '#000000',
        },
      },
      body: {
        tr: {
          borderBottom: '1px solid #d2d2d2',
        },
        td: {
          fontFamily: 'inherit',
          fontSize: '14px',
          color: '#000',
          lineHeight: '20px',
          padding: '4px 8px',
        },
      },
    },
    customerTeamSection: {
      sectionBackgroundColor: '#efefee',
      sectionContent: {
        padding: '24px 8px',
      },
    },
    additionalVideosSection: {
      sectionContent: {
        padding: '24px 8px',
      },
    },
    featuredResourcesSection: {
      sectionBackgroundColor: 'transparent',
      sectionContent: {
        padding: '24px 8px',
      },
    },
    featuredResourcesProspectSection: {
      sectionBackgroundColor: '#f4f7fc',
      sectionContent: {
        padding: '24px 8px',
      },
    },
    customerTeam: {
      padding: '32px 0',
      team: {
        padding: '32px 0',
      },
    },
    customerBio: {
      maxWidth: '100%',
      padding: 'auto',
      avatar: {
        size: '85px',
      },
      details: {
        textAlign: 'left',
        padding: '8px 0 0 12px',
        name: {
          fontFamily: FONT_FAMILY_PARAGRAPH_UHC,
          fontSize: '19px',
          fontWeight: 700,
          color: 'inherit',
          margin: '0',
        },
        title: {
          fontFamily: FONT_FAMILY_PARAGRAPH_UHC,
          fontSize: '14px',
          fontWeight: 'inherit',
          color: 'inherit',
          lineHeight: '1.2',
        },
        email: {
          fontFamily: FONT_FAMILY_PARAGRAPH_UHC,
          fontSize: 'inherit',
          fontWeight: 'inherit',
          color: 'inherit',
        },
        phone: {
          fontFamily: FONT_FAMILY_PARAGRAPH_UHC,
          fontSize: 'inherit',
          fontWeight: 'inherit',
          color: 'inherit',
        },
        description: {
          fontFamily: FONT_FAMILY_PARAGRAPH_UHC,
          fontSize: 'inherit',
          fontWeight: 'inherit',
          color: 'inherit',
          margin: '16px 0 0',
          lineHeight: '1.4',
        },
        bioClick: {
          fontFamily: FONT_FAMILY_PARAGRAPH_UHC,
          color: '#316bbe',
          margin: '8px 0 0',
          textDecoration: 'underline',
          hoverTextDecoration: 'underline',
        },
      },
    },
    tableResources: {
      padding: '16px',
      boxShadow: 'rgb(0 0 0 / 14%) 4px 8px 20px 0px',
      border: 'none',
    },
    featuredResources: {
      padding: '24px 0',
      sectionBackgroundColor: '',
      columns: 'repeat(auto-fit, minmax(240px, 1fr))',
      margin: 'none',
      gap: '24px',
      mobileGap: '24px',
      vertical: {
        backgroundColor: '#f4f4f4',
        padding: '24px',
        border: 'none',
        borderRadius: 0,
        gap: '16px',
        minWidth: '300px',
        maxWidth: '460px',
        contentPadding: '0',
        title: {
          color: '#002677',
          fontSize: '28px',
          fontWeight: 700,
          fontFamily: FONT_FAMILY_HEADER_UHC,
          margin: '0 0 14px',
        },
        item: {
          color: '#196ecf',
          fontSize: '16px',
          fontWeight: 200,
          textDecoration: 'none',
          separator: 'none',
          fontFamily: FONT_FAMILY_PARAGRAPH_UHC,
          description: {
            color: 'inherit',
            fontSize: 'inherit',
            fontFamily: 'inherit',
            margin: '10px 0',
          },
        },
      },
      header: {
        color: '',
        fontFamily: '',
        fontSize: '',
      },
      item: {
        color: '#196ecf',
        fontSize: '16px',
        separator: 'none',
        fontWeight: 500,
        textTransform: 'capitalize',
        fontFamily: 'inherit',
        textDecoration: 'none',
        description: {
          color: 'inherit',
          fontSize: 'inherit',
          fontFamily: 'inherit',
          margin: '10px 0',
        },
      },
      moreResourcesWrap: {
        justifyContent: 'flex-end',
        paddingTop: '16px',
      },
    },
    footerBrand: {
      backgroundColor: '#E4F7FA',
      padding: '0',
    },
    contentExpander: {
      margin: '16px 0 0',
      padding: '0',
      button: {
        right: '0',
        padding: '12px 24px',
        color: '#fff',
        backgroundColor: '#316bbe',
        border: '1px solid #316bbe',
        borderRadius: '50px',
        fontFamily: FONT_FAMILY_PARAGRAPH_UHC,
        fontWeight: 600,
        fontSize: '16px',
      },
    },
    videosTable: {
      tableHeader: {
        fontFamily: `${FONT_FAMILY_HEADER_UHC} !important`,
        fontSize: '28.83px !important',
        color: '#002677 !important',
      },
    },
    challengeSection: {
      sectionBackgroundColor: COLOR_WHITE,
      sectionContent: {
        padding: '53px 0',
      }
    },
    howWeAssistedSection: {
      sectionBackgroundColor: COLOR_UHC_SECTION,
    },
    howWeAssistedBlock: {
      paddingMd: '53px 5rem 53px 0',
      paddingXs: '53px 0',
      imageBackgroundColor: COLOR_UHC_PRIMARY,
      headerColor: COLOR_UHC_PRIMARY,
      item: {
        padding: '1.3rem 0',
        borderBottom: `1px solid ${COLOR_UHC_PRIMARY}`,
      }
    },
    performanceSection: {
      sectionBackgroundColor: COLOR_WHITE,
      sectionContent: {
        padding: '53px 0',
      }
    },
    performanceBlock: {
      gap: '10rem',
      rowGap: '4rem',
      item: {
        title: {
          fontSize: '3.5rem',
          color: COLOR_UHC_PRIMARY,
          fontFamily: FONT_FAMILY_HEADER_UHC,
          marginBottom: '0.02rem',
        }
      }
    },
    text: {
      header: {
        fontSize: FONT_SIZE_HEADER,
        fontFamily: FONT_FAMILY_HEADER_UHC,
        color: COLOR_UHC_PRIMARY
      },
      paragraph: {
        fontFamily: FONT_FAMILY_PARAGRAPH_UHC,
        fontSize: '1rem',
        color: COLOR_TEXT,
      },
      link: {
        color: COLOR_UHC_LINK,
      }
    },
    clientLeadsSection: {
      sectionBackgroundColor: COLOR_UHC_SECTION,
    }

  }),
  [themesNames.coley]: merge({}, defaultTheme, {
    body: {
      fontFamily: coleyThemeConsts.primaryFontFamily,
      color: '#333',
    },
    pageHeader: {
      padding: '18px 40px 10px 0',
      borderBottom: '8px solid #008F76',
      backgroundColor: '#fff',
      logo: {
        height: '48px',
        margin: '8px 0',
        transform: 'translateY(-5px)',
      },
      branding: {
        text: {
          fontSize: '40px',
          fontFamily: coleyThemeConsts.primaryFontFamily,
        },
      },
      nav: {
        color: '#5A5A5A',
        hoverColor: '#257789',
        fontFamily: coleyThemeConsts.primaryFontFamily,
        fontWeight: 700,
      },
    },
    heroBanner: {
      backgroundColor: '#BEDAC5',
      details: {
        header: {
          color: coleyThemeConsts.primaryColor,
          fontFamily: coleyThemeConsts.primaryFontFamily,
          fontSize: '46px',
          fontWeight: 700,
        },
        text: {
          color: coleyThemeConsts.textColor,
          fontWeight: 300,
          fontFamily: coleyThemeConsts.primaryFontFamily,
        },
      },
    },
    optionalSection: {
      title: {
        color: coleyThemeConsts.primaryColor,
        fontFamily: coleyThemeConsts.primaryFontFamily,
      },
      copy: {
        color: coleyThemeConsts.textColor,
        fontFamily: coleyThemeConsts.primaryFontFamily,
      },
    },
    sectionHeader: {
      color: coleyThemeConsts.primaryColor,
      fontSize: '28px',
      fontWeight: 700,
      fontFamily: coleyThemeConsts.primaryFontFamily,
    },
    featuredResourcesSection: {
      sectionBackgroundColor: '#fff',
    },
    featuredResourcesProspectSection: {
      sectionBackgroundColor: '#fff',
    },
    customerTeamSection: {
      sectionBackgroundColor: '#F2F2F2',
    },
    customerBio: {
      details: {
        name: {
          color: coleyThemeConsts.primaryColor,
        },
        bioClick: {
          fontFamily: coleyThemeConsts.primaryFontFamily,
          color: coleyThemeConsts.textLinkColor,
          fontWeight: 700,
        },
      },
    },
    featuredResources: {
      vertical: {
        backgroundColor: '#F2F2F2',
        gap: '20px',
        border: 'none',
        borderRadius: '4px',
        title: {
          color: coleyThemeConsts.primaryColor,
          fontWeight: 900,
          fontFamily: coleyThemeConsts.primaryFontFamily,
        },
        item: {
          color: coleyThemeConsts.textLinkColor,
          fontFamily: coleyThemeConsts.primaryFontFamily,
          fontWeight: 700,
          textDecoration: 'none',
          description: {
            fontFamily: coleyThemeConsts.primaryFontFamily,
          },
        },
      },
      item: {
        color: coleyThemeConsts.textLinkColor,
        fontSize: '16px',
        fontWeight: 700,
        fontFamily: coleyThemeConsts.primaryFontFamily,
        textDecoration: 'underline',
        description: {
          fontFamily: coleyThemeConsts.primaryFontFamily,
        },
      },
    },
    videoGallery: {
      item: {
        title: {
          color: coleyThemeConsts.primaryColor,
          fontFamily: coleyThemeConsts.primaryFontFamily,
          fontWeight: 700,
          padding: '4px 0',
        },
      },
    },
    footerBrand: {
      borderTop: '8px solid #008F76',
    },
    contentExpander: {
      margin: '16px 0 0',
      padding: '0',
      button: {
        color: coleyThemeConsts.textLinkColor,
        border: `2px solid ${coleyThemeConsts.textLinkColor}`,
      },
    },
    videosTable: {
      tableHeader: {
        fontFamily: `${coleyThemeConsts.primaryFontFamily} !important`,
        color: `${coleyThemeConsts.primaryColor} !important`,
      },
    },
    landingPage: {
      header: {
        fontFamily: coleyThemeConsts.primaryFontFamily,
        maxWidth: {
          xl: '80%',
          lg: '100%',
          md: '65%',
        },
        fontSize: {
          lg: '40px !important',
          md: '35px !important',
          sm: '35px !important',
          xs: '32px !important',
        },
        text: {
          color: coleyThemeConsts.textColor,
        },
        link: {
          color: coleyThemeConsts.textLinkColor,
          margin: '0.5em 0 2.5em',
        },
      },
      section: {
        color: coleyThemeConsts.primaryColor,
        fontFamily: coleyThemeConsts.primaryFontFamily,
      },
      card: {
        border: 'none',
        header: {
          color: coleyThemeConsts.primaryColor,
          fontFamily: coleyThemeConsts.primaryFontFamily,
        },
        text: {
          color: coleyThemeConsts.textColor,
          fontFamily: coleyThemeConsts.primaryFontFamily,
        },
        helpLink: {
          color: coleyThemeConsts.textLinkColor,
          fontFamily: coleyThemeConsts.primaryFontFamily,
        },
      },
    },
  }),
};

export const getPageTheme = (theme = 'default') => {
  return themes[theme];
};
