import { Loader, PageContent } from '@clatter/ui';
import { useSelector } from 'react-redux';
import {AnalyticsView as SharedAnalyticsView, requestStatus, usePageTitleHeader} from '@clatter/platform';
import Page from '../../components/Page/Page';
import routes from "../../routes/routes";

const AnalyticsView = () => {
  const isLoading = useSelector(({ platform }) => platform.toolInfo.loadingStatus) === requestStatus.pending;

  const { renderPageTitleHeader } = usePageTitleHeader({
    pageTitle: "Analytics",
    routes: routes,
  });

  return (
    <Page minHeight="10px" padding="0" backgroundColor="rgb(248,250,252)" withPageContent={false}>
      {isLoading && <Loader />}

      <PageContent minHeight="0" padding="32px 84px 0 84px">
        { renderPageTitleHeader() }
      </PageContent>

      <SharedAnalyticsView />
    </Page>
  );
};

export default AnalyticsView;
