import { requestStatus } from '@clatter/platform';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  isRejectedWithValue,
} from '@reduxjs/toolkit';
import templatesApi from '../api/templates.api';

export const PAGETEMPLATES_FEATURE_KEY = 'pageTemplates';
export const pageTemplatesAdapter = createEntityAdapter();

const collection = 'pageTemplates';

export const fetchPageTemplates = createAsyncThunk(
  `${collection}/fetchStatus`,
  async () => {
    try {
      return templatesApi.fetchTemplates();
    } catch (error) {
      return isRejectedWithValue({
        ...error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const updatePageTemplate = createAsyncThunk(
  'pageTemplates/putStatus',
  async ({ templateId, updatedTemplate }) => {
    try {
      const response = await templatesApi.updateTemplate({ templateId, updatedTemplate });

      return mapApiToStore(response.data);
    } catch (error) {
      return isRejectedWithValue({
        ...error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const deletePageTemplate = createAsyncThunk(
  'pageTemplates/deleteStatus',
  async (templateId) => {
    try {
      const response = await templatesApi.deleteTemplate(templateId);

      return mapApiToStore(response.data);
    } catch (error) {
      return isRejectedWithValue({
        ...error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const initialPageTemplatesState = pageTemplatesAdapter.getInitialState({
  loadingStatus: requestStatus.initial,
  error: null,
});

export const mapApiToStore = (item) => ({
  id: item.id,
  ...item.attributes,
  icon_uri: item.attributes?.icon_uri?.data !== null ? {
    id: item.attributes?.icon_uri?.data?.id,
    asset: {
      id: item.attributes?.icon_uri?.data?.attributes?.asset?.data?.id,
      ...item.attributes?.icon_uri?.data?.attributes?.asset?.data?.attributes,
    },
  } : null,
});

export const pageTemplatesSlice = createSlice({
  name: PAGETEMPLATES_FEATURE_KEY,
  initialState: initialPageTemplatesState,
  reducers: {
    add: pageTemplatesAdapter.addOne,
    remove: pageTemplatesAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPageTemplates.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(fetchPageTemplates.fulfilled, (state, action) => {
        pageTemplatesAdapter.setAll(state, (action.payload.data || []).map(mapApiToStore));
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(fetchPageTemplates.rejected, (state, action) => {
        state.loadingStatus = requestStatus.error;
        state.error = action.error.message;
      })
      .addCase(updatePageTemplate.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(updatePageTemplate.fulfilled, (state, action) => {
        pageTemplatesAdapter.setOne(state, action.payload);
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(updatePageTemplate.rejected, (state, action) => {
        state.loadingStatus = requestStatus.error;
        state.error = action.error.message;
      })
      .addCase(deletePageTemplate.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(deletePageTemplate.fulfilled, (state, action) => {
        pageTemplatesAdapter.removeOne(state, action.payload?.id);
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(deletePageTemplate.rejected, (state, action) => {
        state.loadingStatus = requestStatus.error;
        state.error = action.error.message;
      });
  },
});

export const pageTemplatesReducer = pageTemplatesSlice.reducer;
export const pageTemplatesActions = pageTemplatesSlice.actions;

const { selectAll, selectEntities } = pageTemplatesAdapter.getSelectors();

export const getPageTemplatesState = (rootState) =>
  rootState[PAGETEMPLATES_FEATURE_KEY];

export const selectAllPageTemplates = createSelector(
  getPageTemplatesState,
  selectAll,
);
export const selectPageTemplatesEntities = createSelector(
  getPageTemplatesState,
  selectEntities,
);

export const selectPageTemplatesLoadingStatus = createSelector(
  getPageTemplatesState,
  (state) => state.loadingStatus,
);
