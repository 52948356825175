import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { appGlobalStyles } from '../../config/appGlobalStyles';

const GlobalStyle = createGlobalStyle`
  ${appGlobalStyles}
`;

const Page = ({ children }) => (
  <>
    <GlobalStyle />
    {children}
  </>
);

export default Page;
