import { groupBy, orderBy } from 'lodash';
import { filterDataGridTableRowByTableColumns } from '@clatter/platform';
import {
  DISPLAY_TYPE_PAGE,
  DISPLAY_TYPE_SITE,
} from './MicrositesDetailFilters';

const sortRows = (data, filters) => {
  const { sort_model } = filters;
  // sort model undefined when clicking on currently sorted column name
  if (!sort_model) {
    return data;
  }

  const sortByFields = [sort_model.field];
  const orderDirections = [sort_model.sort];

  const isGroupByPage = filters?.display_type === DISPLAY_TYPE_PAGE;
  if (isGroupByPage) {
    sortByFields.unshift('site');
    orderDirections.unshift('asc');
  }

  return orderBy(data, sortByFields, orderDirections);
};

const groupByDisplay = (data, filters) => {
  const isGroupBySite = filters?.display_type === DISPLAY_TYPE_SITE;
  const sitePositionGroups = groupBy(data, 'site');
  const sitePositionKeys = Object.keys(sitePositionGroups);

  if (isGroupBySite) {
    return sitePositionKeys.map((siteName) => {
      const row = sitePositionGroups[siteName][0];

      return {
        uuid: row.uuid,
        created_by: row.created_by,
        created_at: row.created_at,
        site: row.site,
        pages: sitePositionGroups[siteName],
        published: row.published,
      };
    });
  }

  return data.map((row) => ({
    uuid: row.uuid,
    created_by: row.created_by,
    created_at: row.created_at,
    site: row.site,
    page: row.page,
    template: row.template,
    published: row.published,
    isOdd: sitePositionKeys.indexOf(row.site) % 2,
  }));
};
export const micrositesDetailFilter = ({
  data = [],
  filters,
  tableColumns = [],
}) => {
  const sortedData = sortRows(data, filters);
  const groupedData = groupByDisplay(sortedData, filters);

  if (filters?.search_query) {
    return groupedData.filter((row) =>
      filterDataGridTableRowByTableColumns(
        row,
        tableColumns,
        filters.search_query,
      ),
    );
  }

  return groupedData;
};
