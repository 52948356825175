import { orderBy } from 'lodash';
import { createApi } from '@reduxjs/toolkit/query/react';
import { userResponseSchema } from '@clatter/platform';
import { RTK_CACHE_TIMEOUT, userRolesMap } from '../constants';
import { axiosApiV2BaseQuery } from './baseQueries';

export const usersService = createApi({
  reducerPath: 'usersService',
  keepUnusedDataFor: RTK_CACHE_TIMEOUT,
  baseQuery: axiosApiV2BaseQuery(),
  endpoints: (builder) => ({
    getMSMUsersOwnershipList: builder.query({
      query: () => {
        const query = Object.values(userRolesMap)
          .map((userRole) => `has_any_roles[]=${userRole}`)
          .join('&');
        return `/user?${query}`;
      },
      transformResponse: (response) => {
        // let's validate response
        const { error, value } = userResponseSchema.validate(response);

        if (error) {
          console.error('Validation error:', error.details); // leaving this for debug in the sentry
          throw new Error('Users response is not valid! Please contact the administrator.');
        }

        // map response to the desired format
        return orderBy(
          value.data,
          [user => user.first_name.toLowerCase(), user => user.last_name.toLowerCase()]
        ).map((user) => ({
          label: `${user.first_name} ${user.last_name} - ${user.email}`,
          value: user.email,
        }));
      },
    }),
  }),
});

export const { useGetMSMUsersOwnershipListQuery } = usersService;
