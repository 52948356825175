import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Loader  } from '@clatter/ui';
import { useAuth, usePageTitleHeader } from '@clatter/platform';
import {
  fetchPages,
  fetchPageTemplates,
  fetchCobrandLogos,
  fetchHeroImages,
  fetchMicrosites,
  selectSortedCoBrandLogos,
} from '../../store';
import SiteMaker from '../../components/SiteMaker/SiteMaker';
import SiteMakerContentHead from '../../components/SiteMaker/SiteMakerContentHead';
import { heroImageFromStore, pageFromStore } from '../fromStore';
import SiteSettingsForm from '../../components/SiteSettingsForm/SiteSettingsForm';
import { selectAllPageTemplates } from '../../store/page-templates.slice';
import useMsmPageTitle from "../../hooks/useMsmPageTitle";
import { selectPagesEntities } from '../../store/pages.slice';
import routes from "../../routes/routes";

const SiteSettings = () => {
  // set page title for GA4
  useMsmPageTitle()
  const { siteId } = useParams();
  const { user, isLoading } = useAuth();

  const isNew = useSelector((state) => state.microsites.isCreatingMicrosite)

  if (isLoading) {
    return <div>Loading authorization...</div>;
  }

  const dispatch = useDispatch();

  useEffect(() => {
    // not site/page dependent fetches
    dispatch(fetchPageTemplates());
    dispatch(fetchHeroImages());
    dispatch(fetchCobrandLogos());

    if (!siteId) {
      return false;
    }

    (async () => {
      const microsites = await dispatch(fetchMicrosites({ user: user, micrositeId: siteId }));
      const micrositePagesIds = (microsites?.payload?.data?.[0]?.attributes?.pages?.data || []).map(page => page?.id);

      dispatch(fetchPages({ pagesIds: micrositePagesIds }));
    })();
  }, [siteId]);

  const loading = useSelector(
    (state) =>
      state.pageTemplates.loadingStatus === 'loading' ||
      state.microsites.loadingStatus === 'loading' ||
      state.pages.loadingStatus === 'loading' ||
      state.heroImages.loadingStatus === 'loading' ||
      state.cobrandLogos.loadingStatus === 'loading',
  );

  const cobrandLogos = useSelector(selectSortedCoBrandLogos);
  const pageEntities = useSelector(selectPagesEntities);
  const pageTemplates = useSelector(selectAllPageTemplates);

  const heroImages = useSelector((state) =>
    Object.values(state.heroImages.entities).map(heroImageFromStore),
  );

  const currentMicrosite = useSelector((state) => {
    if (!siteId || !state.microsites.entities[siteId]) {
      return;
    }

    return {
      ...state.microsites.entities[siteId],
      pages: state.microsites.entities[siteId].pages?.map((page) =>
        pageFromStore(siteId, page, pageTemplates, pageEntities[page.id]),
      ),
    };
  });

  const { renderPageTitleHeader } = usePageTitleHeader({
    currentMicrosite: currentMicrosite,
    pageTitle: isNew ? 'Create a Microsite' : 'Edit Microsite',
    routes: routes,
  });

  if (!loading && (!currentMicrosite || !currentMicrosite.pages)) {
    return <p>Couldn't load current page</p>;
  }

  return (
    <SiteMaker site={currentMicrosite}>
      {loading && <Loader />}

      { renderPageTitleHeader() }

      <SiteMakerContentHead
        subhead="Please complete the following steps and click the “Save and continue” button below."
        hideButtons={true}
      />

      <SiteSettingsForm
        loading={loading}
        cobrandLogos={cobrandLogos}
        heroImages={heroImages}
        microsite={currentMicrosite}
        pages={currentMicrosite?.pages || []}
        pageTemplates={pageTemplates}
      />
    </SiteMaker>
  );
};

export default SiteSettings;
