import React, { useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { VideoGalleryIframe, VideoPreviewDialog } from '@clatter/ui';

const StyledVideoGallery = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: minmax(
    ${(props) => `${props.videoSize.height + 48}px`},
    auto
  );
  gap: ${({ theme }) => theme.videoGallery?.gap};
  width: auto;
  height: auto;
  overflow-y: auto;
  justify-content: center;
  overflow-x: hidden;

  @media (min-width: 1060px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1400px) {
    grid-template-columns: ${(props) =>
      `repeat(${props.numOfColumns ?? 3}, 1fr)`};
  }

  .video-gallery-item {
    margin-bottom: auto;

    .video {
      width: ${(props) => `${props.videoSize.width}px`};
      height: ${(props) => `${props.videoSize.height}px`};
      position: relative;
      background-color: #000;

      iframe {
        width: 100%;
        height: 100%;
      }
    }

    .preview-video-button {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      svg {
        font-size: 18px;
        border-radius: 8px;
        background-color: rgba(0, 0, 0, 0.9);
        fill: #fff;
        width: 48px;
        height: 36px;
      }
    }

    .video-details {
      padding: ${({ theme }) => theme.videoGallery?.item.padding};
      max-width: ${(props) => `${props.videoSize.width}px`};

      .video-title {
        font-family: ${({ theme }) =>
          theme.videoGallery?.item.title.fontFamily};
        font-weight: ${({ theme }) =>
          theme.videoGallery?.item.title.fontWeight};
        font-size: ${({ theme }) => theme.videoGallery?.item.title.fontSize};
        line-height: ${({ theme }) =>
          theme.videoGallery?.item.title.lineHeight};
        color: ${({ theme }) => theme.videoGallery?.item.title.color};
        padding: ${({ theme }) => theme.videoGallery?.item.title.padding};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .video-description {
        font-size: ${({ theme }) =>
          theme.videoGallery?.item.description.fontSize};
        color: ${({ theme }) => theme.videoGallery?.item.description.color};
      }

      @media (max-width: 1400px) {
        .video-title {
          font-size: 1.4em;
        }
      }
    }

    @media (min-width: 350px) {
      margin: auto;
      margin-top: 0;
    }
  }
`;

const VideoGallery = ({
  showDescription = false,
  urlProperty = 'link',
  videos = [],
  videoWidth = 200,
  videoHeight: customVideoHeight,
  videoAspectRatio = '16/9',
  numOfColumns,
}) => {
  const [videoSize, setVideoSize] = useState(null);
  const [previewVideo, setPreviewVideo] = useState(null);

  useLayoutEffect(() => {
    const [widthRatio, heightRatio] = videoAspectRatio.split('/');
    const videoHeight = customVideoHeight
      ? customVideoHeight
      : parseInt((videoWidth / Number(widthRatio)) * Number(heightRatio));
    setVideoSize({ width: videoWidth, height: videoHeight });
  }, [videoWidth, videoAspectRatio]);

  const handleVideoClick = (event) => {
    if (!event) {
      // clicked outside video container, closing dialog window
      return setPreviewVideo(null);
    }

    const clickedItemId = parseInt(event?.currentTarget?.dataset?.id);
    const nextPreviewVideo = (videos || []).find((video) => video.id === clickedItemId) || null;

    setPreviewVideo(nextPreviewVideo);
  };

  const renderVideos = () => {
    if (!videos || !videos.length) {
      return <p>No videos</p>;
    }

    return videos.map((video) => (
      <div className="video-gallery-item" key={video?.id}>
        <div className="video">
          <div
            data-id={video?.id}
            data-video-name={video?.title} // required by GA4 do not remove
            onClick={handleVideoClick}
            className="preview-video-button"
            data-clatter-event={JSON.stringify({
              type: 'Video Click',
              data: {
                name: video?.title,
              },
            })}
          >
            <PlayArrowIcon />
          </div>
          <VideoGalleryIframe videoUrl={video?.[urlProperty]} />
        </div>
        <div className="video-details">
          <div className="video-title">{video?.title}</div>
          {showDescription && (
            <div className="video-description">
              {video?.description || '(description not provided)'}
            </div>
          )}
        </div>
      </div>
    ));
  };

  if (!videoSize) {
    return null;
  }

  return (
    <StyledVideoGallery videoSize={videoSize} numOfColumns={numOfColumns}>
      {renderVideos()}

      <VideoPreviewDialog
        title={previewVideo?.title}
        videoUrl={previewVideo?.link}
        open={!!previewVideo}
        onCloseDialog={handleVideoClick}
      />
    </StyledVideoGallery>
  );
};

export default VideoGallery;
