import { useMemo } from 'react';
import { documentTitleMap } from '../routes/routes';
import { useSelector } from 'react-redux';
import { useDocumentTitle } from '@clatter/ui';

const useMsmPageTitle = (pageTitle, customPrefix) => {
  const isNew = useSelector((state) => state.microsites.isCreatingMicrosite);

  const getPageTitle = useMemo(() => {
    const titlePrefix = customPrefix
      ? customPrefix
      : isNew
      ? documentTitleMap.createMicrosite
      : documentTitleMap.editMicrosite;
    return pageTitle ? `${titlePrefix}:${pageTitle}` : titlePrefix;
  }, [pageTitle, isNew]);

  useDocumentTitle(getPageTitle);
};

export default useMsmPageTitle;
