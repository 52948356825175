import React from 'react';
import {
  Checkbox,
  EditorControl,
  FormControl,
  StyledCheckboxContainer,
  TextControl,
} from '@clatter/ui';
import { Controller, useWatch } from 'react-hook-form';

const OptionalCopyBlockFormItem = ({
  control,
  index = 0,
  hideCta = false,
  disableHeadline = false,
  errors,
  headLine = '',
}) => {
  const { register } = control;

  const isButtonEnabled = useWatch({
    control,
    name: `optionalCopyBlocks[${index}].showButton`,
  });

  return (
    <>
      <FormControl label={headLine} error={errors?.optionalCopyBlocks?.[index]?.headline}>
        <TextControl
          {...register(`optionalCopyBlocks[${index}].headline`)}
          placeholder="Enter section title"
          maxLength={100}
          disabled={disableHeadline}
        />
      </FormControl>

      <FormControl error={errors?.optionalCopyBlocks?.[index]?.description}>
        <Controller
          name={`optionalCopyBlocks[${index}].description`}
          rules={{
            minLength: {
              value: 1,
              message: 'This field is required.'
            },
            maxLength: {
              value: 500,
              message: 'Maximum length is 500 characters',
            },
          }}
          render={({ field: { onChange, value } }) => (
            <EditorControl
              onChange={onChange}
              value={value}
            />
          )}
          control={control}
        ></Controller>
      </FormControl>

      {index !== 0 && !hideCta && (
        <>
          <FormControl error={errors?.optionalCopyBlocks?.[index]?.showButton}>
            <StyledCheckboxContainer>
              <Checkbox
                text="Include Call-to-Action Button"
                {...register(`optionalCopyBlocks[${index}].showButton`)}
              />
            </StyledCheckboxContainer>
          </FormControl>

          {isButtonEnabled && (
            <>
              <FormControl
                error={errors?.optionalCopyBlocks?.[index]?.buttonText}
              >
                <TextControl
                  {...register(`optionalCopyBlocks[${index}].buttonText`)}
                  placeholder="Enter Button Text"
                  maxLength={20}
                />
              </FormControl>

              <FormControl
                error={errors?.optionalCopyBlocks?.[index]?.buttonUrl}
              >
                <TextControl
                  {...register(`optionalCopyBlocks[${index}].buttonUrl`)}
                  placeholder="Enter URL"
                />
              </FormControl>
            </>
          )}
        </>
      )}
    </>
  );
};

export default OptionalCopyBlockFormItem;
