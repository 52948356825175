import React, { useState } from 'react';
import styled from 'styled-components';

const StyledContentExpander = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.contentExpander?.margin};
  padding: ${({ theme }) => theme.contentExpander?.padding};

  button {
    position: relative;
    right: ${({ theme }) => theme.contentExpander?.button.right};
    top: -2px;
    cursor: pointer;
    padding: ${({ theme }) => theme.contentExpander?.button.padding};
    color: ${({ theme }) => theme.contentExpander?.button.color};
    background-color: ${({ theme }) =>
      theme.contentExpander?.button.backgroundColor};
    border: ${({ theme }) => theme.contentExpander?.button.border};
    border-radius: ${({ theme }) => theme.contentExpander?.button.borderRadius};
    font-family: ${({ theme }) => theme.contentExpander?.button.fontFamily};
    font-size: ${({ theme }) => theme.contentExpander?.button.fontSize};
    font-weight: ${({ theme }) => theme.contentExpander?.button.fontWeight};
  }

  button {
    right: 0;
  }
`;

const ContentExpander = ({
  children,
  items = [],
  limit = 0,
  moreText = 'More',
  lessText = 'Less',
}) => {
  const [showAll, setShowAll] = useState(limit ? false : true);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <>
      {children(showAll ? items : items.slice(0, limit))}
      {!!limit && items && Array.isArray(items) && items.length > limit && (
        <StyledContentExpander className="content-expander">
          <button type="button" onClick={toggleShowAll}>
            {showAll ? lessText : moreText}
          </button>
        </StyledContentExpander>
      )}
    </>
  );
};

export default ContentExpander;
