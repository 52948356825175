import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Loader } from '@clatter/ui';
import SiteMakerContent from './SiteMakerContent';
import SiteMakerSidebar from './SiteMakerSidebar';

const StyledSiteMaker = styled.div`
  display: grid;
  grid-template-areas: 'sidebar content';
  grid-template-columns: 250px 1fr;
  grid-auto-rows: auto;
  align-items: start;
  gap: 40px;
  padding-bottom: 120px;
`;

const SiteMaker = ({ children, site }) => {
  if (!site) {
    return <Loader />;
  }

  return (
    <StyledSiteMaker>
      <SiteMakerSidebar site={site} pages={site.pages} />
      <SiteMakerContent>{children}</SiteMakerContent>
    </StyledSiteMaker>
  );
};

SiteMaker.propTypes = {
  children: PropTypes.node,
  site: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      variables: PropTypes.string.isRequired,
      bios: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.shape({
            id: PropTypes.string.isRequired,
          }),
          PropTypes.string,
        ]),
      ),
      resources: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.shape({
            id: PropTypes.string.isRequired,
          }),
          PropTypes.string,
        ]),
      ),
      video_embeds: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.shape({
            id: PropTypes.string.isRequired,
          }),
          PropTypes.string,
        ]),
      ),
    }),
  ),
};

export default SiteMaker;
