import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';
import { unwrapResult } from '@reduxjs/toolkit';
import { FormProvider, useForm } from 'react-hook-form';
import {
  fetchReport,
  platformSelectors,
  reportTypes,
  requestStatus,
  useNotices,
  useRoutesState,
  usePageTitleHeader,
} from '@clatter/platform';
import { useDocumentTitle } from '@clatter/ui';
import Page from '../../../components/Page/Page';
import PageviewsFilter, {
  DEFAULT_SORT_MODEL,
  DISPLAY_TYPE_PAGE,
  DISPLAY_TYPE_SITE,
  PAGE_VIEWS_FILTER_KEY,
} from './Filters/PageViewsFilters';
import PageviewsByPageTable, {
  columns as pageTableColumns,
} from './PageviewsByPageTable';
import PageviewsBySite, {
  columns as siteTableColumns,
} from './PageviewsBySiteTable';
import { pageviewsFilter } from './Filters/pageviewsFilter';
import routes, { documentTitleMap } from '../../../routes/routes';

const StyledTableWrapper = styled.div`
  margin-top: 20px;

  .even {
    background-color: #ffffff;
  }

  .odd {
    background-color: #f7f7f7;
  }
`;

const StyledPageContent = styled.div`
  min-width: 300px;
  max-width: calc(100vw - 80px);
  margin: 0 auto;
  padding: 32px 16px;
  min-height: calc(100vh - 64px);
`;

const PageviewsView = () => {
  const { documentFileName } = useDocumentTitle(
    documentTitleMap.reportPageViews.document,
  );
  const { getRouteStateProperty } = useRoutesState();
  const dispatch = useDispatch();
  const { addNotice } = useNotices();
  const [_, setFilterValues] = useState();
  const [rows, setRows] = useState([]);

  const persistedFormState = getRouteStateProperty(PAGE_VIEWS_FILTER_KEY);

  const isLoading =
    useSelector(platformSelectors.report.loadingStatus) ===
    requestStatus.pending;

  const reportData = useSelector(
    platformSelectors.report.selectReportByName(reportTypes.msmPageview),
  );

  const { renderPageTitleHeader } = usePageTitleHeader({
    pageTitle: documentTitleMap.reportPageViews.page,
    routes: routes,
  });

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: persistedFormState || {
      date: {},
      search_query: '',
      display_type: DISPLAY_TYPE_PAGE,
      sort_model: DEFAULT_SORT_MODEL,
    },
  });

  const { setValue, getValues, watch } = formMethods;

  const dateValue = watch('date');
  const displayTypeValue = watch('display_type');
  const sortModelValue = watch('sort_model');
  const searchQueryValue = watch('search_query');

  useEffect(() => {
    if (!dateValue.hasOwnProperty('dateRange') || isLoading) {
      return;
    }

    (async () => {
      try {
        const resultAction = await dispatch(
          fetchReport({
            reportName: reportTypes.msmPageview,
            params: {
              startDate: format(
                parseISO(dateValue?.dateRange.range.startDate),
                'yyyy-MM-dd',
              ),
              endDate: format(
                parseISO(dateValue?.dateRange.range.endDate),
                'yyyy-MM-dd',
              ),
            },
          }),
        );
        unwrapResult(resultAction); // needs to have this in order to catch errors
      } catch (error) {
        setRows([]);
        addNotice({
          message: [
            'Error during fetching data from the API.',
            error?.message && `Reason: ${error.message}`,
          ].join(' '),
          type: 'error',
          title: 'Error',
        });
      }
    })();
  }, [
    dispatch,
    dateValue?.dateRange?.range?.startDate,
    dateValue?.dateRange?.range?.endDate,
  ]);

  // set reports data on filter change
  useEffect(() => {
    if (reportData?.data?.length === 0) {
      setRows([]);
    }

    if (reportData?.data?.length && dateValue.hasOwnProperty('dateRange')) {
      setRows(
        pageviewsFilter({
          data: reportData?.data,
          filters: getValues(),
          tableColumns:
            displayTypeValue === DISPLAY_TYPE_PAGE
              ? pageTableColumns
              : siteTableColumns,
        }),
      );
    }
  }, [
    reportData?.data,
    dateValue?.dateRange?.range.startDate,
    dateValue?.dateRange?.range.endDate,
    searchQueryValue,
    displayTypeValue,
    sortModelValue?.field,
    sortModelValue?.sort,
  ]);

  return (
    <Page withPageContent={false}>
      <FormProvider {...formMethods}>
        <StyledPageContent>

          { renderPageTitleHeader() }

          <PageviewsFilter
            disabled={isLoading}
            onFiltersChange={setFilterValues}
          />
          <StyledTableWrapper>
            {displayTypeValue === DISPLAY_TYPE_PAGE && (
              <PageviewsByPageTable
                isLoading={isLoading}
                rows={rows}
                onSortChange={(sortModel) => setValue('sort_model', sortModel)}
                exportFileName={`${documentFileName} (Page)`}
                name="report/msm-pageviews-page"
              />
            )}

            {displayTypeValue === DISPLAY_TYPE_SITE && (
              <PageviewsBySite
                isLoading={isLoading}
                rows={rows}
                exportFileName={`${documentFileName} (Site)`}
                name="report/msm-pageviews-site"
              />
            )}
          </StyledTableWrapper>
        </StyledPageContent>
      </FormProvider>
    </Page>
  );
};

export default PageviewsView;
