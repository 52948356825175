import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import {
  Button,
  errorMessages,
  FormButtons,
  FormControl,
  SelectControl,
  TextControl,
} from '@clatter/ui';
import { isUrlValid, useAuth } from '@clatter/platform';
import { fetchVideoCategories } from '../../../store/video-categories.slice';
import {
  createVideoEmbed,
  updateVideoEmbed,
} from '../../../store/video-embeds.slice';

const StyledCreateVideoCategoryForm = styled.form`
  width: 480px;
`;

const validateJoiUri = (value, joiHelper) => {
  if (value.includes('//vimeo.com/')) {
    return joiHelper.message({
      custom:
        'Vimeo link should match https://player.vimeo.com/video/XXX where XXX is your videoId.',
    });
  }

  if (value.includes('youtube.com/watch?') || value.includes('youtu.be/')) {
    return joiHelper.message({
      custom:
        'YouTube link should match https://www.youtube.com/embed/XXX where XXX is your videoId',
    });
  }

  return true;
};

const validationSchema = Joi.object()
  .keys({
    title: Joi.string(),
    description: Joi.string(),
    link: Joi.string()
      .uri()
      .custom((value, helper) => {
        const validationResult = validateJoiUri(value, helper);
        if (validationResult !== true) {
          return validationResult;
        }

        if (!isUrlValid(value)) {
          return helper.message(errorMessages.URI);
        }

        return value;
      }),
    download_link: Joi.string().uri(),
    video_category: Joi.any().optional(),
  })
  .messages({
    'any.required': errorMessages.REQUIRED.message,
    'string.uri': errorMessages.URI.message,
    'string.empty': errorMessages.REQUIRED.message,
    'string.base': errorMessages.REQUIRED.message,
  });

const CreateVideoCategoryForm = ({ editableItem, isEdit, onSuccess }) => {
  const dispatch = useDispatch();
  const { activeUser } = useAuth();
  // editableItem data could hold fields that are not included in form
  // such as "id", "direct_link", "_id" which is returning from the api
  // so we couldn't put this in the form as its fields are not registered for
  // all possible fields and therefore form state will always be invalid
  const { link, title, description, video_category, download_link } =
    editableItem || {};

  useEffect(() => {
    dispatch(fetchVideoCategories());
  }, []);

  const videoCategories = useSelector((state) =>
    Object.values(state.videoCategories.entities).sort((a, b) =>
      a.updated_at > b.updated_at ? -1 : 1,
    ),
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    control,
  } = useForm({
    mode: 'all',
    resolver: joiResolver(validationSchema),
    defaultValues: {
      title,
      description,
      link,
      download_link,
      video_category,
    },
  });

  const onSubmit = async (formData) => {
    console.debug({ formData });

    if (isEdit) {
      // update fields and keep the ones
      // that are not included in the form
      const updatedData = {
        ...editableItem,
        link: formData.link,
        title: formData.title,
        description: formData.description,
        video_category: formData.video_category,
        download_link: formData.download_link,
        c_updated_by: activeUser.email,
      };
      await dispatch(
        updateVideoEmbed({ id: editableItem.id, formData: updatedData }),
      ).then(() => {
        onSuccess();
      });
      return;
    }
    await dispatch(createVideoEmbed({ ...formData, c_created_by: activeUser.email })).then(() => {
      onSuccess();
    });
  };

  return (
    <StyledCreateVideoCategoryForm onSubmit={handleSubmit(onSubmit)}>
      <FormControl label="Video name" error={errors.title}>
        <TextControl {...register('title')} placeholder="Enter title" />
      </FormControl>
      <FormControl label="Description" error={errors.description}>
        <TextControl
          {...register('description')}
          placeholder="Enter description"
        />
      </FormControl>
      <FormControl label="Video url" error={errors.link}>
        <TextControl {...register('link')} placeholder="Enter video url" />
      </FormControl>
      <FormControl label="Video download url" error={errors.download_link}>
        <TextControl
          {...register('download_link')}
          placeholder="Enter download url"
        />
      </FormControl>
      <FormControl label="Category">
        <Controller
          name="video_category"
          render={({ field: { onChange, value } }) => (
            <SelectControl
              onChange={onChange}
              options={videoCategories}
              value={value}
              getOptionLabel={(option) => option.categoryName}
              getOptionValue={(option) => option.id}
            />
          )}
          control={control}
        />
      </FormControl>
      <FormButtons>
        <Button disabled={!isValid || !isDirty} type="submit">
          {isEdit ? 'Save' : 'Create'}
        </Button>
      </FormButtons>
    </StyledCreateVideoCategoryForm>
  );
};

export default CreateVideoCategoryForm;
