import React, { useState } from 'react';
import styled from 'styled-components';
import SectionHeader from './SectionHeader';
import CustomerBio from './CustomerBio';
import { ContentExpander } from '../../components';
import BioDetailsDialog from './BioDetailsDialog';

const StyledCustomerTeam = styled.div`
  padding: ${({ theme }) => theme.customerTeam?.padding};

  .team {
    padding: ${({ theme }) => theme.customerTeam?.team?.padding};
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    column-gap: 24px;
    row-gap: 48px;
    align-items: end;
  }
`;

const CustomerTeam = ({ bios, customerName, headerText = '', showEmail, showPhone }) => {
  const [previewBio, setPreviewBio] = useState(null);

  const togglePreviewBio = (event) => {
    const clickedItemId = parseInt(event?.currentTarget?.dataset?.id);

    if (!clickedItemId) {
      setPreviewBio(null);
    }

    const nextPreviewBio = bios.find(({ id }) => id === clickedItemId);

    setPreviewBio(nextPreviewBio);
  };

  return (
    <StyledCustomerTeam>
      <SectionHeader as="h2">
        {headerText || `${customerName} team`}
      </SectionHeader>
      <ContentExpander
        limit={4}
        items={bios}
        moreText="More bios"
        lessText="Less bios"
      >
        {(items) => (
          <div className="team">
            {items.map((bio) => (
              <CustomerBio
                data-id={bio.id}
                data-bio-name={`${bio.displayName}, ${bio.title}`}
                key={bio.displayName}
                image={bio.profilePicture?.formats?.thumbnail?.url}
                name={bio.displayName}
                email={showEmail && bio?.email}
                phone={showPhone && bio?.phone}
                onClick={togglePreviewBio}
                onClickText="Click for bio"
                title={bio.title}
              />
            ))}
          </div>
        )}
      </ContentExpander>
      <BioDetailsDialog
        onCloseDialog={togglePreviewBio}
        details={previewBio}
        open={!!previewBio}
      />
    </StyledCustomerTeam>
  );
};

export default CustomerTeam;
