import React, { useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import { cloneDeep, get } from 'lodash';
import { compareIdentifiers } from '../../helpers';
import { secondaryColor } from '@clatter/ui';

const StyledImagePicker = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    ${(props) => `${props.imageSize.width}px`}
  );
  grid-template-rows: ${(props) => `${props.imageSize.height + 32}px`};
  gap: 16px;
  padding-bottom: 16px;
  height: ${(props) => `${props.imageSize.height * 2 + 80}px`};
  overflow-y: scroll;

  .image-picker-item {
    cursor: pointer;
    position: relative;
    background-color: #fff;
    position: relative;

    .image-picker-image {
      height: ${(props) => `${props.imageSize.height}px`};
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      background-color: #fff;
      border: ${(props) => props?.showBorderForNonSelectedImages ? '2px solid #777' : '4px solid transparent'};
    }

    .image-picker-actions {
      position: absolute;
      top: 8px;
      right: 8px;
    }

    .image-picker-details {
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 32px;
      padding: 0 8px;
      font-size: 12px;

      .file-title {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .image-selected {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 20px;
      height: 20px;
      background-color: ${secondaryColor};
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        color: #fff;
        font-size: 20px;
      }
    }

    &.image-picker-item-selected {
      .image-picker-image {
        border: 4px solid ${secondaryColor};
      }
    }
  }
`;

const ImagePicker = ({
  images = [],
  multiple = false,
  onChange,
  value,
  urlProperty = 'url',
  titleProperty = 'name',
  imageWidth = 200,
  imageAspectRatio = '16/9',
  renderItemActions,
  showBorderForNonSelectedImages = false,
}) => {
  const [imageSize, setImageSize] = useState(null);
  useLayoutEffect(() => {
    const [widthRatio, heightRatio] = imageAspectRatio.split('/');
    const imageHeight = parseInt(
      (imageWidth / Number(widthRatio)) * Number(heightRatio),
    );
    setImageSize({ width: imageWidth, height: imageHeight });
  }, [imageWidth, imageAspectRatio]);

  const isItemSelected = (imageId) => {
    if (!value) {
      return false;
    }

    if (
      (!multiple && compareIdentifiers(value.id, imageId)) ||
      (multiple &&
        value.length &&
        value.some((image) => compareIdentifiers(image.id, imageId)))
    ) {
      return true;
    }
  };

  const handleImageClick = (event) => {
    const clickedItemId = parseInt(event.currentTarget.dataset.id);
    let nextValue;

    if (multiple) {
      nextValue = cloneDeep(value) || [];

      if (
        nextValue.some((image) => compareIdentifiers(image.id, clickedItemId))
      ) {
        nextValue = nextValue.filter(
          (image) => !compareIdentifiers(image.id, clickedItemId),
        );
      } else {
        nextValue.push(clickedItemId);
      }
    } else {
      if (value && compareIdentifiers(value.id, clickedItemId)) {
        nextValue = null;
      } else {
        nextValue = images.find((image) =>
          compareIdentifiers(image.id, clickedItemId),
        );
      }
    }

    typeof onChange === 'function' && onChange(nextValue);
  };

  const renderImages = () => {
    if (!images || !images.length) {
      return <p>No images to pick</p>;
    }

    return images.map((image) => (
      <div
        data-id={image.id}
        onClick={handleImageClick}
        className={`image-picker-item ${
          isItemSelected(image.id) ? 'image-picker-item-selected' : ''
        }`}
        key={image.id}
      >
        <div
          className="image-picker-image"
          style={{ backgroundImage: `url(${get(image, urlProperty)}` }}
        />
        {typeof renderItemActions === 'function' && (
          <div className="image-picker-actions">{renderItemActions(image)}</div>
        )}
        <div className="image-picker-details">
          <div className="file-title">{get(image, titleProperty)}</div>
        </div>
      </div>
    ));
  };

  if (!imageSize) {
    return null;
  }

  return (
    <StyledImagePicker imageSize={imageSize} showBorderForNonSelectedImages={showBorderForNonSelectedImages}>
      {renderImages()}
    </StyledImagePicker>
  );
};

export default ImagePicker;
