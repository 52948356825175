import React from 'react';
import styled from 'styled-components';
import BioCard from '../BioCard/BioCard';

const StyledBioCardList = styled.div`
  > * {
    margin-bottom: 8px;
  }
`;

const BioCardList = ({
  items = [],
  onItemClick,
  renderItemActions,
  emptyRowsMessage = 'Nothing...',
}) => {
  const renderItems = () => {
    if (!items || !items.length) {
      return <div>{emptyRowsMessage}</div>;
    }

    return items.map((bio, index) => (
      <BioCard
        key={bio.id}
        details={bio}
        onClick={onItemClick}
        index={index}
        renderActions={renderItemActions}
      />
    ));
  };

  return <StyledBioCardList>{renderItems()}</StyledBioCardList>;
};

export default BioCardList;
