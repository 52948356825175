import React from 'react';
import VideoGallery from './VideoGallery';

export const mockImages = [
  {
    _id: '602e736b2e79605656d8b2ee',
    name: 'Bank_of_America_logo.svg.png',
    hash: 'Bank_of_America_logo_svg_93e8d52251',
    ext: '.png',
    mime: 'image/png',
    size: 37.63,
    width: 2000,
    height: 254,
    url: 'https://clatter-lpmaker-prod-0.s3.amazonaws.com/Bank_of_America_logo_svg_93e8d52251.png',
    id: '602e736b2e79605656d8b2ee',
  },
  {
    _id: 'q1w2e3r4t5y6u7i8o9p0',
    name: 'logo-social-sq',
    hash: 'logo-social-sq_93e8d52251',
    ext: '.png',
    mime: 'image/png',
    size: 37.63,
    width: 2000,
    height: 254,
    url: 'https://cdn.logo.com/hotlink-ok/logo-social-sq.png',
    id: 'q1w2e3r4t5y6u7i8o9p0',
  },
];

const VideoEmbedGallery = ({
  videos,
  videoWidth,
  videoHeight,
  numOfColumns,
}) => {
  const urlProperty = 'url';
  const titleProperty = 'name';
  const imageWidth = 200;
  const imageAspectRatio = '16/9';

  const isItemSelected = (imageId) => {
    return false;
    // if (!value) {
    //   return false;
    // }

    // if (
    //   (!multiple && compareIdentifiers(value.id, imageId)) ||
    //   (multiple &&
    //     value.length &&
    //     value.some((image) => compareIdentifiers(image.id, imageId)))
    // ) {
    //   return true;
    // }
  };

  const handleImageClick = (event) => {
    const clickedItemId = event.currentTarget.dataset.id;
    // let nextValue;

    // if (multiple) {
    //   nextValue = cloneDeep(value) || [];

    //   if (
    //     nextValue.some((image) => compareIdentifiers(image.id, clickedItemId))
    //   ) {
    //     nextValue = nextValue.filter(
    //       (image) => !compareIdentifiers(image.id, clickedItemId)
    //     );
    //   } else {
    //     nextValue.push(clickedItemId);
    //   }
    // } else {
    //   if (value && compareIdentifiers(value.id, clickedItemId)) {
    //     nextValue = null;
    //   } else {
    //     nextValue = images.find((image) =>
    //       compareIdentifiers(image.id, clickedItemId)
    //     );
    //   }
    // }

    // onChange(nextValue);
  };

  const renderImages = (images) => {
    if (!images || !images.length) {
      return <p>No images to pick</p>;
    }

    return images.map((image) => (
      <div
        data-id={image.id}
        onClick={handleImageClick}
        className={`image-picker-item ${
          isItemSelected(image.id) ? 'image-picker-item-selected' : ''
        }`}
        key={image.id}
      >
        <div
          className="image-picker-image"
          style={{ backgroundImage: `url(${image[urlProperty]}` }}
        />
        {typeof renderItemActions === 'function' && (
          <div className="image-picker-actions">{renderItemActions(image)}</div>
        )}
        <div className="image-picker-details">
          <div className="file-title">{image[titleProperty]}</div>
        </div>
      </div>
    ));
  };

  return (
    <VideoGallery
      videos={videos}
      videoWidth={videoWidth ?? 200}
      videoHeight={videoHeight}
      numOfColumns={numOfColumns}
    />
  );
};

export default VideoEmbedGallery;
