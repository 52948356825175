import React from 'react';
import styled from 'styled-components';

const StyledTextControl = styled.input`
  border: ${({ theme }) => theme.form.input.border};
  font-family: ${({ theme }) => theme.form.input.fontFamily};
  font-family: ${({ theme }) => theme.form.input.fontFamily};
  height: ${({ theme }) => theme.form.input.height};
  padding: 0 16px;
  border-radius: 4px;
  outline: none;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'min-content')};

  &:focus:not(:read-only) {
    border: ${({ theme }) => theme.form.input.hover.border};
  }

  &:read-only {
    background-color: #f4f4f4;
  }
`;

const TextControl = React.forwardRef(
  ({ type = 'text', maxLength, fullWidth = true, ...inputProps }, ref) => (
    <StyledTextControl
      ref={ref}
      type={type}
      maxLength={maxLength}
      fullWidth={fullWidth}
      {...inputProps}
    />
  ),
);

export default TextControl;
