import React, {useCallback, useEffect} from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { Grid } from '@mui/material';
import {
  DateAndGranularityPicker,
  FormControl,
  RadioGroup,
  TextControl,
} from '@clatter/ui';
import { debounce } from "lodash";
import { useRoutesState } from "@clatter/platform";

export const DISPLAY_TYPE_PAGE = 'page';
export const DISPLAY_TYPE_SITE = 'site';

export const DEFAULT_SORT_MODEL = {
  field: 'page',
  sort: 'asc',
};

export const PAGE_VIEWS_FILTER_KEY = 'filters';

const PageviewsFilter = ({ disabled, onFiltersChange }) => {
  const { setRouteStateProperty, getRouteStateProperty } = useRoutesState();
  const { control, handleSubmit, watch, getValues, register } =
    useFormContext();

  useEffect(() => {
    handleSubmit(onFiltersChange(getValues()));
  }, [handleSubmit]);

  useEffect(() => {
    const subscription = watch((formState) => {
      setRouteStateProperty({ [PAGE_VIEWS_FILTER_KEY]: { ...formState, search_query: '' } })
      handleSubmit(onFiltersChange)();
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmitDebounced = useCallback(
    debounce(({ onChange, search }) => onChange(search), 300),
    [],
  );

  return (
    <form onSubmit={handleSubmit(onFiltersChange)}>
      <DevTool control={control} placement="bottom-left" />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Controller
            name="date"
            control={control}
            render={({ field: { onChange, onBlur } }) => (
              <DateAndGranularityPicker
                showGranularity={false}
                onBlur={onBlur}
                disabled={disabled}
                onChange={(event) => onChange(event)}
                defaultDateRangeValue={getRouteStateProperty(PAGE_VIEWS_FILTER_KEY)?.date?.dateRange?.value}
                customDateRangeOptions={{
                  maxDate: new Date(),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl label="Display">
            <RadioGroup
              inline
              disabled={disabled}
              {...register('display_type')}
              options={[
                {
                  label: 'Site',
                  value: DISPLAY_TYPE_SITE,
                },
                {
                  label: 'Page',
                  value: DISPLAY_TYPE_PAGE,
                },
              ]}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="search_query"
            control={control}
            render={({ field: { onChange, onBlur } }) => (
              <TextControl
                placeholder="Search..."
                fullWidth
                disabled={disabled}
                onBlur={onBlur}
                data-test-id="search-input"
                onChange={(event) =>
                  onSubmitDebounced({
                    onChange,
                    search: event.currentTarget.value,
                  })
                }
                text="Search"
              />
            )}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default PageviewsFilter;
