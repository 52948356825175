import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import CustomerBio from './CustomerBio';
import Section from './Section';
import BioDetailsDialog from './BioDetailsDialog';
import classNames from 'classnames';
import { EditorControl } from "@clatter/templates";

const StyledFooterBrand = styled.footer`
  background-color: ${({ theme }) => theme.footerBrand?.backgroundColor};
  border-top: ${({ theme }) => theme.footerBrand?.borderTop};

  .footer-brand-full-width {
    display: flex;
    justify-content: flex-end;
    padding: ${({ theme }) => theme.footerBrand?.padding};
    padding-bottom: 10px;

    .main-bio {
      display: grid;
      grid-template-columns: 1fr;
      gap: 30px;
      direction: ltr;

      > * {
        margin-top: 16px;
        min-width: 250px;
      }

      @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
      }

      @media (min-width: 1280px) {
        grid-template-columns: repeat(${({biosCount}) => biosCount || 1}, 1fr);
      }
    }
  }

  .footer-brand-content {
    display: grid;
    grid-template-areas:
      'brandLogo'
      'bios';
    align-items: center;
    padding: ${({ theme }) => theme.footerBrand?.padding};

    .main-bio {
      grid-area: bios;
      display: grid;
      grid-template-columns: 1fr;
      direction: ltr;

      > * {
        margin-top: 16px;
        min-width: 250px;
      }
    }

    .brand-logo {
      grid-area: brandLogo;

      img {
        display: block;
        max-height: 64px;
        max-width: 100%;
      }
    }
  }

  .footer-optional-copy {
    flex: 1;
    padding-left: 2rem;
    max-height: 110px;
  }

  .footer-brand-three-column {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    row-gap: 4rem;
    padding: ${({ theme }) => theme.footerBrand?.padding};

    .main-bio {
      display: flex;
      justify-content: center;
      flex: 1;
    }

    .brand-logo {
      flex: 1;

      img {
        max-height: 65px;
      }
    }

    @media (max-width: 1200px) {
      .footer-optional-copy {
        /* margin-top: 2rem; */
      }
    }

    @media (min-width: 768px) {
      flex-direction: row;
      row-gap: 2rem;
      .brand-logo {
        flex: 33.33%;
      }

      .main-bio {
        flex: 33.33%;
      }

      .footer-optional-copy {
        flex: 33.33%;
      }
    }
  }

  @media (min-width: 768px) {
    .footer-brand-content {
      .main-bio {
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  @media (min-width: 1000px) {
    .footer-brand-content {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      grid-template-areas: 'brandLogo bios bios';

      .main-bio {
        grid-column: span 2;
        display: grid;
        grid-template-columns: 1fr 1fr;
        direction: rtl;
        margin: 0;

        > * {
          direction: ltr;
          align-self: right;
          margin-top: 0;
          margin-left: 16px;
        }
      }
    }
  }
`;

const FooterBrand = ({
  allowBioClick = false,
  bio,
  logo,
  showLogo = true,
  phoneVisibility = 'both',
  emailVisibility = 'both',
  biosFullWidth = false,
  showOptionalCopy = false,
  optionalCopyText = '',
}) => {
  const [previewBio, setPreviewBio] = useState(null);

  const biosCount = useMemo(
    () => (bio && Array.isArray(bio) ? bio?.length : 1),
    [bio],
  );

  const togglePreviewBio = (event) => {
    const clickedItemId = parseInt(event?.currentTarget?.dataset?.id);

    if (!clickedItemId) {
      setPreviewBio(null);
    }

    let nextPreviewBio = bio.find(({ id }) => id === clickedItemId);

    if (!['both', 'dialog'].includes(phoneVisibility)) {
      nextPreviewBio = omit(nextPreviewBio, 'phone');
    }

    if(!['both', 'dialog'].includes(emailVisibility)) {
      nextPreviewBio = omit(nextPreviewBio, 'email');
    }

    setPreviewBio(nextPreviewBio);
  };

  const renderBio = () => {
    if (!bio) {
      return null;
    }

    const showPhoneOnSite = ['both', 'site'].includes(phoneVisibility);
    const showEmailOnSite = ['both', 'site'].includes(emailVisibility);

    return (
      <div className="main-bio">
        {Array.isArray(bio) ? (
          bio.map((bioItem) => (
            <CustomerBio
              footerItem
              key={bioItem.srcImg}
              image={bioItem.srcImg}
              name={bioItem.displayName}
              title={bioItem.title}
              email={showEmailOnSite && bioItem.email}
              phone={showPhoneOnSite && bioItem.phone}
              data-id={bioItem.id}
              data-bio-name={`${bioItem.displayName}, ${bioItem.title}`}
              onClick={allowBioClick ? togglePreviewBio : undefined}
              onClickText={allowBioClick ? 'Click for Bio' : undefined}
            />
          ))
        ) : (
          <CustomerBio
            footerItem
            key={bio.srcImg}
            image={bio.srcImg}
            name={bio.displayName}
            title={bio.title}
            phone={showPhoneOnSite && bio.phone}
            data-id={bio.id}
            data-bio-name={`${bio.displayName}, ${bio.title}`}
            onClick={togglePreviewBio}
            onClickText="Click for Bio"
          />
        )}
      </div>
    );
  };

  return (
    <StyledFooterBrand biosCount={biosCount}>
      <Section>
        {biosFullWidth ? (
          <div className="footer-brand-full-width">{renderBio()}</div>
        ) : (
          <div
            className={classNames({
              'footer-brand-content': !showOptionalCopy,
              'footer-brand-three-column': showOptionalCopy,
            })}
          >
            <div className="brand-logo">
              {showLogo && !!logo && <img src={logo} alt="Co-Brand-Logo" />}
            </div>
            {renderBio()}
            {showOptionalCopy && (
              <div className="footer-optional-copy">
                <EditorControl readOnly noScrollbars value={optionalCopyText} />
              </div>
            )}
          </div>
        )}
      </Section>
      {allowBioClick && (
        <BioDetailsDialog
          onCloseDialog={togglePreviewBio}
          details={previewBio}
          open={!!previewBio}
        />
      )}
    </StyledFooterBrand>
  );
};

FooterBrand.propTypes = {
  allowBioClick: PropTypes.bool,
  bio: PropTypes.array,
  logo: PropTypes.string,
  showLogo: PropTypes.bool,
  phoneVisibility: PropTypes.oneOf(['both', 'site', 'dialog']),
};

export default FooterBrand;
