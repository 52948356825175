import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from "styled-components";
import { format } from 'date-fns';
import { Stack } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Button, DataTable, Dialog, IconButton, Loader, useDocumentTitle } from '@clatter/ui';
import { ExportImport, formatDateTime, requestStatus, useNotices, usePageTitleHeader } from '@clatter/platform';
import routes, { documentTitleMap } from "../../../routes/routes";
import { fetchCobrandLogos, selectAllCobrandLogos, deleteCobrandLogo } from "../../../store";
import CoBrandLogoForm from "./CoBrandLogoForm";

const ImageThumbnail = styled.img`
    width: 145px;
    height: 90px;
    object-fit: contain;
`

const columns = [
  {
    label: 'Logo',
    customRender: (row) => (
      <ImageThumbnail
          src={row?.asset?.formats?.thumbnail?.url || row?.asset?.url }
          alt={row.company}
      />
    ),
    align: 'left',
  },
  {
    label: 'Company',
    name: 'company',
    align: 'left',
    sortable: true,
  },
  {
    label: 'Created by',
    name: 'c_created_by',
    align: 'left',
    sortable: true,
  },
  {
    label: 'Date added',
    name: 'createdAt',
    customRender: (row) => formatDateTime(row.createdAt),
    align: 'left',
    sortable: true,
  },
  {
    label: 'Updated by',
    name: 'c_updated_by',
    align: 'left',
    sortable: true,
  },
  {
    label: 'Date updated',
    name: 'updatedAt',
    customRender: (row) => formatDateTime(row?.updatedAt) || '',
    align: 'left',
    sortable: true,
  },
];

const CoBrandLogos = () => {
  useDocumentTitle(documentTitleMap.adminCoBrandLogos)
  const [editableItem, setEditableItem] = useState(null);
  const { addNotice } = useNotices();
  const dispatch = useDispatch();

  const { renderPageTitleHeader } = usePageTitleHeader({
    pageTitle: "Co Brand Logos",
    linksType: 'msm-admin',
    routes: routes,
  });

  const coBrandLogos = useSelector(selectAllCobrandLogos);
  const loading = useSelector((state) => state.cobrandLogos.loadingStatus === requestStatus.pending);
  const isEdit = editableItem && 'asset' in editableItem;

  //region METHODS
  const handleImportSuccess = (response) => {
    dispatch(fetchCobrandLogos());

    const message = [];

    if (response.data?.imported?.length > 0) {
      message.push(
        `Successfully imported ${response.data?.imported?.length} co brand logo(s).`,
      );
    }

    if (response.data?.omitted?.length > 0) {
      message.push(
        `${response.data?.omitted?.length} co brand logo(s) omitted (duplicated).`,
      );
    }

    addNotice({
      message: message.join(' '),
      type: 'success',
      title: 'Success',
    });
  };

  const handleEditClick = (event) => {
    const clickedItemId = parseInt(event.currentTarget?.dataset?.id);

    setEditableItem(
      (clickedItemId &&
        coBrandLogos.find((item) => item.id === clickedItemId)) ||
        {},
    );
  };

  const handleDismissEdit = () => {
    setEditableItem(null);
  };

  const handleOnSuccess = () => {
    setEditableItem(null);
    dispatch(fetchCobrandLogos());
  };

  const handleDeleteClick = (event) => {
    if (window.confirm('Are you sure you want to remove this co brand logo?')) {
      dispatch(deleteCobrandLogo(parseInt(event.currentTarget.dataset.id)));
    }
  };
  //endregion

  //region EFFECTS
  useEffect(() => {
    dispatch(fetchCobrandLogos());
  }, [dispatch]);
  //endregion

  return (
    <>
      {loading && <Loader />}

      { renderPageTitleHeader({
        headerActions:
          <Stack spacing={1} direction="row" alignItems="center">
            <ExportImport
              exportUrl="/cobrand-logos"
              collectionName="Co Brand Logos"
              exportFileName={`co_brand_logos_${process.env.NX_CLIENT}_${format(new Date(), 'yyyy-MM-dd_HH-mm-ss')}`}
              exportDisabled={!coBrandLogos || coBrandLogos?.length === 0}
              onImportSuccessCallback={handleImportSuccess}
            />

            <Button onClick={handleEditClick}>
              Add Co Brand Logo
            </Button>
          </Stack>
        })
      }

      <div>
        <DataTable
          autofit
          columns={columns}
          defaultSortField="created_at"
          filterColumns={['company']}
          name="adminCoBrandLogos"
          rows={coBrandLogos}
          renderActionColumn={(row) => (
            <>
              <IconButton data-id={row.id} onClick={handleEditClick}>
                <EditOutlinedIcon />
              </IconButton>
              <IconButton data-id={row.id} onClick={handleDeleteClick}>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </>
          )}
        />
      </div>
      <Dialog
        title={isEdit ? 'Edit Co Brand Logo' : 'Add Co Brand Logo'}
        onCloseDialog={handleDismissEdit}
        open={!!editableItem}
      >
        <CoBrandLogoForm
          isEdit={isEdit}
          editableItem={editableItem}
          onSuccess={handleOnSuccess}
        />
      </Dialog>
    </>
  );
};

export default CoBrandLogos;
