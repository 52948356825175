export const messageTypes = {
  error: 'error',
  info: 'info',
  success: 'success',
  warning: 'warning',
};

export const buttonVariants = {
  primary: 'button-primary',
  secondary: 'button-secondary',
  clear: 'button-clear',
  success: 'button-success',
  dark: 'button-dark',
  danger: 'button-danger',
  warning: 'button-warning'
};

export const buttonWidths = {
  sm: 'button-small',
  md: 'button-medium',
  lg: 'button-large',
}

export const textLinkVariants = {
  primary: 'text-link-primary',
  secondary: 'text-link-secondary',
  clear: 'text-link-clear',
};
