import React from 'react';
import styled from 'styled-components';

const StyledAdminContentHead = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  height: 82px;

  h1 {
    padding: 0;
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const AdminContentHead = ({ children }) => (
  <StyledAdminContentHead>{children}</StyledAdminContentHead>
);

export default AdminContentHead;
