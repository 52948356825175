import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { getAxiosCmsWithAuthorization } from '@clatter/platform';

export const PAGES_FEATURE_KEY = 'pages';
export const pagesAdapter = createEntityAdapter();
const baseUrl = '/pages';

export const fetchPages = createAsyncThunk(
  `${PAGES_FEATURE_KEY}/fetch`,
  async ({ pagesIds = [] }) => {
    let restrict = '';

    if (pagesIds && Array.isArray(pagesIds) && pagesIds.length > 0) {
      for (const pageId of pagesIds) {
        restrict += `&filters[$and][0][id][$in][]=${pageId}`;
      }
    }

    const response = await getAxiosCmsWithAuthorization({
      method: 'get',
      url: `${baseUrl}?pagination[limit]=-1`
        + `&populate=bios.profilePicture,resources.asset,quick_links.asset,video_embeds.video_category,`
        + `page_template.asset,content_rails,footer_bios.profilePicture,banner_video,banner_image${restrict}`
    });

    return Promise.resolve(response.data);
  },
);

export const addPage = createAsyncThunk(
  `${PAGES_FEATURE_KEY}/add`,
  async (page) => {
    const response = await getAxiosCmsWithAuthorization({
      method: 'post',
      url: baseUrl,
      data: { data: page },
    });

    return Promise.resolve(mapApiToStore(response.data.data));
  },
);

// @todo resolve the pluralization conventions
export const updatePage = createAsyncThunk(
  `${PAGES_FEATURE_KEY}/update`,
  async (page, { dispatch }) => {
    const response = await getAxiosCmsWithAuthorization({
      method: 'put',
      url: `${baseUrl}/${page.id}`,
      data: { data: page },
    });

    // dispatch(
    //   messagesActions.setMessage({
    //     message: createMessage(
    //       messageTypes.success,
    //       'Page has been saved.',
    //       'pageSave',
    //     ),
    //   }),
    // );

    return Promise.resolve(mapApiToStore(response.data.data));
  },
);

export const initialPagesState = pagesAdapter.getInitialState({
  loadingStatus: 'loading',
  error: null,
});

const mapApiToStore = (item) => ({
  id: item.id,
  name: item.attributes?.title,
  ...item.attributes,
  bios: (item.attributes?.bios?.data || []).map(bio => ({
    id: bio.id,
    ...bio.attributes,
    profilePicture: bio.attributes?.profilePicture?.data !== null ? {
      id: bio.attributes?.profilePicture?.data?.id,
      ...bio.attributes?.profilePicture?.data?.attributes,
    } : null,
  })),
  footer_bios: (item.attributes?.footer_bios?.data || []).map(footerBio => ({
    id: footerBio.id,
    name: footerBio.attributes.displayName,
    srcImg: footerBio.attributes?.profilePicture?.data?.attributes?.url,
    image: footerBio.attributes?.profilePicture?.data?.attributes?.url,
    ...footerBio.attributes,
    profilePicture: footerBio.attributes?.profilePicture?.data !== null ? {
      id: footerBio.attributes?.profilePicture?.data?.id,
      ...footerBio.attributes?.profilePicture?.data?.attributes,
    } : null,
  })),
  resources: (item.attributes?.resources?.data || []).map(resource => ({
    id: resource.id,
    ...resource.attributes,
    asset: resource.attributes?.asset?.data !== null ? {
      id: resource.attributes?.asset?.data?.id,
      ...resource.attributes?.asset?.data?.attributes,
    } : null,
  })),
  quick_links: (item.attributes?.quick_links?.data || []).map(quickLink => ({
    id: quickLink.id,
    ...quickLink.attributes,
    asset: quickLink.attributes?.asset?.data !== null ? {
      id: quickLink.attributes?.asset?.data?.id,
      ...quickLink.attributes?.asset?.data?.attributes,
    } : null,
  })),
  video_embeds: (item.attributes?.video_embeds?.data || []).map(videoEmbed => ({
    id: videoEmbed.id,
    ...videoEmbed.attributes,
    video_category: videoEmbed.attributes?.video_category?.data !== null ? {
      id: videoEmbed.attributes?.video_category?.data?.id,
      ...videoEmbed.attributes?.video_category?.data?.attributes,
    } : null,
  })),
  page_template: item.attributes?.page_template && item.attributes?.page_template?.data !== null ? {
    id: item.attributes?.page_template?.data?.id,
    ...item.attributes?.page_template?.data?.attributes,
  } : null,
  // NOTE: templateName is used in many blocks configs. It is important that this prop is properly set!!!
  templateName: item.attributes?.page_template && item.attributes?.page_template?.data !== null ?
    item.attributes?.page_template?.data?.attributes?.template : null,
  content_rails: (item.attributes?.content_rails?.data || []).map(contentRail => ({
    id: contentRail.id,
    ...contentRail.attributes,
  })),
  banner_video: item.attributes?.banner_video && item.attributes?.banner_video?.data !== null ? {
    id: item.attributes?.banner_video?.data?.id,
    ...item.attributes?.banner_video?.data?.attributes,
  } : null,
  banner_image: item.attributes?.banner_image && item.attributes?.banner_image?.data !== null ? {
    id: item.attributes?.banner_image?.data?.id,
    ...item.attributes?.banner_image?.data?.attributes,
  } : null,
  resources_call_to_action_button_display: item.attributes?.resources_call_to_action_button_display || null,
  resources_call_to_action_button_label: item.attributes?.resources_call_to_action_button_label || null,
  resources_call_to_action_button_url: item.attributes?.resources_call_to_action_button_url || null,
});

export const pagesSlice = createSlice({
  name: PAGES_FEATURE_KEY,
  initialState: initialPagesState,
  reducers: {
    add: pagesAdapter.addOne,
    remove: pagesAdapter.removeOne,
    update: pagesAdapter.updateOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPages.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(fetchPages.fulfilled, (state, action) => {
        pagesAdapter.setAll(state, (action.payload.data || []).map(mapApiToStore));
        state.loadingStatus = 'loaded';
      })
      .addCase(fetchPages.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(addPage.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(addPage.fulfilled, (state, action) => {
        pagesAdapter.addOne(state, action);
        state.loadingStatus = 'loaded';
      })
      .addCase(updatePage.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(updatePage.fulfilled, (state, action) => {
        const { id, ...changes } = action.payload;
        pagesAdapter.updateOne(state, { id, changes });
        state.loadingStatus = 'loaded';
      });
  },
});

export const pagesReducer = pagesSlice.reducer;
export const pagesActions = pagesSlice.actions;

const { selectAll, selectEntities } = pagesAdapter.getSelectors();

export const getPagesState = (rootState) => rootState[PAGES_FEATURE_KEY];

export const selectAllPages = createSelector(getPagesState, selectAll);

export const selectPagesEntities = createSelector(
  getPagesState,
  selectEntities,
);
