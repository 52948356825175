import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { getAxiosCmsWithAuthorization } from '@clatter/platform';

export const RESOURCE_CATEGORIES_FEATURE_KEY = 'resourceCategories';
export const resourceCategoriesAdapter = createEntityAdapter();
const apiBaseUrl = '/resource-categories';

const mapApiToStore = (item) => ({
  id: item.id,
  ...item.attributes,
});

export const fetchResourceCategories = createAsyncThunk(
  'resourceCategories/fetch',
  async () => {
    const response = await getAxiosCmsWithAuthorization({
      method: 'get',
      url: `${apiBaseUrl}?pagination[limit]=-1&sort=categoryName:asc`,
    });

    return Promise.resolve((response.data.data || []).map(mapApiToStore));
  },
);

export const createResourceCategory = createAsyncThunk(
  'resourceCategories/create',
  async (formData) => {
    const response = await getAxiosCmsWithAuthorization({
      method: 'post',
      url: `${apiBaseUrl}`,
      data: { data: formData },
    });

    return Promise.resolve(mapApiToStore(response.data.data));
  },
);

export const deleteResourceCategory = createAsyncThunk(
  'resourceCategories/delete',
  async (id) => {
    const response = await getAxiosCmsWithAuthorization({
      method: 'delete',
      url: `${apiBaseUrl}/${id}`,
    });

    return Promise.resolve(mapApiToStore(response.data.data));
  },
);

export const updateResourceCategory = createAsyncThunk(
  'resourceCategories/update',
  async ({ id, formData }) => {
    const response = await getAxiosCmsWithAuthorization({
      method: 'put',
      url: `${apiBaseUrl}/${id}`,
      data: { data: formData },
    });

    return Promise.resolve(mapApiToStore(response.data.data));
  },
);

export const initialResourceCategoriesState = resourceCategoriesAdapter.getInitialState(
  {
    loadingStatus: 'loading',
    error: null,
  },
);

export const resourceCategoriesSlice = createSlice({
  name: RESOURCE_CATEGORIES_FEATURE_KEY,
  initialState: initialResourceCategoriesState,
  reducers: {
    add: resourceCategoriesAdapter.addOne,
    remove: resourceCategoriesAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchResourceCategories.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(fetchResourceCategories.fulfilled, (state, action) => {
        resourceCategoriesAdapter.setAll(state, action.payload);
        state.loadingStatus = 'loaded';
      })
      .addCase(fetchResourceCategories.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(createResourceCategory.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(createResourceCategory.fulfilled, (state, action) => {
        resourceCategoriesAdapter.addOne(state, action.payload);
        state.loadingStatus = 'loaded';
      })
      .addCase(createResourceCategory.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(updateResourceCategory.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(updateResourceCategory.fulfilled, (state, action) => {
        resourceCategoriesAdapter.upsertOne(state, action.payload);
        state.loadingStatus = 'loaded';
      })
      .addCase(updateResourceCategory.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(deleteResourceCategory.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(deleteResourceCategory.fulfilled, (state, action) => {
        resourceCategoriesAdapter.removeOne(state, action.payload.id);
        state.loadingStatus = 'loaded';
      })
      .addCase(deleteResourceCategory.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      });
  },
});

export const resourceCategoriesReducer = resourceCategoriesSlice.reducer;
export const resourceCategoriesActions = resourceCategoriesSlice.actions;

const { selectAll, selectEntities } = resourceCategoriesAdapter.getSelectors();

export const getResourceCategoriesState = (rootState) =>
  rootState[RESOURCE_CATEGORIES_FEATURE_KEY];

export const selectAllResourceCategories = createSelector(
  getResourceCategoriesState,
  selectAll,
);

export const selectResourceCategoriesEntities = createSelector(
  getResourceCategoriesState,
  selectEntities,
);
