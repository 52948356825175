import React from 'react';
import styled from 'styled-components';

const StyledListItemIcon = styled.div`
  font-size: 20px;
  margin-right: 12px;
  color: #262626;
  display: flex;
`;

const ListItemIcon = ({ children }) => (
  <StyledListItemIcon>{children}</StyledListItemIcon>
);

export default ListItemIcon;
