import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { cloneDeep, get, orderBy } from 'lodash';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import SortIcon from '@mui/icons-material/Sort';
import Checkbox from '../Forms/Checkbox';
import Table from '../Table/Table';
import { FormControl, TextControl } from '../Forms';
import useSortModel from '../../hooks/useSortModel';

const compareIdentifiers = (first, second) => {
  const firstType = typeof first;
  const secondType = typeof second;

  if (firstType === secondType) {
    return first === second;
  }

  if (firstType === 'string' && secondType === 'number') {
    return first === second.toString();
  }

  if (firstType === 'number' && secondType === 'string') {
    return first === Number(second);
  }

  return false;
};

const StyledDataTable = styled.div`
  .table-scroll {
    overflow-y: auto;
  }

  .table-th-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .table-th-label {
    line-height: 24px;
  }

  .sortable-header {
    cursor: pointer;
  }

  .table-th-sort {
    display: flex;
    align-items: center;

    svg {
      margin-left: 8px;
      width: 16px;
      height: 16px;
    }
  }

  ${(props) =>
    !props.autofit
      ? `
    .table-scroll {
      max-height: 200px;
    }
      `
      : `
    display: grid;
    height: 100%;
    grid-template-rows: auto 1fr;

    .table-scroll {
      max-height: auto;
    }
  `}
`;

export const defaultSortFunction = (array, key, sort = 'asc') =>
  orderBy(array, key, sort);

const DataTable = ({
  autofit = false,
  columns,
  defaultSortField,
  filterColumns = [],
  name,
  noRowsMessage = 'No items',
  onSelectionChange,
  rows = [],
  selectable = false,
  selectedRows,
  stickyHeader = true,
  renderActionColumn,
  actionsColumnName = null,
}) => {
  const { sortModel, handleSortModelChange } = useSortModel(name);
  const [selected, setSelected] = useState(selectedRows || []);
  const [displayRows, setDisplayRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    let nextDisplayRows = Array.isArray(rows) ? rows : [];

    if (searchTerm) {
      nextDisplayRows = nextDisplayRows.filter((row) =>
        filterColumns.some((column) =>
          row[column]?.toLowerCase().includes(searchTerm.toLowerCase()),
        ),
      );
    }

    if (defaultSortField || sortModel?.field) {
      setDisplayRows(rows);

      const sortColumn =
        sortModel?.field &&
        columns.find(({ name }) => name === sortModel?.field);

      nextDisplayRows = defaultSortFunction(
        nextDisplayRows,
        sortColumn?.customSortValue || sortModel?.field || defaultSortField,
        sortModel?.sort,
      );
    }

    setDisplayRows(nextDisplayRows);
  }, [rows, sortModel, searchTerm, defaultSortField, filterColumns.length]);

  useEffect(() => {
    if (selectable && Array.isArray(selectedRows)) {
      setSelected(selectedRows);
    }
  }, [selectedRows, selectable]);

  const isSelected = (itemId) =>
    selected.some((item) => compareIdentifiers(itemId, item.id));

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSelectAllClick = (event) => {
    let nextSelected = [];

    if (event.target.checked) {
      nextSelected = rows.map((row) => row);
    }

    typeof onSelectionChange === 'function' && onSelectionChange(nextSelected);
    setSelected(nextSelected);
  };

  const handleSelectClick = (event) => {
    const clickedItem = rows.find((item) =>
      compareIdentifiers(item.id, event.target.value),
    );

    let nextSelected = cloneDeep(selected);

    if (selected.some((item) => clickedItem.id === item.id)) {
      nextSelected = nextSelected.filter((item) => clickedItem.id !== item.id);
    } else {
      nextSelected.push(clickedItem);
    }

    typeof onSelectionChange === 'function' && onSelectionChange(nextSelected);
    setSelected(nextSelected);
  };

  const handleSortClick = (event) => {
    const { sortField } = event.currentTarget.dataset;
    if (sortModel?.field === sortField && sortModel?.sort === 'desc') {
      handleSortModelChange([]);
    } else {
      const nextSortModel = {
        field: sortField,
        sort: sortModel?.field === sortField ? 'desc' : 'asc',
      };
      handleSortModelChange([nextSortModel]);
    }
  };

  const renderCell = (row, column) => {
    if (typeof column.customRender === 'function') {
      return column.customRender(row, get(row, column.name));
    }

    return get(row, column.name);
  };

  const renderRows = () => {
    if (!displayRows || !displayRows.length) {
      const columnsCount =
        typeof renderActionColumn === 'function'
          ? columns.length + 1
          : columns.length;
      return (
        <tr>
          <td colSpan={columnsCount}>{noRowsMessage}</td>
        </tr>
      );
    }

    return displayRows.map((row, index) => (
      <tr key={row.id}>
        {selectable && (
          <td>
            <Checkbox
              checked={isSelected(row.id)}
              value={row.id}
              onChange={handleSelectClick}
            />
          </td>
        )}
        {columns.map((column) => (
          <td
            key={`${row.id}-${column.name}`}
            align={column.align}
            className={column?.className || ''}
          >
            {renderCell(row, column)}
          </td>
        ))}
        {typeof renderActionColumn === 'function' && (
          <td className="table-td-actions" align="right">{renderActionColumn(row, index)}</td>
        )}
      </tr>
    ));
  };

  const renderSortIndicator = (column) => {
    if (sortModel?.field !== column.name) {
      return <SortIcon />;
    }

    return sortModel?.sort === 'desc' ? (
      <ArrowDownwardIcon />
    ) : (
      <ArrowUpwardIcon />
    );
  };

  const renderHeaderCell = (column) => {
    if (column.sortable) {
      return (
        <th
          className="sortable-header"
          onClick={handleSortClick}
          data-sort-field={column.name}
          key={column.label}
          align={column.align || 'left'}
        >
          <div className="table-th-sort">
            <div className="table-th-label">{column.label}</div>
            {renderSortIndicator(column)}
          </div>
        </th>
      );
    }

    return (
      <th key={column.label} align={column.align || 'left'}>
        <div className="table-th-label">{column.label}</div>
      </th>
    );
  };

  if (!columns || !columns.length) {
    return null;
  }

  return (
    <StyledDataTable autofit={autofit}>
      {selectable && `Rows selected: ${selected?.length}`}
      {filterColumns?.length > 0 && (
        <div className="filters">
          <FormControl>
            <TextControl
              onChange={handleSearchTermChange}
              placeholder="Search..."
              value={searchTerm}
            />
          </FormControl>
        </div>
      )}
      <div className={`${stickyHeader ? 'table-scroll' : ''}`}>
        <Table stickyHeader={stickyHeader}>
          <thead>
            <tr>
              {selectable && (
                <th padding="checkbox">
                  <Checkbox
                    checked={
                      displayRows.length > 0 &&
                      selected &&
                      selected.length === displayRows.length
                    }
                    onChange={handleSelectAllClick}
                  />
                </th>
              )}
              {columns.map(renderHeaderCell)}
              {typeof renderActionColumn === 'function' && (
                <th align="right" className="table-th-actions">
                  {actionsColumnName || 'Actions'}
                </th>
              )}
            </tr>
          </thead>
          <tbody>{renderRows()}</tbody>
        </Table>
      </div>
    </StyledDataTable>
  );
};

DataTable.propTypes = {
  autofit: PropTypes.bool,
  columns: PropTypes.array,
  defaultSortField: PropTypes.string,
  filterColumns: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
  noRowsMessage: PropTypes.string,
  onSelectionChange: PropTypes.func,
  rows: PropTypes.array,
  selectable: PropTypes.bool,
  selectedRows: PropTypes.array,
  stickyHeader: PropTypes.bool,
  renderActionColumn: PropTypes.func,
};

export default DataTable;
