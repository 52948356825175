import React from 'react';
import { buttonWidths, DataGridPremium, IconButton } from '@clatter/ui';
import {
  GridToolbarContainer,
  useGridApiContext,
} from '@mui/x-data-grid';
import {
  ArrowUpward as ArrowUpwardIcon,
  CloudDownload as CloudDownloadIcon,
} from '@mui/icons-material';
import {
  handleExportAsCSV,
  secondsToMinutesAndSeconds,
} from '@clatter/platform';
import { DEFAULT_SORT_MODEL } from './Filters/PageViewsFilters';

export const columns = [
  {
    renderHeader: () => (
      <strong>
        {'Site '}
        <ArrowUpwardIcon fontSize="small" />
      </strong>
    ),
    field: 'site',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'Page',
    field: 'page',
    sortable: true,
    flex: 1,
  },
  {
    headerName: 'Pageviews',
    field: 'views',
    sortable: true,
    flex: 1,
  },
  {
    headerName: 'Visitors',
    field: 'visitors',
    sortable: true,
    flex: 1,
  },
  {
    headerName: 'Engagement',
    field: 'avg_engagement_time',
    valueFormatter: (params) =>
      secondsToMinutesAndSeconds(Math.round(params.value)),
    sortable: true,
    flex: 1,
  },
  {
    headerName: 'Clicks',
    field: 'clicks',
    sortable: true,
    flex: 1,
  },
];

const CustomToolbar = ({ exportFileName }) => {
  const apiRef = useGridApiContext();

  return (
    <GridToolbarContainer
      style={{
        justifyContent: 'end',
      }}
    >
      <IconButton
        tooltip="Export"
        width={buttonWidths.md}
        onClick={() =>
          handleExportAsCSV({
            apiRef: apiRef,
            exportFileName: exportFileName,
          })
        }
      >
        <CloudDownloadIcon />
      </IconButton>
    </GridToolbarContainer>
  );
};

const PageviewsByPageTable = ({
  isLoading = false,
  rows = [],
  onSortChange,
  name = "report/msm-pageviews-page",
  exportFileName,
}) => {
  return (
    <DataGridPremium
      showVerticalSidebar
      loading={isLoading}
      dataGridProps={{
        components: {
          Toolbar: () => CustomToolbar({ exportFileName }),
        },
        // in free version DataGrid does not support multi-column sorting
        // this is a workaround to add it
        sortingMode: 'server',
        sortModel: undefined,
        getRowClassName: ({ row }) => (row.isOdd ? 'even' : 'odd'),
        onSortModelChange: (sortModel) =>
          onSortChange(sortModel[0] || DEFAULT_SORT_MODEL),
      }}
      name={name}
      columns={columns}
      defaultSortField={{ field: 'page', sort: 'desc' }}
      rows={rows}
      getRowId={(row) => row.uuid}
      disableColumnFilter
    />
  );
};

export default PageviewsByPageTable;
