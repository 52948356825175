import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '../IconButton/IconButton';

const StyledDialog = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);

  .dialog {
    background-color: #fff;
    min-width: 320px;
    max-width: 80%;
    border-radius: 4px;

    .dialog-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 48px;
      border-bottom: 1px solid #ddd;
      padding: 0 16px;

      .dialog-title {
        flex-grow: 0;
        max-width: 60%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .dialog-content {
      padding: 16px;
      max-height: 80vh;
      overflow-y: auto;
    }
  }
`;

const Dialog = ({ children, title, onCloseDialog, open }) => {
  const dialogRef = useRef(null);
  if (!open) {
    return null;
  }

  const handleClickOutsideDialog = (event) => {
    if (dialogRef.current && dialogRef.current === event.target) {
      onCloseDialog(null);
    }
  };

  return ReactDOM.createPortal(
    <StyledDialog onMouseDown={handleClickOutsideDialog} ref={dialogRef}>
      <div className="dialog">
        <div className="dialog-header">
          <div className="dialog-title">{title}</div>
          <IconButton onClick={onCloseDialog}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="dialog-content">{children}</div>
      </div>
    </StyledDialog>,
    document.body,
  );
};

export default Dialog;
