import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { getAxiosCmsWithAuthorization } from '@clatter/platform';

export const BIOS_FEATURE_KEY = 'bios';
export const biosAdapter = createEntityAdapter();
const baseUrl = `/bios`;

const mapApiToStore = (item) => ({
  id: item.id,
  // ...item.attributes,
  firstName: item?.attributes?.firstName || '',
  lastName: item?.attributes?.lastName || '',
  displayName: item?.attributes?.displayName || '',
  title: item?.attributes?.title || '',
  email: item?.attributes?.email || '',
  description: item?.attributes?.description || '',
  phone: item?.attributes?.phone || '',
  phoneType: item?.attributes?.phoneType || '',
  phoneExtension: item?.attributes?.phoneExtension || '',
  createdAt: item?.attributes?.createdAt || '',
  updatedAt: item?.attributes?.updatedAt || '',
  c_created_by: item?.attributes?.c_created_by,
  c_updated_by: item?.attributes?.c_updated_by,
  profilePicture: item.attributes?.profilePicture && item.attributes?.profilePicture?.data !== null ? {
    id: item.attributes?.profilePicture?.data?.id,
    ...item.attributes?.profilePicture?.data?.attributes,
  } : null,
  group: item.attributes?.group && item.attributes?.group?.data !== null ? {
    id: item.attributes?.group?.data?.id,
    ...item.attributes?.group?.data?.attributes,
  } : null,
});

export const fetchBios = createAsyncThunk(
  `${BIOS_FEATURE_KEY}/fetch`,
  async () => {
    const response = await getAxiosCmsWithAuthorization({
      method: 'get',
      url: `${baseUrl}?pagination[limit]=-1&sort=displayName:asc&populate=*`,
    });

    return Promise.resolve((response.data.data || []).map(mapApiToStore));
  },
);

export const createBio = createAsyncThunk(
  `${BIOS_FEATURE_KEY}/create`,
  async ({
    description,
    file,
    firstName,
    group,
    lastName,
    title,
    email,
    phone,
    c_created_by,
  }) => {
    const payload = {
      data: {
        displayName: `${firstName} ${lastName}`,
        firstName,
        group,
        lastName,
        title,
        email,
        phone,
        description,
        c_created_by,
      }
    };

    // in case when email is { null, undefined, empty string }
    if (!payload?.data?.email?.trim()) {
      delete payload.data.email;
    }

    const response = await getAxiosCmsWithAuthorization({
      method: 'post',
      url: `${baseUrl}?populate=*`,
      data: payload,
    });
    const mappedResponse = mapApiToStore(response.data.data);

    const newUpload = new FormData();
    newUpload.append('files', file);
    newUpload.append('ref', 'api::bio.bio');
    newUpload.append('refId', mappedResponse.id);
    newUpload.append('field', 'profilePicture');

    const uploadResponse = await getAxiosCmsWithAuthorization({
      method: 'post',
      url: '/upload',
      data: newUpload,
    });

    const newBio = {
      ...mappedResponse,
      profilePicture: {
        ...uploadResponse.data[0],
      },
    };

    return Promise.resolve(newBio);
  },
);

export const updateBio = createAsyncThunk(
  `${BIOS_FEATURE_KEY}/update`,
  async ({
    id,
    description,
    file,
    firstName,
    group,
    lastName,
    title,
    email,
    phone,
    c_updated_by,
  }) => {
    const payload = {
      data:
        {
          displayName: `${firstName} ${lastName}`,
          firstName,
          group,
          lastName,
          title,
          email,
          phone,
          description,
          c_updated_by,
        }
    }

    // in case when email is { null, undefined, empty string }
    if (!payload?.data?.email?.trim()) {
      payload.data.email = null;
    }

    const response = await getAxiosCmsWithAuthorization({
      method: 'put',
      url: `${baseUrl}/${id}?populate=*`,
      data: payload,
    });
    const mappedResponse = mapApiToStore(response.data.data);

    const updatedBio = {
      ...mappedResponse,
    };

    if (file) {
      const newUpload = new FormData();
      newUpload.append('files', file);
      newUpload.append('ref', 'api::bio.bio');
      newUpload.append('refId', mappedResponse.id);
      newUpload.append('field', 'profilePicture');

      const uploadResponse = await getAxiosCmsWithAuthorization({
        method: 'post',
        url: '/upload',
        data: newUpload,
      });

      updatedBio.profilePicture = {
        ...uploadResponse.data[0],
      };
    }

    return Promise.resolve(updatedBio);
  },
);

export const deleteBio = createAsyncThunk(
  `${BIOS_FEATURE_KEY}/delete`,
  async (id) => {
    const response = await getAxiosCmsWithAuthorization({
      method: 'delete',
      url: `${baseUrl}/${id}`,
    });

    return Promise.resolve(mapApiToStore(response.data.data));
  },
);

export const initialBiosState = biosAdapter.getInitialState({
  loadingStatus: 'loading',
  error: null,
});

export const biosSlice = createSlice({
  name: BIOS_FEATURE_KEY,
  initialState: initialBiosState,
  reducers: {
    add: biosAdapter.addOne,
    remove: biosAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBios.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(fetchBios.fulfilled, (state, action) => {
        biosAdapter.setAll(state, action.payload);
        state.loadingStatus = 'loaded';
      })
      .addCase(fetchBios.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(createBio.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(createBio.fulfilled, (state, action) => {
        biosAdapter.addOne(state, action.payload);
        state.loadingStatus = 'loaded';
      })
      .addCase(createBio.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(updateBio.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(updateBio.fulfilled, (state, action) => {
        biosAdapter.upsertOne(state, action.payload);
        state.loadingStatus = 'loaded';
      })
      .addCase(updateBio.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(deleteBio.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(deleteBio.fulfilled, (state, action) => {
        biosAdapter.removeOne(state, action.payload.id);
        state.loadingStatus = 'loaded';
      })
      .addCase(deleteBio.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      });
  },
});

export const biosReducer = biosSlice.reducer;
export const biosActions = biosSlice.actions;

const { selectAll, selectEntities } = biosAdapter.getSelectors();
export const getBiosState = (rootState) => rootState[BIOS_FEATURE_KEY];
export const selectAllBios = createSelector(getBiosState, selectAll);
export const selectBiosEntities = createSelector(getBiosState, selectEntities);
