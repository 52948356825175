import React from 'react';
import styled from 'styled-components';
import { buttonVariants } from '../../config/consts';
import Button from '../Button/Button';

const StyledStepCardAction = styled.div``;

const StepCardAction = ({ icon: Icon, label = '', onClick = () => {} }) => (
  <StyledStepCardAction>
    <Button variant={buttonVariants.secondary} onClick={onClick} icon={Icon}>
      {label}
    </Button>
  </StyledStepCardAction>
);

export default StepCardAction;
