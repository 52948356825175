import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { getAxiosCmsWithAuthorization } from '@clatter/platform';

export const RESOURCES_FEATURE_KEY = 'resources';
export const resourcesAdapter = createEntityAdapter();
const baseUrl = '/resources';

// @todo: (almost) 1:1 copy of fetchResources from below; refactor to fetchResources
export const fetchAllResources = createAsyncThunk(
  `${RESOURCES_FEATURE_KEY}/fetchAll`,
  async () => {
      const response = await getAxiosCmsWithAuthorization({
        method: 'get',
        url: `${baseUrl}?pagination[limit]=-1&sort=title:asc&populate[resource_category][fields][0]=category_name`,
      });

      return response.data.data;
  },
);

export const fetchResources = createAsyncThunk(
  `${RESOURCES_FEATURE_KEY}/fetch`,
  async () => {
      const response = await getAxiosCmsWithAuthorization({
        method: 'get',
        url: `${baseUrl}?pagination[limit]=-1&sort=title:asc&populate=*`,
      });

      return response.data.data;
  },
);

export const createResource = createAsyncThunk(
  `${RESOURCES_FEATURE_KEY}/create`,
  async ({ description, file, link, resource_category, title, createdBy }, { rejectWithValue }) => {
    try {
      const createResourceResponse = await getAxiosCmsWithAuthorization({
        method: 'post',
        url: `${baseUrl}?populate=*`,
        data: {
          data: {
            description,
            link,
            resource_category,
            title,
            c_created_by: createdBy,
          },
        },
      });
      const mappedResponse = mapApiToStore(createResourceResponse.data.data);
      const newResource = {
        ...mappedResponse,
      };

      if (file) {
        newResource.asset = await _uploadResourceAsset({
          resourceId: createResourceResponse.data.data.id,
          file: file,
          shouldDeleteResource: true, // we are in create mode, therefore whenever file upload fails, delete resource
        });
      }

      return newResource;
    } catch (error) {
      return rejectWithValue(error?.response?.data || error?.message);
    }
  },
);

export const updateResource = createAsyncThunk(
  `${RESOURCES_FEATURE_KEY}/update`,
  async ({
    id,
    formData: { description, file, link, resource_category, title, updatedBy },
  }, { rejectWithValue }) => {
    try {
      const updateResourceResponse = await getAxiosCmsWithAuthorization({
        method: 'put',
        url: `${baseUrl}/${id}?populate=*`,
        data: {
          data: {
            description,
            link,
            resource_category,
            title,
            c_updated_by: updatedBy,
          },
        },
      });
      const mappedResponse = mapApiToStore(updateResourceResponse.data.data);
      const updatedResource = {
        ...mappedResponse,
      };

      if (file) {
        updatedResource.asset = await _uploadResourceAsset({
          resourceId: updateResourceResponse.data.data.id,
          file: file,
          shouldDeleteResource: false, // we are in edit mode, DO NOT delete resource when upload fails
        });
      }

      return updatedResource;
    } catch (error) {
      return rejectWithValue(error?.response?.data || error?.message);
    }
  },
);

export const deleteResource = createAsyncThunk(
  `${RESOURCES_FEATURE_KEY}/delete`,
  async (id) => {
    const response = await getAxiosCmsWithAuthorization({
      method: 'delete',
      url: `${baseUrl}/${id}`,
    });

    return mapApiToStore(response.data.data);
  },
);

export const initialResourcesState = resourcesAdapter.getInitialState({
  categories: [],
  loadingStatus: 'loading',
  error: null,
});

const mapApiToStore = (item) => ({
  id: item.id,
  ...item.attributes,
  asset: item.attributes?.asset && item.attributes?.asset?.data !== null ? {
    id: item.attributes?.asset?.data?.id,
    ...item.attributes?.asset?.data?.attributes,
  } : null,
  owner: item.attributes?.owner && item.attributes?.owner?.data !== null ? {
    id: item.attributes?.owner?.data?.id,
    ...item.attributes?.owner?.data?.attributes,
  } : null,
  resource_category: item.attributes?.resource_category && item.attributes?.resource_category?.data !== null ? {
    id: item.attributes?.resource_category?.data?.id,
    ...item.attributes?.resource_category?.data?.attributes,
  } : null,
});

export const resourcesSlice = createSlice({
  name: RESOURCES_FEATURE_KEY,
  initialState: initialResourcesState,
  reducers: {
    add: resourcesAdapter.addOne,
    remove: resourcesAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchResources.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(fetchResources.fulfilled, (state, action) => {
        resourcesAdapter.setAll(state, (action.payload || []).map(mapApiToStore));
        state.loadingStatus = 'loaded';
      })
      .addCase(fetchResources.rejected, (state, action) => {
        console.log('action (rejected): ', action);

        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(fetchAllResources.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(fetchAllResources.fulfilled, (state, action) => {
        resourcesAdapter.setAll(state, (action.payload || []).map(mapApiToStore));
        state.loadingStatus = 'loaded';
      })
      .addCase(fetchAllResources.rejected, (state, action) => {
        console.log('action (rejected): ', action);

        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(deleteResource.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(deleteResource.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(deleteResource.fulfilled, (state, action) => {
        resourcesAdapter.removeOne(state, action.payload.id);
        state.loadingStatus = 'loaded';
      })
      .addCase(createResource.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(createResource.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(createResource.fulfilled, (state, action) => {
        resourcesAdapter.addOne(state, action.payload);
        state.loadingStatus = 'loaded';
      })
      .addCase(updateResource.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(updateResource.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(updateResource.fulfilled, (state, action) => {
        resourcesAdapter.removeOne(state, action.payload.id);
        resourcesAdapter.addOne(state, action.payload);
        state.loadingStatus = 'loaded';
      });
  },
});

export const resourcesReducer = resourcesSlice.reducer;
export const resourcesActions = resourcesSlice.actions;

const { selectAll, selectEntities } = resourcesAdapter.getSelectors();

export const getResourcesState = (rootState) =>
  rootState[RESOURCES_FEATURE_KEY];

export const selectAllResources = createSelector(getResourcesState, selectAll);

export const selectResourcesEntities = createSelector(
  getResourcesState,
  selectEntities,
);

const _uploadResourceAsset = async ({ resourceId, file, shouldDeleteResource }) => {
  try {
    const newUpload = new FormData();
    newUpload.append('files', file);
    newUpload.append('ref', 'api::resource.resource');
    newUpload.append('refId', resourceId);
    newUpload.append('field', 'asset');

    const uploadResponse = await getAxiosCmsWithAuthorization({
      method: 'post',
      url: '/upload',
      data: newUpload,
    });

    return uploadResponse.data[0];
  } catch (error) {
    // at this point resource has been already created, yet the resource file upload failed
    // we need to rollback resource creation (delete resource) and show an error notification
    if (shouldDeleteResource && resourceId && !isNaN(resourceId)) {
      await getAxiosCmsWithAuthorization({
        method: 'delete',
        url: `${baseUrl}/${resourceId}`,
      });
    }

    throw new Error('Something went wrong during file upload.');
  }
};
