import React from 'react';
import PropTypes from 'prop-types';
import { Button, buttonVariants } from '@clatter/ui';

const PreviewButton = ({ pageName = '', siteName = '', onPreviewClick, isDisabled = false }) => {
  const redirectToPreview = () => {
    window.open(
      `${process.env.NX_PREVIEW_HOST}/api/preview?secret=MY_SECRET_TOKEN&slug=sites/${siteName}/${pageName}`,
      '_blank',
      'noopener',
    );
  };

  const handlePreviewClick = () => {
    if (typeof onPreviewClick === 'function') {
      onPreviewClick().then(() => {
        redirectToPreview();
      });
    } else {
      redirectToPreview();
    }
  };

  return (
    <Button
      disabled={!pageName || !siteName || isDisabled}
      onClick={handlePreviewClick}
      variant={buttonVariants.secondary}
    >
      Preview
    </Button>
  );
};

PreviewButton.propTypes = {
  pageName: PropTypes.string,
  siteName: PropTypes.string,
  onPreviewClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

export default PreviewButton;
