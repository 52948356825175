import React, { useEffect, useState } from 'react';
import TextControl from './TextControl';

export const getMaskedValue = (mask, value = '') => {
  let maskArray = mask.split('');
  let valueArray = value.split('');

  for (let i = 0; i < valueArray.length; i++) {
    if (maskArray[i] === '-' && valueArray[i] !== '-') {
      valueArray.splice(i, 0, maskArray[i]);
    }
  }

  return valueArray.join('');
};

const MaskedControl = ({ mask = '', onChange, value, ...inputProps }) => {
  const [maskedValue, setMaskedValue] = useState(getMaskedValue(mask, value));

  useEffect(() => {
    setMaskedValue(getMaskedValue(mask, value));
  }, [mask, value]);

  const handleChange = (event) => {
    if (mask.length >= event.target.value.length) {
      onChange(event.target.value.replaceAll('-', ''));
    }
  };

  return (
    <TextControl {...inputProps} onChange={handleChange} value={maskedValue} />
  );
};

export default MaskedControl;
