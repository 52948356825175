import getAxiosWithStrapi from './strapiFactory';

const pageTemplateIconsApi = {
  getAllPageTemplateIcons: () =>
    getAxiosWithStrapi({
      url: `/page-template-icons?pagination[limit]=-1&populate=*&sort=updatedAt:desc`,
      method: 'get',
    }),
  addPageTemplateIcon: (data) =>
    getAxiosWithStrapi({
      url: `/page-template-icons?populate=*`,
      method: 'post',
      data: {
        data: data,
      },
    }),
};

export default pageTemplateIconsApi;
