import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LandingPage, Loader } from '@clatter/ui';
import { cookieService, platformSelectors, useAuth } from '@clatter/platform';
import routes from '../../routes/routes';
import queryString from 'query-string';

const LandingPageView = () => {
  const { isAuthenticated } = useAuth();
  const history = useHistory();
  const toolInfo = useSelector(platformSelectors.toolInfo.selectToolInfo);

  const { redirectTo } = queryString.parse(history.location.search) || {};

  useEffect(() => {
    if (isAuthenticated) {
      history.push(redirectTo || routes.microsites);
    }
  }, [isAuthenticated, redirectTo]);

  // if auth0 cookie is set, disable LandingPage
  if (cookieService.get('auth_token')) {
    return <Loader />;
  }

  return (
    <LandingPage
      successfulLoginRedirectUrl={routes.microsites}
      backgroundImageUrl={toolInfo?.data?.companyHeroImage?.location}
      logoUrl={toolInfo?.data?.companyLogo?.location}
      supportEmail={toolInfo?.data?.supportEmail}
      descriptionText={toolInfo?.data?.description}
    />
  );
};

export default LandingPageView;
