import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { getAxiosCmsWithAuthorization } from '@clatter/platform';

export const VIDEO_EMBEDS_FEATURE_KEY = 'videoEmbeds';
export const videoEmbedsAdapter = createEntityAdapter();
const baseUrl = '/video-embeds';

const mapApiToStore = (item) => ({
  id: item.id,
  ...item.attributes,
  video_category: item.attributes?.video_category && item.attributes?.video_category?.data !== null ? {
    id: item.attributes?.video_category?.data?.id,
    ...item.attributes?.video_category?.data?.attributes,
  } : null,
});

export const fetchVideoEmbeds = createAsyncThunk(
  `${VIDEO_EMBEDS_FEATURE_KEY}/fetch`,
  async () => {
    const response = await getAxiosCmsWithAuthorization({
      method: 'get',
      url: `${baseUrl}?pagination[limit]=-1&populate=video_category&sort=title:asc`,
    });

    return Promise.resolve((response.data.data || []).map(mapApiToStore));
  },
);

export const createVideoEmbed = createAsyncThunk(
  `${VIDEO_EMBEDS_FEATURE_KEY}/create`,
  async (formData) => {
    const response = await getAxiosCmsWithAuthorization({
      method: 'post',
      url: `${baseUrl}?populate=*`,
      data: { data: formData },
    });

    return Promise.resolve(mapApiToStore(response.data.data));
  },
);

export const updateVideoEmbed = createAsyncThunk(
  `${VIDEO_EMBEDS_FEATURE_KEY}/update`,
  async ({ id, formData }) => {
    const response = await getAxiosCmsWithAuthorization({
      method: 'put',
      url: `${baseUrl}/${id}?populate=*`,
      data: { data: formData },
    });

    return Promise.resolve(mapApiToStore(response.data.data));
  },
);

export const deleteVideoEmbed = createAsyncThunk(
  `${VIDEO_EMBEDS_FEATURE_KEY}/delete`,
  async (id) => {
    const response = await getAxiosCmsWithAuthorization({
      method: 'delete',
      url: `${baseUrl}/${id}`,
    });

    return Promise.resolve(mapApiToStore(response.data.data));
  },
);

export const initialVideoembedsState = videoEmbedsAdapter.getInitialState({
  loadingStatus: 'loading',
  error: null,
});

export const videoEmbedsSlice = createSlice({
  name: VIDEO_EMBEDS_FEATURE_KEY,
  initialState: initialVideoembedsState,
  reducers: {
    add: videoEmbedsAdapter.addOne,
    remove: videoEmbedsAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVideoEmbeds.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(fetchVideoEmbeds.fulfilled, (state, action) => {
        videoEmbedsAdapter.setAll(state, action.payload);
        state.loadingStatus = 'loaded';
      })
      .addCase(fetchVideoEmbeds.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(createVideoEmbed.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(createVideoEmbed.fulfilled, (state, action) => {
        videoEmbedsAdapter.addOne(state, action.payload);
        state.loadingStatus = 'loaded';
      })
      .addCase(createVideoEmbed.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(deleteVideoEmbed.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(deleteVideoEmbed.fulfilled, (state, action) => {
        videoEmbedsAdapter.removeOne(state, action.payload.id);
        state.loadingStatus = 'loaded';
      })
      .addCase(deleteVideoEmbed.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(updateVideoEmbed.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(updateVideoEmbed.fulfilled, (state, action) => {
        videoEmbedsAdapter.upsertOne(state, action.payload);
        state.loadingStatus = 'loaded';
      })
      .addCase(updateVideoEmbed.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      });
  },
});

export const videoEmbedsReducer = videoEmbedsSlice.reducer;
export const videoEmbedsActions = videoEmbedsSlice.actions;

const { selectAll, selectEntities } = videoEmbedsAdapter.getSelectors();

export const getVideoEmbedsState = (rootState) =>
  rootState[VIDEO_EMBEDS_FEATURE_KEY];

export const selectAllVideoEmbeds = createSelector(
  getVideoEmbedsState,
  selectAll,
);

export const selectVideoEmbedsEntities = createSelector(
  getVideoEmbedsState,
  selectEntities,
);
