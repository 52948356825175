export const ENABLE_CO_BRAND_LOGO_REL = true;

export const populateCoBrandLogoRel = ENABLE_CO_BRAND_LOGO_REL ? `,cobrand_logo_rel.asset,cobrand_logo_rel.owner` : '';

export const strapiCoBrandLogoToStoreMap = (item) => {
  if (!item) {
    return null;
  }

  return {
    id: item.id,
    ...item.attributes,
    owner: item.attributes?.owner?.data !== null ? {
      id: item.attributes?.owner?.data?.id,
      ...item.attributes?.owner?.data?.attributes,
    } : null,
    asset: item.attributes?.asset?.data !== null ? {
      id: item.attributes?.asset?.data?.id,
      ...item.attributes?.asset?.data?.attributes,
    } : null,
  };
};
