import styled from 'styled-components';

const StyledCheckboxContainer = styled.div`
  input {
    position: relative;
    bottom: 2px;
  }
`;

export default StyledCheckboxContainer;
