import React, { useEffect } from 'react';
import Joi from 'joi';
import { useDispatch } from 'react-redux';
import {generatePath, useHistory} from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { joiResolver } from '@hookform/resolvers/joi/dist/joi';
import { Stack } from '@mui/material';
import {
  Button,
  Checkbox,
  errorMessages,
  FormControl,
  StepCard,
  StepCardContent,
  StepCardHeader,
  StepCardTitle,
  StyledCheckboxContainer,
  TextControl,
} from '@clatter/ui';
import { getNextPage, isSiteComplete } from '../../helpers';
import routes from '../../routes/routes';
import PreviewButton from '../SiteMaker/PreviewButton';
import SiteMakerActions from '../SiteMaker/SiteMakerActions';
import { updatePage } from '../../store';
import { useDeepCompareEffect } from '@clatter/platform';

const validationSchema = Joi.object()
  .keys({
    external_link: Joi.string().uri().required(),
    external_link_open_in_new: Joi.boolean().required(),
  })
  .messages({
    'any.required': errorMessages.REQUIRED.message,
    'string.uri': errorMessages.INVALID.message,
    'string.empty': errorMessages.REQUIRED.message,
    'string.base': errorMessages.REQUIRED.message,
    'array.base': errorMessages.REQUIRED.message,
    'array.min': errorMessages.REQUIRED.message,
    'string.min': errorMessages.REQUIRED.message,
  })
  .options({
    allowUnknown: true,
  });

export const ExternalLinkForm = ({ currentMicrosite, currentPage, pageId, saveButtonText, onChange }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    control,
    formState: { errors, isValid, isDirty, dirtyFields },
    handleSubmit,
    getValues,
    reset,
    register,
  } = useForm({
    resolver: joiResolver(validationSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      external_link: currentPage?.external_link || '',
      external_link_open_in_new: currentPage?.external_link_open_in_new || false,
    },
  });

  const handleUpdate = async (formData) => {
    if (isValid && isDirty) {
      await dispatch(
        updatePage({
          id: pageId,
          external_link: formData.external_link,
          external_link_open_in_new: formData.external_link_open_in_new,
        }),
      );
    }
  };

  const handleFormSubmit = async (formData) => {
    if (isDirty) {
      await handleUpdate(formData);
    }
    history.push(getNextPage(currentMicrosite, currentMicrosite.pages));
  };

  //@todo
  const onPreviewClick = async () => {
    const formData = getValues();
    await handleUpdate(formData);
    reset(formData);
  };

  const redirectToPublish = () => history.push(generatePath(routes.publishSite, { siteId: currentMicrosite.id }));

  //region EFFECTS
  useEffect(() => {
    if (isDirty) {
      return onChange('Save External Link and continue');
    }
    onChange('Continue');
  }, [isDirty]);

  // set initial form data
  useDeepCompareEffect(() => {
    if (currentPage?.id && currentPage?.external_link) {
      reset({
        external_link: currentPage?.external_link || '',
        external_link_open_in_new: currentPage?.external_link_open_in_new || false,
      });
    }
  }, [currentPage]);
  //endregion

  const renderButtons = () => (
    <>
      <Button disabled={!isValid} type="submit">
        {saveButtonText}
      </Button>

      <PreviewButton
        siteName={currentMicrosite?.name}
        pageName={currentPage?.name}
        onPreviewClick={onPreviewClick}
        isDisabled={!isValid}
      />

      <Button
        disabled={isDirty || !isSiteComplete(currentMicrosite, currentMicrosite?.pages)}
        onClick={redirectToPublish}
      >
        Publish
      </Button>
    </>
  );

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <DevTool control={control} placement="bottom-left" />

      <StepCard>
        <StepCardHeader step={1}>
          <StepCardTitle text="Enter external link data" />
        </StepCardHeader>
        <StepCardContent>
          <Stack direction="column" spacing={4}>
            <FormControl label="URL" error={errors?.external_link}>
              <TextControl {...register(`external_link`)} placeholder="Enter url" />
            </FormControl>

            <StyledCheckboxContainer>
              <Checkbox {...register(`external_link_open_in_new`)} text="Open in new window" />
            </StyledCheckboxContainer>
          </Stack>
        </StepCardContent>
      </StepCard>

      <SiteMakerActions renderButtons={renderButtons} />
    </form>
  );

  return <>External Link Form</>;
};
